import React, { useEffect, useState } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ActivityItem from './ActivityItem';
import { styled } from '@mui/material/styles';
import Input from './UI/Input';
import { InputLabel, Typography } from '@mui/material';
import CustomTooltip from './UI/CustomTooltip';

const INTERESTS = [
  'Music',
  'Books',
  'Dancing',
  'Travel',
  'Art',
  'Podcasts',
  'Photography',
  'Fitness',
  'Gaming',
  'Meditation',
  'Parties',
  'Wellness',
  'Wine',
  'Movies',
  'Sports',
  'Fine Dining',
  'Cooking',
  'Music Skills',
  'Diving',
  'Tech Skills',
  'Networking',
  'Painting',
  'Mixology',
  'Podcasting',
  'Digital Art and Design',
  'Fashion',
  'DJing',
  'Environmentalism',
  'Astrology'
];

const ActivityGroup = ({selectedActivities = [], error, required = false, tooltipText, onChange}) => {
  const [interests, setInterests] = useState(INTERESTS);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedActivities.length > 0) {
      setInterests(prevState => ([...new Set([...prevState, ...selectedActivities])]))
    }
  }, [selectedActivities]);

  const onAddInterest = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const newInterest = e.target.value.trim().charAt(0).toUpperCase() + e.target.value.trim().slice(1);
      if (e.target.value === '') {
        return;
      }
      if (!interests.includes(newInterest)) {
        setInterests(state => ([...state, newInterest]));
        setInputValue('');
        onChange(newInterest);
      }
    }
  };
  const onChangeInput = ({target: {value}}) => {
    setInputValue(value);
  };

  const onChangeGroup = ({target: {value}}) => {
    onChange(value);
  };

  return (
    <>
      <InputLabel sx={{display: 'flex', alignItems: 'center', fontSize: 16, fontWeight: 500, overflow: 'visible'}}>
        Interests
        {required && (
          <Typography sx={{ml: 0.5, fontSize: 16, fontWeight: 400, fontStyle: 'italic'}}>
            • Optional
          </Typography>
        )}
        <CustomTooltip tooltipText={tooltipText} error={error} />
        {error && (
          <Typography  sx={{ml: 0.5, fontSize: 14, fontWeight: 400, color: '#F44336'}}>
            {error}
          </Typography>
        )}
      </InputLabel>
      <CustomToggleButtonGroup
        value={selectedActivities}
        onChange={onChangeGroup}
        name="interests"
      >
        {interests?.map(item => (<ActivityItem value={item} key={item}/>))}
      </CustomToggleButtonGroup>
      <Input
        sx={{mt: 1, mb: 3}}
        type="text"
        placeholder={`Fill in more interests and press enter`}
        value={inputValue}
        onChange={onChangeInput}
        onKeyDown={onAddInterest}
        fullWidth
      />
    </>
  );
};

export default ActivityGroup;

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  '& .MuiToggleButtonGroup-grouped': {
    border: '1px solid #C19A5B',
    borderRadius: '4px',
    padding: '4px 8px',
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '&.Mui-selected': {
      backgroundColor: '#F9F5EF',
      border: '1px solid #C19A5B',
      boxShadow: 'inset 0px 0px 0px 1px rgba(193,154,91,1)',
      marginLeft: 0,
      '& + .MuiToggleButtonGroup-grouped.Mui-selected': {
        borderLeft: '1px solid #C19A5B',
      }
    },
    '& > .MuiTypography-root': {
      color: '#373632',
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'capitalize',
      pointerEvents: 'none',
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
      borderLeft: '1px solid #C19A5B',
      marginLeft: 0,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
    },
  },
}));
