import StaticPageWrapper from '../../../components/StaticPagesWrapper';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Container, InputAdornment, Typography } from '@mui/material';
import Label from '../../../components/UI/Label';
import Input from '../../../components/UI/Input';
import InstagramIcon from '@mui/icons-material/Instagram';
import dayjs from 'dayjs';
import SectorsSelect from '../../../components/SectorsSelect';
import { Button } from '../../../components/UI/Button';
import { useApplyWaitingListMutation, useCheckValueMutation } from '../api';
import SuccessApplyDialog from './SuccessApplyDialog';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import CustomDateField from '../../../components/CustomDateField';

const SignUp = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const isDisabled = !form?.firstName || !form?.lastName || !form?.email || !form?.instagram ||
    !form?.birthDate || !form?.liveIn || !form?.from || !form?.occupationSector || !form?.referralCode;
  const [checkValue, {isLoading: isCheckLoading}] = useCheckValueMutation();
  const [applyWaitingList, {isLoading, error}] = useApplyWaitingListMutation();
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (state?.isFoundingMember) {
      setForm(previousState => ({...previousState, isFoundingMember: state.isFoundingMember}));
    }
    return () => {
      history.replace({ state: {} });
    };
  }, [state?.isFoundingMember, history]);

  const handleChange = ({target: {value, name}}) => {
    setForm(state => ({...state, [name]: value}));
    if (value === "") {
      setErrors((state) => ({...state, [name]: `${name} cannot be empty`,}));
      return;
    }
    if (name === 'email' && !String(value).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      setErrors(state => ({...state, [name]: 'Enter a valid email address'}))
      return;
    }
    setErrors(state => ({...state, [name]: null}))
  };

  const handleDateChange = (e) => (name) => {
    setForm(state => ({...state, [name]: dayjs(e?.$d || null).format()}));
  };

  const handleLocation = (key) => (value) => {
    setForm(state => ({...state, [key]: value}));
  };

  const handleApply = () => {
    checkValue({key: 'email', body: {email: form?.email}}).then(res => {
      if (res?.data?.result) {
        setErrors(state => ({...state, email: 'Email already exist'}));
        window.scrollTo(0, 0);
      } else {
        applyWaitingList({...form}).then((res) => {
          if (res.data) {
            setOpen(true);
          }
        });
      }
    });
  };

  return (
    <StaticPageWrapper>
      <Container sx={{maxWidth: {xs: 420}, pt: 3}}>
        <Typography sx={{textTransform: 'uppercase', fontSize: 16, fontWeight: 500, mb: 1}}>
          apply
        </Typography>
        <Typography sx={{fontSize: 14, fontWeight: 400, lineHeight: '18px', mb: 2}}>
          Submit the below information to apply for a membership
        </Typography>
        <Label sx={{mb: 0.5}}>First name</Label>
        <Input
          name={'firstName'}
          value={form?.firstName || ''}
          onChange={handleChange}
          error={Boolean(errors?.firstName)}
          helperText={errors?.firstName}
          fullWidth
          sx={{mb: 2}}
        />
        <Label sx={{mb: 0.5}}>Last name</Label>
        <Input
          name={'lastName'}
          value={form?.lastName || ''}
          onChange={handleChange}
          error={Boolean(errors?.lastName)}
          helperText={errors?.lastName}
          fullWidth
          sx={{mb: 2}}
        />
        <Label sx={{mb: 0.5}}>Email</Label>
        <Input
          name={'email'}
          value={form?.email || ''}
          onChange={handleChange}
          error={Boolean(errors?.email)}
          helperText={errors?.email}
          fullWidth
          sx={{mb: 2}}
        />
        <Label sx={{mb: 0.5}}>Date of birth</Label>
        <CustomDateField
          sx={{mb: 2}}
          value={form?.birthDate || null}
          onChange={(e) => handleDateChange(e)('birthDate')}
          disableFuture
        />
        <Label sx={{mb: 0.5}}>Instagram</Label>
        <Input
          name={'instagram'}
          value={form?.instagram || ''}
          onChange={handleChange}
          error={Boolean(errors?.instagram)}
          helperText={errors?.instagram}
          fullWidth
          sx={{mb: 2}}
          InputProps={{
            startAdornment: <InputAdornment position="start"><InstagramIcon color={'primary'} /></InputAdornment>,
          }}
        />
        <Label sx={{mb: 0.5}}>I live in</Label>
        <GoogleAutocomplete
          sx={{mb: 2}}
          value={form?.liveIn || null}
          handleChange={handleLocation('liveIn')}
          error={errors?.liveIn}
        />
        <Label sx={{mb: 0.5}}>I am from</Label>
        <GoogleAutocomplete
          sx={{mb: 2}}
          value={form?.from || null}
          handleChange={handleLocation('from')}
          error={errors?.from}
        />
        <Label sx={{mb: 0.5}}>Industry</Label>
        <SectorsSelect
          name={'occupationSector'}
          value={form?.occupationSector || ''}
          onChange={handleChange}
          error={errors?.occupationSector}
          sx={{mb: 2}}
        />
        <Label sx={{mb: 0.5}}>Company •<em>Optional</em></Label>
        <Input
          name={'company'}
          value={form?.company || ''}
          onChange={handleChange}
          fullWidth
          sx={{mb: 2}}
        />
        <Label sx={{mb: 0.5}}>
          Referral name or code
        </Label>
        <Typography sx={{fontSize: 12, mb: 1}}>
          Connection to our community is the most important factor considered by our review committee. You can write NA if you don’t have one.
        </Typography>
        <Input
          name={'referralCode'}
          value={form?.referralCode || ''}
          onChange={handleChange}
          fullWidth
          error={error?.referralCode}
          sx={{mb: 2}}
        />
        <Typography sx={{fontSize: 14, mb: 2, '& > a': {fontSize: 14, color: '#C19A5B'}}}>
          By clicking Apply Invite.Club, you agree to our <Link to="/terms-and-conditions">Terms & Conditions </Link> and <Link to="/privacy-policy">Privacy Policy</Link>
        </Typography>
        {error && (
          <Typography sx={{color: 'red', mb: 2, fontSize: 14}}>{error?.data?.error}</Typography>
        )}
        <Button
          variant={'contained'}
          color={'action'}
          fullWidth
          sx={{mb: 3}}
          disabled={isDisabled || isCheckLoading || isLoading}
          onClick={handleApply}
        >
          Apply
        </Button>
        <Typography sx={{fontSize: 14, mb: 2, '& > a': {fontSize: 14, color: '#C19A5B'}}}>
          Already have an account? <Link to="/login">Log in</Link>
        </Typography>
        <SuccessApplyDialog
          open={open}
          onClose={() => setOpen(false)}
          firstName={form?.firstName}
          lastName={form?.lastName}
        />
      </Container>
    </StaticPageWrapper>
  );
};

export default SignUp;
