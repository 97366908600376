import PageWrapper from '../../components/PageWrapper';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Button } from '../../components/UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateProfileMutation } from '../../services/mainApi';
import dayjs from 'dayjs';
import { SET_USER } from '../../redux/types';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import FormInput from '../../components/UI/FormInput';
import Label from '../../components/UI/Label';
import Select from '../../components/UI/Select';
import SectorsSelect from '../../components/SectorsSelect';
import NationalitiesSelect from '../../components/NationalitiesSelect';
import DatePicker from '../../components/UI/DatePicker';
import LanguagesSelect from '../../components/LanguagesSelect';
import ActivitiesGroup from '../../components/ActivitiesGroup';

const CompleteProfile = () => {
  const history = useHistory();
  const {user} = useSelector(store => store.user);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const isDisabled = form?.gender === "" || form?.occupationSector === "" || form?.shortBio === "" ||
    form?.nationality === "" || form?.birthDate === "" || !form?.languages?.length ||
    !form?.interests?.length;
  const isChanged = JSON.stringify(form) === JSON.stringify({
    shortBio: user?.shortBio || '',
    gender: user?.gender || '',
    nationality: user?.nationality || '',
    birthDate: user?.birthDate || null,
    languages: user?.languages || [],
    interests: user?.interests || [],
    occupationSector: user?.occupationSector || '',
  });
  const dispatch = useDispatch();
  const [update] = useUpdateProfileMutation();

  useEffect(() => {
    if (user?.role) {
      setForm({
        shortBio: user?.shortBio || '',
        gender: user?.gender || '',
        nationality: user?.nationality || '',
        birthDate: user?.birthDate || null,
        languages: user?.languages || [],
        interests: user?.interests || [],
        occupationSector: user?.occupationSector || '',
      })
    }
  }, [user]);

  const onChangeInput = ({target: {name, value}}) => {
    setErrors(state => ({...state, [name]: false}))
    if (name === 'shortBio' && value.length > 300) {
      setErrors(state => ({...state, [name]: 'Field should contain less than 300 characters'}))
      setForm(state => ({...state, [name]: value.slice(0, 300)}));
      return;
    }
    setForm(state => ({...state, [name]: value}));
  };

  const handleDateChange = (e) => {
    setForm(state => ({...state, birthDate: dayjs(e?.$d || null).format()}))
  };

  const onChangeActivity = (name) => (value) => {
    setForm(state => ({
      ...state,
      [name]: state[name].includes(value) ? state[name].filter(item => item !== value) : [...state[name], value]
    }));
  };

  const onUpdate = () => {
    update({id: user?.id, body: form}).then(res => {
      if (res.data) {
        dispatch({
          type: SET_USER,
          payload: res.data,
        });
        enqueueSnackbar(`Your profile was updated`, {
          variant: 'info',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        history.goBack();
      }
    });
  };

  return (
    <PageWrapper>
      <Stack direction={'column'} sx={{maxWidth: 475, width: '100%', mx: 'auto'}}>
        <Typography sx={{fontSize: 20, fontWeight: 600}} align={'center'}>Complete Your Profile</Typography>
        {history.location.state?.path && (
          <Stack direction={'row'} spacing={1} sx={{my: 2}}>
            <InfoIcon
              sx={{
                ml: 0.5,
                fontSize: 20,
                width: 20,
                height: 20,
                fill: '#c19a5b'
              }}
            />

            <Typography sx={{fontSize: 14}}>
              Fill out your details to start
              {history.location.state.path === '/create-invitation' ? 'posting invitations' : 'applying to invitations'}
              . Let others know who you are!
            </Typography>
          </Stack>
        )}
        <Stack direction={'column'}>
          {!user?.shortBio && (
            <FormInput
              label={'Short bio'}
              required={user?.role === 'guest'}
              fullWidth
              multiline
              rows={4}
              name={'shortBio'}
              value={form?.shortBio || ''}
              error={errors?.shortBio}
              onChange={onChangeInput}
            />
          )}
          {!user?.gender && (
            <>
              <Label sx={{mb: 1}}>Gender</Label>
              <Select
              name={'gender'}
              value={form?.gender || ''}
              onChange={onChangeInput}
              error={errors?.gender}
              sx={{mb: 2}}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
              </Select>
            </>
          )}
          {!user?.occupationSector && (
            <>
              <Label sx={{mb: 1}}>Industry</Label>
              <SectorsSelect
                name={'occupationSector'}
                value={form?.occupationSector || ''}
                onChange={onChangeInput}
                error={errors?.occupationSector}
                sx={{mb: 2}}
              />
            </>
          )}
          {!user?.nationality?.length && (
            <>
              <Label sx={{mb: 1}}>Nationality</Label>
              <NationalitiesSelect
                name={'nationality'}
                value={form?.nationality || []}
                onChange={onChangeInput}
                errors={errors?.nationality}
                sx={{mb: 2}}
              />
            </>
          )}
          {!user?.birthDate && (
            <>
              <Label sx={{mb: 1}}>Date of Birth</Label>
              <DatePicker
                name={'birthDate'}
                value={form?.birthDate }
                onChange={handleDateChange}
                sx={{mb: 2}}
              />
            </>
          )}
          {!user?.languages?.length && (
            <>
              <Label sx={{mb: 1}}>Languages</Label>
              <LanguagesSelect
                name={'languages'}
                value={form?.languages || []}
                onChange={onChangeInput}
                error={errors?.languages}
                sx={{mb: 2}}
              />
            </>
          )}
          {!user?.interests?.length && (
            <ActivitiesGroup
              selectedActivities={form?.interests}
              label={'Interests'}
              required={user?.role === "host"}
              tooltipText={'Things you enjoy'}
              error={errors?.interests}
              onChange={onChangeActivity('interests')}
            />
          )}
          <Button
            variant={'contained'}
            sx={{'&.MuiButton-contained':{maxWidth: 330, width: '100%', mx: 'auto'}}}
            onClick={onUpdate}
            disabled={isDisabled || isChanged}
          >
            Update
          </Button>
        </Stack>
        <Button
          component={Link}
          variant={'outlined'}
          sx={{'&.MuiButton-outlined':{maxWidth: 330, width: '100%', mx: 'auto', mt: 3}}}
          to={'/invitations-gallery'}
        >
          Skip
        </Button>
      </Stack>
    </PageWrapper>
  );
};

export default CompleteProfile;
