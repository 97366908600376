import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  Typography,
  InputLabel,
} from '@mui/material';
import ModalTitle from '../../../components/UI/DialogTitle';
import { Button } from '../../../components/UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clearSlice } from '../slice';
import { useSendRequestForAccommodationMutation } from '../api';
import Input from '../../../components/UI/Input';

const AccommodationModal = ({open, onClose}) => {
  const [form, setForm] = useState({
    tripInfo: ''
  })
  const history = useHistory();
  const dispatch = useDispatch();
  const {user} = useSelector(store => store);
  const [sendRequestForAccommodation] = useSendRequestForAccommodationMutation();

  const handleChange = ({target: {name, value}}) => {
    setForm(state => ({...state, [name]: value}))
  };

  const handleSubmit = () => {
    sendRequestForAccommodation({
      tripInfo: form?.tripInfo,
      userId: user?.user?.id,
    }).then(res => {
      if (res?.data) {
        setForm(state => ({...state, isMessageSubmitted: true}))
      }
    });
  };

  const handleClose = () => {
    if (form?.isMessageSubmitted) {
      dispatch(clearSlice());
      history.push('/my-invitations');
    } else {
      onClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 600,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={handleClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600, textTransform: 'uppercase'}}>Help me organise my experience</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        {!form?.isMessageSubmitted  ? (
          <>
            <InputLabel sx={{mb: 0.5, fontWeight: 400, color: '#373632'}}>
              Share some details about your experience
            </InputLabel>
            <Input
              sx={{mb: 3}}
              name="tripInfo"
              value={form?.tripInfo}
              onChange={handleChange}
              multiline
              maxRows={4}
              minRows={4}
              fullWidth
            />
            <Button
              sx={{margin: '0 auto', display: 'block', maxWidth: 300, width: '100%'}}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!form?.tripInfo}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </>
        ) : (
          <Typography sx={{fontSize: 15, fontWeight: 500}} align={'center'} >
            We have received your request, we will contact you within the next
            24 hours.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccommodationModal;
