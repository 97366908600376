import { Button } from '../../../components/UI/Button';
import { IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCreateApplicationMutation, useUploadApplicationImageMutation, useUploadApplicationVideoMutation } from '../api';
import { dataURLtoBlob } from '../../../util/utils';
import { useHistory } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../../components/Loader';

const FormButtons = ({onNextStep, onPrevStep, showPrev = false, showNext = false, showApply, isNextDisabled }) => {
  const form = useSelector(store => store.application.applicationForm);
  const [createApplication, {isSuccess: isCreateSuccess, error: isCreateError, data: createdApplication, isLoading: isCreateLoading}] = useCreateApplicationMutation();
  const [uploadImages, {isSuccess: isUploadImagesSuccess, error: isUploadImagesError, isLoading: isUploadImageLoading}] = useUploadApplicationImageMutation();
  const [uploadVideo, {isSuccess: isUploadVideoSuccess, error: isUploadVideoError, isLoading: isUploadVideoLoading}] = useUploadApplicationVideoMutation();
  const history = useHistory();
  const hasError = isCreateError || isUploadImagesError || isUploadVideoError;
  const isLoading = isCreateLoading || isUploadImageLoading || isUploadVideoLoading;

  useEffect(() => {
    if (
      (isCreateSuccess && isUploadImagesSuccess && !form.video && !hasError) ||
      (isCreateSuccess && isUploadVideoSuccess && !form.gallery.length && !hasError) ||
      (isCreateSuccess && isUploadImagesSuccess && isUploadVideoSuccess) ||
      (isCreateSuccess && !form.video && !hasError) || (isCreateSuccess && !form.gallery?.length && !hasError)
    ) {
      enqueueSnackbar(`Application Submitted. Await a response from the host. In the meantime, feel free to explore other events`, {
        variant: 'success',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
        </IconButton>
      });
      history.push('/invitations-gallery');
    }
  }, [isCreateSuccess, isUploadImagesSuccess, isUploadVideoSuccess, form?.video, form?.gallery]) //eslint-disable-line

  const onApplyInvitation = () => {
    const {video, gallery, ...body} = form;
    if (!createdApplication) {
      createApplication({body}).then(res => {
        if (res?.data) {
          if (gallery?.length) {
            const images = gallery.map(item => dataURLtoBlob(item))
            uploadImages({id: res?.data?.id, images: images }).then(res => {
              if (video) {
                const videoFile = dataURLtoBlob(video);
                uploadVideo({id: res?.data?.id, video: videoFile });
              }
            });
            return;
          }
          if (video) {
            const videoFile = dataURLtoBlob(video);
            uploadVideo({id: res?.data?.id, video: videoFile });
          }
        }
      });
    } else {
      if (gallery?.length && !isUploadImagesSuccess) {
        const images = gallery.map(item => dataURLtoBlob(item));
        uploadImages({id: createdApplication.id, images: images }).then(() => {
          if (video && !isUploadVideoSuccess) {
            const videoFile = dataURLtoBlob(video);
            uploadVideo({id: createdApplication.id, video: videoFile });
          }
        });
        return;
      }
      if (video && !isUploadVideoSuccess) {
        const videoFile = dataURLtoBlob(video);
        uploadVideo({id: createdApplication.id, video: videoFile });
      }
    }
  };

  return (
    <>
      {(hasError) && (
        <Typography sx={{color: 'red', fontSize: 16}} align={'center'}>
          {isCreateError?.data.error || isUploadImagesError?.data.error || isUploadVideoError?.data.error}
        </Typography>
      )}
      <Stack
        direction={{xs: 'column', md: 'row'}}
        justifyContent={(!showPrev && !showNext) ? 'flex-end' : 'space-between'}
        alignItems={'center'}
        spacing={2}
        sx={{width: '100%', mt: 2}}>
        {showPrev && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onPrevStep}
            sx={{
              maxWidth: "330px",
              width: "100%",
              "& .MuiButton-label": {
                lineHeight: "18px",
                fontWeight: "500",
              },
            }}
          >
            PREVIOUS
          </Button>
        )}
        {showNext && (
          <Button
            variant="contained"
            color={'primary'}
            sx={{
              marginLeft: "auto",
              maxWidth: "330px",
              width: "100%",
              "& .MuiButton-label": {
                lineHeight: "18px",
                fontWeight: "500",
              },
            }}
            onClick={onNextStep}
            disabled={isNextDisabled}
          >
            NEXT
          </Button>
        )}
        {showApply && (
          <Button
            variant="contained"
            color="action"
            sx={{
              padding: "15px",
              maxWidth: "330px",
              width: "100%",
              backgroundColor: "#373632",
              "&.Mui-disabled": {
                opacity: 0.5,
              },
              "@media (max-width:700px)": {
                marginBottom: "20px",
              },
              "& .MuiButton-label": {
                lineHeight: "18px",
                fontWeight: "500",
              },
            }}
            onClick={onApplyInvitation}
          >
            APPLY
          </Button>
        )}
      </Stack>
      {isLoading && <Loader />}
    </>
  );
};

export default FormButtons;
