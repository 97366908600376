import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useDeleteInvitationMutation,
  useEditInvitationBackgroundMutation,
  useInvitationQuery
} from '../api';
import dayjs from 'dayjs';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Stack, Typography, MenuItem, InputLabel } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from "@mui/icons-material/Close";
import PageWrapper from '../../../components/PageWrapper';
import { Button } from '../../../components/UI/Button';
import Dropdown from '../../../components/UI/Dropdown';
import InvitationInfo from './InvitationInfo';
import InvitationTimeline from './InvitationTimeline';
import DeleteConfirmModal from '../DeleteConfirmModal';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCheckIsUserAppliedQuery } from '../../applications/api';
import EventInvitationInfo from './EventInvitationInfo';

const Invitation = () => {
  const {id} = useParams();
  const {user} = useSelector(store => store);
  const {data: invitation, error, isLoading} = useInvitationQuery(id);
  const {data: isUserApplied} = useCheckIsUserAppliedQuery({invitationId: id, userId: user.user.id}, {skip: !id || !user?.user?.id});
  const [deleteInvitation, {isSuccess: isDeleteSuccess, }] = useDeleteInvitationMutation();
  const [updateBackground, {error: editBackgroundError}] = useEditInvitationBackgroundMutation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isDeleteSuccess) {
      setOpen(false);
      history.push('/my-invitations');
    }
  }, [isDeleteSuccess, history]);

  useEffect(() => {
    if (editBackgroundError) {
      enqueueSnackbar(
        `${editBackgroundError?.data?.error === 'File too large' ?
          'Please use file with size less than 10MB' :
          editBackgroundError?.data?.error}`, {
        variant: 'error',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
        </IconButton>
      });
    }
  }, [editBackgroundError])

  const onDeleteDraft = () => {
    const name = invitation?.name;
    deleteInvitation({id}).then(res => {
      if (res?.data) {
        enqueueSnackbar(`${name} was deleted`, {
          variant: 'error',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  const handleChangeBackgroundImage = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    updateBackground({id, image: e.target.files[0]});
  };

  return (
    <PageWrapper>
      {error && !isLoading && <Typography>No invitations found</Typography>}
      {invitation?.deleted && !isLoading && <Typography>Invitation was deleted</Typography>}
      {invitation && !error && !isLoading && !invitation?.deleted && (
        <>
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            alignItems={{xs: 'center', sm: 'flex-end'}}
            justifyContent={'space-between'}
            spacing={{xs: 3, sm: 1}}
            sx={{top: {xs: 65, md: 0}, width: '100%', zIndex: 10, position: 'sticky', pb: 1, background: '#fff'}}
          >
            <Box>
              <Typography sx={{color: "#C19A5B", fontSize: 28, textTransform: 'capitalize'}}>
                {invitation?.name} {invitation?.uid ? `#${invitation?.uid}` : ''}
              </Typography>
              {user?.user?.role === 'user' ? (
                <Stack direction={{xs: 'column', md: 'row'}} alignItems={{xs: 'flex-start', md: 'center'}} spacing={1}>
                  <Typography sx={{color: "#373632", fontSize: 17}}>
                    {dayjs(invitation?.startDate).format("DD MMM YYYY")}{" "}-{" "}{dayjs(invitation?.endDate).format("DD MMM YYYY")}{" "}
                  </Typography>
                  {invitation?.experienceType === 'trip' && (
                    <Typography sx={{color: "#373632", fontSize: 17}}>
                      {invitation?.acceptedApplications || 0} / {invitation?.type === 'guest' ?
                      `${invitation?.numberOfGuest || 0} Guests` :
                      `${invitation?.numberOfCoHosts || 0} CoHosts`}
                    </Typography>
                  )}
                </Stack>
              ) : (
                <Typography sx={{color: "#373632", fontSize: 17}}>
                  {dayjs(invitation?.startDate).format("DD MMM YYYY")} - {" "}{dayjs(invitation?.endDate).format("DD MMM YYYY")}
                </Typography>
              )}
              <IconButton onClick={() => history.goBack()} sx={{position: 'fixed', right: {xs: 0, sm : 20}, top: {xs: 65, md: 0}}}>
                <CloseIcon sx={{color: '#000'}} />
              </IconButton>
            </Box>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: {xs: '100%', sm: 'auto'}, gap: 1}}>
              {invitation?.host.id === user?.user?.id && invitation?.status === 'active' && (
                <Button
                  sx={{height: 'auto', py: 0.5, width: {xs: '100%', sm: 'auto'}}}
                  variant="outlined"
                  startIcon={<CreateIcon />}
                  component={Link}
                  to={`/invitation/${invitation?.id}/edit`}
                >
                  EDIT INVITATION
                </Button>
              )}
              {invitation?.host.id === user?.user?.id && invitation?.status === 'draft' && (
                <>
                  <Button
                    sx={{height: 'auto', py: 0.5, width: {xs: '100%', sm: 'auto'}}}
                    variant="outlined"
                    color={'error'}
                    onClick={() => setOpen(true)}
                  >
                    Delete Draft
                  </Button>
                  <Button
                    sx={{height: 'auto', py: 0.5, width: {xs: '100%', sm: 'auto'}}}
                    variant="outlined"
                    startIcon={<CreateIcon />}
                    component={Link}
                    to={`/create-invitation/${invitation?.id}`}
                  >
                    Finish Draft
                  </Button>
                </>
              )}
              {isUserApplied?.isApplied && (
                <Box sx={{
                  fontSize: 14,
                  color: "black",
                  fontWeight: 600,
                }}>
                  ALREADY APPLIED
                </Box>
              )}
              {!isUserApplied?.isApplied && invitation?.host.id !== user?.user?.id  && user?.user?.role !== 'admin' && (
                <Button
                  sx={{height: 'auto', py: 0.5, minWidth: 170}}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/invitation/${invitation?.id}/apply`}
                >
                  APPLY
                </Button>
              )}
              {user?.user?.role === "admin" && (
                <Dropdown
                  sx={{display: 'flex', alignItems: 'center', justifyContent: {xs: 'center', md: 'flex-start'}}}
                  dropdownItems={
                  <Box>
                    <MenuItem
                      onClick={() => history.push({
                        pathname: '/inbox',
                        state: {activeUser: {username: invitation?.host?.username, id: invitation?.host?.id}}})
                      }
                    >
                      SEND MESSAGE TO HOST
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to={`/invitation/${invitation?.id}/edit`}
                    >
                      EDIT INVITATION
                    </MenuItem>
                    <MenuItem>
                      <InputLabel
                        htmlFor="changeInvitationBg"
                        sx={{
                          cursor: 'pointer',
                          fontSize: 16,
                          textTransform: 'uppercase',
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontWeight: 400,
                        }}
                      >
                        ADD BACKGROUND PHOTO
                        <input
                          accept="image/*"
                          id="changeInvitationBg"
                          type="file"
                          hidden
                          onChange={handleChangeBackgroundImage}
                        />
                      </InputLabel>
                    </MenuItem>
                  </Box>
                }>
                  {<Typography sx={{display: {xs: 'flex', sm: 'none'}}}>Options</Typography>}
                  <MoreVertIcon/>
                </Dropdown>
              )}
            </Box>
          </Stack>
          {invitation?.imgUrl && invitation?.imgUrl !== "" && (
            <Box sx={{
              width: "100%",
              height: {xs: 300, sm: 480},
              '& > img': {
                width: '100%',
                height: '100%',
                objectFit: "cover",
              }
            }}>
              <img
                src={invitation?.imgUrl}
                alt={'hero'}
              />
            </Box>
          )}
          {invitation && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={1}
              sx={{mt: 1}}
            >
              <Grid item xs={12} sm={6}>
                <InvitationTimeline invitation={invitation} />
              </Grid>
              <Grid item xs={12} sm={6}>
                {invitation?.experienceType === 'event' ? <EventInvitationInfo invitation={invitation} /> : <InvitationInfo invitation={invitation} />}

              </Grid>
            </Grid>
          )}
        </>
      )}
      <DeleteConfirmModal open={open} onConfirm={onDeleteDraft} onClose={() => setOpen(false)}/>
    </PageWrapper>
  );
};

export default Invitation;
