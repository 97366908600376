import React from "react";
import howItWorksImage from "./../../assets/how_it_works/how_it_works.jpg";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box, Grid, Typography } from '@mui/material';
import CustomTimelineItem from '../../components/UI/CustomTimelineItem';
import StaticPageWrapper from '../../components/StaticPagesWrapper';

const HOSts_INFO = [
  'As a Host you have the ability to create a personalized invitation for your upcoming vacation, describing your ideal companion and the amazing experience you\'ve planned at stunning locations like Mykonos, Ibiza, Dubai, Miami, Tulum, Maldives, or Bali.',
  'Whether you\'re looking to share  the expenses of your holiday or you are looking to increase the number of participants, or both, Invite.Club has the answers.',
  'Our smart platform matches you with like-minded individuals who share your interests and love for adventure, ensuring the right fit for your vacation. Your personal data will be kept private throughout this process, for your protection.',
  'Guests and other Hosts have the opportunity to express interest by applying to your Invitation. Review applications, containing information such as background, interests, and pictures, and accept, decline, or waitlist them to find the perfect companion.',
  'As a host, cover travel, accommodation, and sustenance expenses for your selected Guests, giving them a worry-free, extraordinary adventure.',
  'Invite.Club provides all-inclusive dedicated travel services through joint ventures with travel agents, making travel arrangements smooth and hassle-free.',
];

const GUESTS_INFO = [
  'Create a profile on our platform, showcasing your interests and personality.',
  'Get hand-picked invitations from affluent, charming, and intelligent hosts planning fantastic vacations in stunning destinations.',
  'Apply to invitations that match your personal preferences, knowing that your host covers all travel, accommodation, and sustenance expenses for a worry-free adventure.',
  'Accept or decline invitations based on your preferences and travel desires, with both your and the host\'s personal details kept private until your application is accepted.',
  'Specify if you\'d like to travel with a friend when applying to invitations, ensuring the perfect travel experience for you.',
  'Enjoy a once-in-a-lifetime vacation where Invite.Club will be facilitating communication and logistics for a smooth and reliable travel arrangement.',
];

const HowItWorks = () => {
  return (
    <StaticPageWrapper>
      <Box sx={{pt: "20px", width: "100%", display: "flex", flexDirection: "column"}}>
        <Box sx={{width: "100%", maxWidth: 870, mx: 'auto', px: 3}}>
          <Typography sx={{fontSize: "24px", color: "#373632", fontWeight: "bold", textAlign: 'left'}}>How It Works</Typography>
        </Box>
        <Box sx={{width: "100%", margin: "30px 0px", '& > img': {width: '100%', height: 'auto', objectFit: 'cover', minHeight: {xs: 150, md: 'auto'}}}}>
          <img src={howItWorksImage} alt={'how-it-works-1'} />
        </Box>
        <Box sx={{width: "100%", maxWidth: 870, mx: 'auto', px: 3}}>
          <Typography sx={{fontSize: "14px", lineHeight: "24px", color: "#C19A5B", fontWeight: "500", mb: 1}}>
            INVITE.CLUB IS A MEMBERS ONLY CLUB.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              letterSpacing: "0.24px",
              lineHeight: "24px",
              mb: 3
            }}
          >
           Welcome to Invite.Club, where luxury vacations and new friendships come together.
            Our members-only social network connects adventurous travellers with amazing companions, creating unforgettable experiences.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              letterSpacing: "0.24px",
              lineHeight: "24px",
              mb: 3
            }}
          >
            New members are carefully reviewed by a dedicated committee and are mostly referred by existing members.
            All Invite.Club members are ID verified. Non-referred membership applications are also considered.
          </Typography>
          <Grid container direction={{xs: 'column', md: 'row'}} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  color: "#C19A5B",
                  fontSize: "14px",
                  fontWeight: "500",
                  mb: 3
                }}
              >HOSTS</Typography>
              <Timeline
                sx={{
                  m: 0,
                  p: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  '& .MuiTimelineItem-root:last-of-type .MuiTimelineSeparator-root .MuiTimelineConnector-root': {
                    display: 'none'
                  }
                }}>
                {HOSts_INFO.map((item, i) => (
                  <CustomTimelineItem description={item} key={`item-${i}`} />
                ))}
              </Timeline>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  color: "#C19A5B",
                  fontSize: "14px",
                  fontWeight: "500",
                  mb: 3
                }}
              >GUESTS</Typography>
              <Timeline
                sx={{
                  m: 0,
                  p: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  '& .MuiTimelineItem-root:last-of-type .MuiTimelineSeparator-root .MuiTimelineConnector-root': {
                    display: 'none'
                  }
                }}>
                {GUESTS_INFO.map((item, i) => (
                  <CustomTimelineItem description={item} key={`item-${i}`} />
                ))}
              </Timeline>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontSize: "16px",
              letterSpacing: "0.24px",
              lineHeight: "24px",
              mb: 3
            }}
          >
            Jump into a world of shared interests, engaging conversations, and the excitement of exploring breathtaking destinations together.
            Join Invite.Club today and experience the perfect mix of style and fun, where every getaway turns into an unforgettable journey.
          </Typography>
        </Box>
      </Box>
    </StaticPageWrapper>
  );
}

export default HowItWorks;
