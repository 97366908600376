import { MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Select from '../../components/UI/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OutlinedInput from '@mui/material/OutlinedInput';
import Dropdown from '../../components/UI/Dropdown';
import Box from '@mui/material/Box';
import { Link, useHistory } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const ReferralUsersTable = ({users, isLoading, onFilter}) => {
  const [userType, setUserType] = useState([]);
  const history = useHistory();

  const onChangeFilter = ({target: {value}}) => {
    setUserType(value);
    onFilter(value);
  };

  return (
    <>
      {isLoading && <Typography>Loading...</Typography>}
      {users?.length > 0 && !isLoading && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{'& > .MuiTableCell-root': { background: '#F9F5EF'}}}>
                <TableCell style={{ width: 250, minWidth: 200 }} >
                  USERNAME
                </TableCell>
                <TableCell style={{ width: 126, minWidth: 126 }} sx={{p: 0}}>
                  <Select
                    name={'status'}
                    sx={{
                      background: 'transparent',
                      '& .MuiSelect-select': {
                        fontSize: 14,
                        fontWeight: 600,
                        '&[aria-expanded="true"]': {
                          background: '#C19A5B',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      }
                    }}
                    multiple
                    autoWidth
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    value={userType}
                    onChange={onChangeFilter}
                    MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
                    input={<OutlinedInput />}
                    renderValue={() => {
                      return <span>USER TYPE</span>;
                    }}
                  >
                    <SelectItem value={'host'} name={'host'} checked={userType.indexOf('host') > -1} />
                    <SelectItem value={'guest'} name={'guest'} checked={userType.indexOf('guest') > -1} />
                  </Select>
                </TableCell>
                <TableCell style={{ width: 250, minWidth: 200 }}>
                  REFERRED USERNAME
                </TableCell>
                <TableCell style={{ width: 126, minWidth: 126 }}>
                  AMOUNT
                </TableCell>
                <TableCell align={'center'} style={{ width: 100, minWidth: 100 }}>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map(item => (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.id} sx={{'& > .MuiTableCell-root': { fontSize: 15, fontWeight: 400, color: '#373632', p: 2, borderBottomColor: '#E1E1E1'}}}>
                  <TableCell>
                    {item.username}
                  </TableCell>
                  <TableCell>
                    {item.role}
                  </TableCell>
                  <TableCell>
                    {item?.signUpReferralUser.username}
                  </TableCell>
                  <TableCell>
                    {item?.credits}$
                  </TableCell>
                  <TableCell align={'center'}>
                    <Dropdown
                      dropdownItems={
                        <Box>
                          <MenuItem
                            sx={{textTransform: 'uppercase', fontSize: 15 }}
                            component={Link}
                            to={`/user/${item?.id}`}
                          >
                            {item?.username}
                          </MenuItem>
                          <MenuItem
                            sx={{textTransform: 'uppercase', fontSize: 15}}
                            onClick={() => history.push({
                              pathname: '/inbox',
                              state: {activeUser: {username: item?.username, id: item?.id}}})
                            }
                          >
                            SEND MESSAGE
                          </MenuItem>
                        </Box>
                      }
                    >
                      <MoreVertIcon/>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {users?.length === 0 && !isLoading && <Typography align={'center'}>No users found</Typography>}
    </>
  )
};

export default ReferralUsersTable;

const SelectItem = ({value, name, checked, ...props}) => {
  return (
    <MenuItem
      sx={{
        minWidth: "126px",
        padding: "5px 10px !important",
        "& span": {
          fontSize: "12px",
          fontWeight: 600,
        },
        "& .MuiCheckbox-root": {
          padding: "0px !important",
          marginRight: "8px",
        },
      }}
      value={value}
      {...props}
    >
      <Checkbox
        sx={{'& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
        disableRipple
        color="default"
        checked={checked}
      />
      <ListItemText
        sx={{textTransform: "uppercase", color: '#000'}}
        primary={name}
      />
    </MenuItem>
  )
};
