import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import { connect } from "react-redux";
import { forgotPassword, resetPassword } from "../redux/actions/userActions.js";
import { Link } from 'react-router-dom';
import { Button } from './UI/Button';

const styles = theme => ({
  header: {
    textAlign: "center",
    backgroundColor: "#F9F5EF",
    borderBottom: "1px solid #8F8F8F",
    "& span": {
      fontSize: "15px",
      fontWeight: 600,
    },
    "& .MuiCardHeader-action": {
      alignSelf: "center !important",
      marginTop: "0px !important",
      marginRight: "0px !important",
    },
    "& button": {
      padding: "0px",
      "& svg": {
        fill: "#8F8F8F",
      },
    },
  },
  card: {
    borderRadius: "10px !important",
    boxShadow: "unset !important",
  },
  label: {
    color: "#373632 !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  body: {
    padding: "25px 130px",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      padding: "25px 15px",
    },
  },
  successBody: {
    padding: "25px 80px",
    margin: "auto",
  },
  successMessage: {
    display: "block",
    textAlign: "center",
  },
  button: {
    marginTop: "50px",
    marginBottom: "20px",
    padding: "14px 0px",
    width: "100%",
    fontSize: "14px !important",
    lineHeight: "18px !important",
  },
  successButton: {
    display: "block",
    margin: "20px auto 0px auto !important",
    padding: "14px 0px",
    width: "50%",
    fontSize: "14px !important",
    lineHeight: "18px !important",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    "& .MuiInputBase-root": {
      border: "1px solid #C19A5B",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Roboto !important",
      fontSize: "15px",
      color: "#F44336",
    },
    "& input": {
      padding: "15px 20px",
      "&::placeholder": {
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
  },

  loginModalLinkSpan: {
    fontFamily: "Roboto !important",
    fontSize: "16px",
  },
  loginModalLink: {
    fontFamily: "Roboto !important",
    fontSize: "16px",
    color: "#C19A5B",
    textDecoration: "underline",
    cursor: "pointer",
  },

  passwordField: {
    "& .MuiInputBase-root": {
      borderRadius: "1px !important",
      border: "1px solid #C19A5B !important",
      "&.Mui-error": {
        border: "1px solid #F44336 !important",
      },
    },

    backgroundColor: "white !important",
    borderRadius: "1px !important",
    border: "1px solid #C19A5B !important",
    "&.Mui-error": {
      border: "1px solid #F44336 !important",
    },

    "& fieldset": {
      display: "none",
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Roboto !important",
      fontSize: "15px",
      color: "#F44336",
      margin: "0px !important;",
    },
    "& input": {
      backgroundColor: "white",
      outline: "unset !important",
      padding: "15px 20px !important",
      "&::placeholder": {
        color: "#000",
        fontSize: "16px",
        opacity: 1,
      },
    },
  },
  errorText: {
    fontFamily: "Roboto !important",
    fontSize: "15px !important",
    color: "#F44336 !important",
    margin: "0px !important;",
  },
});

class ResetPasswordModal extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirmPassword: "",
      errors: {},
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.password !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.password === this.state.confirmPassword
    ) {
      const userData = {
        token: this.props.token,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      };
      this.props.resetPassword(userData);
    } else {
      if (this.state.password === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            password: "Password cannot be empty",
          },
        }));
      }
      if (this.state.confirmPassword === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            confirmPassword: "Confirm Password cannot be empty",
          },
        }));
      }
      if (this.state.password !== this.state.confirmPassword) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            confirmPassword: "Passwords do not match.",
          },
        }));
      }
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "password" && "password" in this.state.errors) {
      delete this.state.errors["password"];
    }
    if (
      event.target.name === "confirmPassword" &&
      "confirmPassword" in this.state.errors
    ) {
      delete this.state.errors["confirmPassword"];
    }
  };
  handleClickShowPassword = () => {
    this.setState({
      ...this.state.password,
      showPassword: !this.state.showPassword,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      ...this.state.confirmPassword,
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton
              aria-label="Close"
              onClick={this.props.handleResetPasswordClose}
            >
              <CloseIcon />
            </IconButton>
          }
          title="RESET YOUR PASSWORD"
        />
        {!this.props.user.resetPassword ? (
          <CardContent className={classes.body}>
            <form
              className={classes.loginForm}
              noValidate
              onSubmit={this.handleSubmit}
            >
              <InputLabel className={classes.label} htmlFor="password">
                Password
              </InputLabel>
              <FilledInput
                id="password"
                name="password"
                type={this.state.showPassword ? "text" : "password"}
                placeholder="Enter Password"
                variant="outlined"
                className={classes.textField + " " + classes.passwordField}
                value={this.state.password}
                onChange={this.handleChange}
                disableUnderline={true}
                helperText={errors.password}
                error={!!errors.password}
                fullWidth
                endAdornment={
                  <InputAdornment
                    style={{ backgroundColor: "transparent" }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff style={{ color: "#6B6963" }} />
                      ) : (
                        <Visibility style={{ color: "#6B6963" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="password" className={classes.errorText}>
                {errors.password}
              </FormHelperText>
              <InputLabel
                className={classes.label}
                htmlFor="password"
                style={{ marginTop: "30px" }}
              >
                Confirm Password
              </InputLabel>
              <FilledInput
                id="confirmPassword"
                name="confirmPassword"
                type={this.state.showConfirmPassword ? "text" : "password"}
                placeholder="Re Enter Password"
                variant="outlined"
                className={classes.textField + " " + classes.passwordField}
                disableUnderline={true}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                helperText={errors.confirmPassword}
                error={!!errors.confirmPassword}
                fullWidth
                endAdornment={
                  <InputAdornment
                    style={{ backgroundColor: "transparent" }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmPassword}
                      onMouseDown={this.handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {this.state.showConfirmPassword ? (
                        <VisibilityOff style={{ color: "#6B6963" }} />
                      ) : (
                        <Visibility style={{ color: "#6B6963" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                id="confirmPassword"
                className={classes.errorText}
              >
                {errors.confirmPassword}
              </FormHelperText>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
                fullWidth
                sx={{my: 2}}
              >
                RESET PASSWORD
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>{" "}
            </form>
            <span className={classes.loginModalLinkSpan}>
              Problem with your account?{" "}
              <Link className={classes.loginModalLink} to="/contact">
                Contact us
              </Link>
            </span>
          </CardContent>
        ) : (
          <CardContent className={classes.successBody}>
            <span className={classes.successMessage}>
              Password was successfully reset.
            </span>
            <Button
              variant="contained"
              color="primary"
              className={classes.successButton}
              onClick={this.props.handleLoginOpen}
              fullWidth
            >
              LOG IN
            </Button>
          </CardContent>
        )}
      </Card>
    );
  }
}

ResetPasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionToProps = {
  forgotPassword,
  resetPassword,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles)(ResetPasswordModal));
