import React from 'react';
import { Stack } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';

const PageWrapper = ({children, sx}) => {
  return (
    <>
      <Navbar />
      <Stack
        direction={'column'}
        sx={{
          width: {xs: '100%', md: 'calc(100% - 350px)'},
          marginLeft: 'auto',
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          px: 3,
          pt: {xs: '100px', md: '30px'},
          pb: {xs: '20px', md: '65px'},
          ...sx
        }}
      >
        {children}
      </Stack>
      <Footer />
    </>
  )
};

export default PageWrapper;
