import { createSlice } from '@reduxjs/toolkit';
import { notificationsApi } from './api';

const initialState = {
  newNotificationsCount: 0,
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, {payload}) => {
      state.newNotificationsCount = payload.length;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      notificationsApi.endpoints.notifications.matchFulfilled,
      (state, {payload}) => {
        state.newNotificationsCount = payload.reduce((prev, acc) => (
          !acc.read && acc.title !== "Application cancelled" ? prev + 1 : prev + 0
        ), 0);
      }
    )
  }
});

export const { setNotifications } = notifications.actions;
export default notifications.reducer;
