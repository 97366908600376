import React, { useState } from 'react';
import { Grid, Stack, Tabs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/UI/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageWrapper from '../../../components/PageWrapper';
import InvitationCard from '../../../components/InvitationCard';
import { useInvitationsQuery } from '../api';
import TabItem from '../../../components/UI/TabItem';

const InvitationsGallery = () => {
  // const {user} = useSelector(store => store);
  const [params, setParams] = useState({page: 1, limit: 25, experienceType: 'trip'});
  const {data, isLoading} = useInvitationsQuery({params});
  const [tab, setTab] = useState(0);

/*  const handleFiltersClick = (name, value) => {
    if (!value.length) {
      const {[name]: unUsed, ...newParams} = params;
      setParams(newParams);
    } else {
      setParams(params => ({...params, [name]: value}));
    }
  };*/

  // const handleClearFilters = () => {
  //   setParams({page: 1, limit: 25});
  // };

  const handleChangeTab = (event, newValue) => {
    setParams(state => ({...state, experienceType: newValue === 0 ? 'trip' : 'event'}))
    setTab(newValue);
  };

  return (
    <PageWrapper>
      <Stack direction={{xs: 'column', sm: 'row'}} alignItems={{xs: 'flex-start', sm: 'center'}} justifyContent={'space-between'} spacing={2} sx={{mb: 2}}>
        <Typography sx={{fontWeight: 500}}>Invitations gallery</Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/create-invitation"
          startIcon={<AddCircleIcon />}
          sx={{height: 'auto', py: 1, color: '#000'}}
        >
          CREATE INVITATION
        </Button>
      </Stack>
      {/*Advanced filters for host and user*/}
{/*      {user?.user?.role !== 'admin' && (
        <InvitationsDetailedFilters />
      )}*/}
{/*      {user?.user?.role === 'admin' && (
       <InvitationsFilters
         sx={{mb: 3}}
         itemsCount={data?.data?.length}
         handleClear={handleClearFilters}
         handleFilter={handleFiltersClick} />
      )}*/}
      <Tabs value={tab} onChange={handleChangeTab} sx={{minHeight: 'auto', mb: 3, '& .MuiTabs-indicator': {backgroundColor: '#C19A5B'}}}>
        <TabItem label="Trips" />
        <TabItem label="Events" />
      </Tabs>
      {data?.data?.length > 0 && !isLoading && (
        <>
          <Grid container spacing={3} >
            {data?.data?.map((invitation) => (
              <Grid item xs={12} sm={6} md={4} xl={3} key={invitation.id}>
                <InvitationCard invitation={invitation} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {!data?.data?.length && !isLoading && (
        <Typography sx={{fontSize: 16, mt: 3}}>No Invitations Found</Typography>
      )}
      {isLoading && (<Typography sx={{fontSize: 16, mt: 3}}>...Loading</Typography>)}
    </PageWrapper>
  );
};

export default InvitationsGallery;
