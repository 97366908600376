import { Box, Menu as MuiMenu } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Dropdown = ({children, dropdownItems, showArrow = false, sx, ...props}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }

  return (
    <>
      <Box
        sx={theme => ({cursor: 'pointer', '& > svg': {color: anchorEl && dropdownItems ? theme.palette.action.active : 'primary'}, ...sx})}
        onClick={handleMenu}
      >
        {children}
        {showArrow && (
          <ExpandMoreIcon
            sx={{
              fontSize: 20,
              color: '#000',
              position: 'relative',
              transform: anchorEl ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 200ms ease-in 200ms',
            }}
          />
        )}
      </Box>
      {dropdownItems && (
        <MuiMenu
          onClick={(e) => e.stopPropagation()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
          PaperProps={{
            elevation: 2
          }}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            sx: {
              p: 0,
              minWidth: anchorEl && anchorEl.offsetWidth
            },
          }}
          {...props}
        >
          {dropdownItems}
        </MuiMenu>
      )}
    </>
  );
};

export default Dropdown
