import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import ModalTitle from '../../components/UI/DialogTitle';
import ProfileForm from './ProfileForm';

const EditProfile = ({open, onClose}) => {

return (
  <Dialog
    open={open}
    onClose={onClose}
    sx={{
      '& .MuiPaper-root': {
        maxWidth: 535,
        width: '100%',
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "#b0b0b04d",
      },
    }}
  >
    <ModalTitle onClose={onClose}>
      <Typography sx={{fontSize: 15, fontWeight: 600}}>EDIT PROFILE</Typography>
    </ModalTitle>
    <DialogContent sx={{p: 3, mt: 3}}>
      <ProfileForm onSuccess={() => onClose()} />
    </DialogContent>
  </Dialog>
)};

export default EditProfile;
