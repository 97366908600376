import React from 'react';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { Box, Stack, Typography, Link } from '@mui/material';
import CustomTimelineItem from '../../../components/UI/CustomTimelineItem';
import TravelPackage from '../../../components/TravelPackage';
import { useSelector } from 'react-redux';
import { Link as NavLink, useLocation } from 'react-router-dom';

const InvitationTimeline = ({invitation}) => {
  const {user} = useSelector(store => store.user);
  const location = useLocation();
  const isCreate = location.pathname.includes('/create-invitation');

  return (
    <>
      <Timeline
        sx={{
          m: 0,
          p: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          '& .MuiTimelineItem-root:last-of-type .MuiTimelineSeparator-root .MuiTimelineConnector-root': {
            display: 'none'
          }
        }}>
     {/*   {invitation?.tagline && (
          <CustomTimelineItem title={'Tagline'} description={invitation?.tagline} />
        )}
        {invitation?.itinerary && (
          <CustomTimelineItem title={'Itinerary'} description={invitation?.itinerary} />
        )}*/}
        {invitation?.additionalInfo && (
          <CustomTimelineItem title={invitation?.experienceType === 'trip' ? 'Trip Information' : 'Event information'} description={invitation?.additionalInfo} />
        )}
        {invitation?.activities && (
          <CustomTimelineItem title={'Activities'} description={invitation?.activities} />
        )}
        {invitation?.associatedEvent && (
          <CustomTimelineItem title={'Associated Event'} description={invitation?.associatedEvent} />
        )}
        {invitation?.numberOfCoHosts && (
          <CustomTimelineItem title={'Number of CoHosts'} description={invitation?.numberOfCoHosts} />
        )}
        {invitation?.genderOfCoHosts && (
          <CustomTimelineItem title={'Gender of CoHosts'} description={invitation?.genderOfCoHosts[0].toUpperCase() + invitation?.genderOfCoHosts.slice(1)} />
        )}
        {(Boolean(invitation?.host?.interests?.length) || Boolean(user?.interests?.length)) && invitation?.experienceType === 'trip' && (
          <CustomTimelineItem title={'Interests of Host'} description={(isCreate || user?.id === invitation?.host?.id) ? user?.interests?.join(', ') : invitation?.host?.interests?.join(', ')} />
        )}
        {invitation?.hostGallery.length > 0 && (
          <CustomTimelineItem title={'Picture of Host'}>
            <Stack direction={'row'} spacing={1}>
              {invitation?.hostGallery.map((image, i) => (
                <Box
                  key={`hostImage-${i}`}
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    aspectRatio: '1',
                    justifyContent: 'center',
                    backgroundColor: '#AFAFAF',
                    width: '25%',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    '& > img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }
                  }}
                >
                  <img src={image} alt={`hostImage-${i}`} />
                </Box>
              ))}
            </Stack>
          </CustomTimelineItem>
        )}
        {invitation?.coHostsGallery.length > 0 && (
          <CustomTimelineItem title={'Picture of CoHost'}>
            <Stack direction={'row'} spacing={1}>
              {invitation?.coHostsGallery.map((image, i) => (
                <Box
                  key={`hostImage-${i}`}
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    aspectRatio: '1',
                    justifyContent: 'center',
                    backgroundColor: '#AFAFAF',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: '25%',
                    '& > img': {
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }
                  }}
                >
                  <img src={image} alt={`coHostImage-${i}`} />
                </Box>
              ))}
            </Stack>
          </CustomTimelineItem>
        )}
        {invitation?.travelPackage && (
          <CustomTimelineItem title={'Travel Category'}>
            <TravelPackage
              title={invitation?.travelPackage?.title}
              color={invitation?.travelPackage?.color}
              description={invitation?.travelPackage?.description}
            />
          </CustomTimelineItem>
        )}
        {invitation?.host?.id && (
          <CustomTimelineItem title={'Host'}>
            <Link component={NavLink} to={`/user/${invitation?.host?.id}`} sx={{'&:hover .MuiTypography-root':{textDecoration: 'underline'}}}>
              {invitation?.host?.imgUrl?.length > 0 && (
                <Box sx={{mb: 1, width: 46, height: 46, '& > img, & > svg': {width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%'}}}>
                  <img src={invitation?.host?.imgUrl[0]} alt="host" />
                </Box>
              )}
              <Typography sx={{fontSize: 12, color: '#373632', display: 'inline-block'}}>
                @{invitation?.host?.username}
              </Typography>
            </Link>
          </CustomTimelineItem>
        )}
      </Timeline>
    </>
  );
};

export default InvitationTimeline;
