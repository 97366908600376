import { Portal, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Loader = () => (
  <Portal>
    <Stack alignItems={'center'} justifyContent={'center'} sx={{
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.5)',
      zIndex: 10000
    }}>
      <CircularProgress size={100} thickness={10} sx={{color: '#C19A5B'}}/>
    </Stack>
  </Portal>
);

export default Loader;
