import React from "react";
import { NavLink } from 'react-router-dom';
import { Box, Container, Link, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

const Footer = ({isVisibleOnMobile = false, isStaticFooter = false}) => {
  return (
    <Box sx={{
      zIndex: 100,
      position: "fixed",
      bottom: 0,
      width: "100%",
      py: 2,
      backgroundColor: "#fff",
      display: {xs: isVisibleOnMobile ? 'flex' : 'none', md: 'flex'},
    }}>
      <Container
        maxWidth={'xl'}
        sx={{
          maxWidth: isStaticFooter ? 1320 : '100%',
          display: {xs: isVisibleOnMobile ? 'flex' : 'none', md: 'flex'},
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: 'center',
          alignItems: {xs: 'center', md: 'flex-start'},
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={{xs: 'flex-start', sm: 'center'}}
          spacing={4}
          sx={{flexWrap: {sm: 'nowrap'}, overflowX: {xs: 'auto', md: 'visible'},  width: {xs: '100%', md: 'auto'}, pb: {xs: 2, md: 0}}}
        >
          <FooterNav/>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;

export const FooterNav = ({sx}) => {
  const {authenticated} = useSelector(store => store.user);

  return (
    <>
      {authenticated && (
        <Link component={NavLink} sx={{...LinkSx, ...sx}} to="/faq">
          FAQ
        </Link>
      )}
      {authenticated && (
        <Link component={NavLink}  sx={{...LinkSx, ...sx}} to="/how-it-works">
          HOW IT WORKS
        </Link>
      )}
      <Link sx={{...LinkSx, ...sx}} href="mailto:contactus@invite.club">
        CONTACT US
      </Link>
      <Link component={NavLink}  sx={{...LinkSx, ...sx}} to="/terms-and-conditions">
        TERMS & CONDITIONS
      </Link>
      <Link component={NavLink}  sx={{...LinkSx, ...sx}} to="/privacy-policy">
        PRIVACY POLICY
      </Link>
    </>
  )
};

const LinkSx = {
  width: {xs: 'auto', sm: 'auto'},
  flexShrink: 0,
  color: "#696969",
  fontSize: "12px",
  fontWeight: 500,
  textDecoration: "none",
  position: "relative",
  fontFamily: 'GothamBook, sans-serif !important',
  "&:after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    right: 0,
    width: 0,
    bottom: -5,
    background: "#000",
    height: '1px',
    transition: "width 0.3s ease-in-out",
  },
  "&:hover:after, &.active:after": {
    left: 0,
    right: "auto",
    width: "100%",
  },
};
