import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";

const styles = {
  header: {
    textAlign: "center",
    backgroundColor: "#F9F5EF",
    borderBottom: "1px solid #8F8F8F",
    "& span": {
      fontSize: "16px",
      fontWeight: 600,
    },
    "& .MuiCardHeader-action": {
      alignSelf: "center !important",
      marginTop: "0px !important",
      marginRight: "0px !important",
    },
    "& button": {
      padding: "0px",
      "& svg": {
        fill: "#8F8F8F",
      },
    },
  },
  card: {
    borderRadius: "10px !important",
    boxShadow: "unset !important",
  },

  body: {
    maxWidth: "608px",
    width: "100%",
    padding: "40px 62px !important",
    margin: "auto",
    boxSizing: "border-box",
  },
  bodyTextWrapper: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.24px",
    fontFamily: "Roboto !important",
    textAlign: "center",
    display: "block",
  },
  bodyTextName: {
    fontWeight: "bold",
  },
};

class SubmitMessageModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }

  render() {
    const {
      classes,
      handleSubmitMessageClose,
      title,
      success,
    } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton aria-label="Close" onClick={handleSubmitMessageClose}>
              <CloseIcon />
            </IconButton>
          }
          title={title}
        />
        <CardContent className={classes.body}>
          <span
            className={classes.bodyTextWrapper}
            dangerouslySetInnerHTML={{ __html: success }}
          />
        </CardContent>
      </Card>
    );
  }
}

SubmitMessageModal.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionToProps = {};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles)(SubmitMessageModal));
