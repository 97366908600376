import VideoUploader from './VideoUploader';
import { Box, IconButton, Stack } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

const Video = ({onChange, onDelete, video, videoHeight = 245, isLoading = false, sx}) => {
  return (
    <Box sx={{mb: 3, position: 'relative', ...sx}}>
      {!video && <VideoUploader onChange={onChange} />}
      {video && (
        <Box sx={{width: '100%', height: videoHeight, position: 'relative', '& > video': {width: '100%', height: '100%', objectFit: 'cover'}}}>
          <video controls>
            <source src={video} type="video/mp4" />
          </video>
          {onDelete && (
            <IconButton
              onClick={() => onDelete(video)}
              sx={{
                background: '#000',
                position: 'absolute',
                right: 10,
                top: 10,
                m: 0,
                p: 0
              }}
            >
              <CancelIcon />
            </IconButton>
          )}
        </Box>
      )}
      {isLoading && (
        <Stack alignItems={'center'} justifyContent={'center'} sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.5)',
          zIndex: 10000
        }}>
          <CircularProgress size={100} thickness={10} sx={{color: '#C19A5B'}}/>
        </Stack>
      )}
    </Box>
  );
};

export default Video;
