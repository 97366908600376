import React, { Component } from "react";
import AccordionComp from "../../components/StaticPages/AccordionCompPrivacy";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import StaticPageWrapper from '../../components/StaticPagesWrapper';

const styles = {
  toggle: {
    marginTop: "60px",
  },
};
class PrivacyPolicy extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: true,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <StaticPageWrapper>
        <div className="static-wrapper">
          <span className="static-title">Privacy Policy</span>

          <div className={classes.toggle}>
            <AccordionComp />
          </div>
        </div>
      </StaticPageWrapper>
    );
  }
}

PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(withStyles(styles)(PrivacyPolicy));
