import { IconButton, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { Button } from '../../components/UI/Button';
import React, { useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import { useAddUserMutation } from './api';
import Label from '../../components/UI/Label';
import Select from '../../components/UI/Select';
import Input from '../../components/UI/Input';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

const AddUser = () => {
  const history = useHistory();
  const [addUser, {isLoading, error}] = useAddUserMutation();
  const [form, setForm] = useState({
    role: '',
    email: '',
    username: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const isDisabled = Object.values(form)?.some(item => item === '') || Object.values(errors).some(item => Boolean(item)) || isLoading;

  const onChange = ({target: {name, value}}) => {
    setForm(state => ({...state, [name]: value}))
    if (value === "") {
      setErrors((state) => ({...state, [name]: `${name} cannot be empty`,}));
    }
    switch (name) {
      case "email":
        if (!String(value).toLowerCase().match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
          setErrors(state => ({...state, [name]: 'Enter a valid Email Address'}))
        } else {
          setErrors(state => ({...state, [name]: null}))
        }
        break;
      case "password":
        if (value.length < 8) {
          setErrors(state => ({...state, [name]: 'Password too short'}))
        } else if (value.length > 25) {
          setErrors(state => ({...state, [name]: 'Password too long'}))
        } else {
          setErrors(state => ({...state, [name]: null}))
        }
        break;
      default: setErrors(state => ({...state, [name]: null}));
    }
  };

  const onSubmit = () => {
    addUser({body: {...form, acceptTerms: true, confirmPassword: form?.password}}).then(res => {
      if (res.data) {
        enqueueSnackbar(`user was created`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        history.push('/user-management');
      }
    });
  }

  return (
    <PageWrapper>
      <Stack direction={{xs: 'column', sm: 'row'}} alignItems={'center'} justifyContent={'space-between'} spacing={1} sx={{mb: 3}}>
        <Typography sx={{fontWeight: 500}}>Add Users Manually</Typography>
        <IconButton onClick={() => history.goBack()}>
          <CloseIcon sx={{color: '#000'}} />
        </IconButton>
      </Stack>
      <Stack direction={'column'} sx={{maxWidth: 345, width: '100%', mx: 'auto'}}>
        <Label>User Type</Label>
        <Select
          value={form?.role || ''}
          name={'role'}
          onChange={onChange}
          sx={{mb: 3}}
          error={errors.role}
        >
          <MenuItem value={'host'}>
            Host
          </MenuItem>
          <MenuItem value={'guest'}>
            Guest
          </MenuItem>
        </Select>
        <Label>Email address</Label>
        <Input
          value={form?.email || ''}
          onChange={onChange}
          name={'email'}
          error={Boolean(errors?.email)}
          helperText={errors?.email}
          sx={{mb: 3}}
        />
        <Label>Username</Label>
        <Input
          value={form?.username || ''}
          onChange={onChange}
          name={'username'}
          error={Boolean(errors?.username)}
          helperText={errors?.username}
          sx={{mb: 3}}
        />
        <Label>Password</Label>
        <Input
          value={form?.password || ''}
          onChange={onChange}
          name={'password'}
          type={showPassword ? 'text' : 'password'}
          error={Boolean(errors?.password)}
          helperText={errors?.password}
          sx={{mb: 3}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={() => setShowPassword(state => !state)}>
                {showPassword ? <VisibilityOff style={{ fill: "#696969" }} />: <Visibility style={{ fill: "#696969" }} />}
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
        {error && <Typography color={'red'} sx={{mb: 3}}>{error.data.error}</Typography>}
        <Button variant={'contained'} disabled={isDisabled} onClick={onSubmit}>
          ADD USER
        </Button>
      </Stack>
    </PageWrapper>
  )
};

export default AddUser;
