import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.invite.club/v1/',
  // baseUrl: 'http://localhost:3164/v1/', // local env
  prepareHeaders: (headers) => {
    const token = JSON.parse(localStorage.getItem("IdToken"))?.Token;
    if (token) {
      headers.append('authorization', token)
    }
    return headers
  }
});

export const TAGS = {
  HOST_INVITATIONS: 'HOST_INVITATIONS',
  INVITATION: 'INVITATION',
  DRAFT: 'DRAFT',
  APPLICATIONS: 'APPLICATIONS',
  APPLICATION: 'APPLICATION',
  USER: 'USER',
  USERS: 'USERS',
  WAITLISTED_USERS: 'WAITLISTED_USERS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  CONVERSATIONS: 'CONVERSATIONS',
  CONVERSATION: 'CONVERSATION',
  APPLIED_USERS: 'APPLIED_USERS',
}

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery,
  tagTypes: Object.values(TAGS),
  endpoints: (build) => ({
    getUser: build.query({
      query: () => 'auth/me',
      providesTags: [TAGS.USER],
    }),
    travelCategories: build.query({
      query: ({params}) => {
        return {url: 'packages', params};
      },
    }),
    updateProfile: build.mutation({
      query: ({id,body}) => {
        return {
          url: `users/${id}`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.USER, TAGS.APPLICATION],
    }),
    updateProfileImg: build.mutation({
      query: ({id, image}) => {
        const formData = new FormData();
        formData.append('img', image);
        return {
          url: `/users/image/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
      invalidatesTags: [TAGS.USER],
    }),
    deleteProfileImg: build.mutation({
      query: ({id, body}) => {
        return {
          url: `/users/delete-image/${id}`,
          method: 'DELETE',
          body
        };
      },
      invalidatesTags: [TAGS.USER],
    }),
    usersUsedReferrals: build.query({
      query: () => 'users/byrefcode',
    }),
  })
});

export const {
  useLazyGetUserQuery,
  useTravelCategoriesQuery,
  useUpdateProfileMutation,
  useUpdateProfileImgMutation,
  useUsersUsedReferralsQuery,
  useDeleteProfileImgMutation,
} = mainApi;
