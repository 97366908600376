import {
  Icon,
  Stack,
  Typography
} from '@mui/material';
import FormWrapper from '../../invitations/create-invitation/FormWrapper';
import InfoIcon from '@mui/icons-material/Info';
import Label from '../../../components/UI/Label';
import Input from '../../../components/UI/Input';
import { Button } from '../../../components/UI/Button';
import previewIconFile from '../../../assets/create_inv/preview.svg';
import FooterButtons from './FooterButtons';
import PreviewApplication from '../PreviewApplication';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setError, updateForm } from '../slice';
import InfoModal from './InfoModal';
import Video from '../../../components/Video';
import { blobToDataURL, getVideoDuration } from '../../../util/utils';
import ImageRow from '../../invitations/ImageRow';

const ApplyCoHostInvitation = ({name, uid, requiredFields}) => {
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const {application: {applicationForm: form, errors}, user} = useSelector(store => store);
  const dispatch = useDispatch();

  const onChange = ({target: {value, name}}) => {
    if (name === 'messageToHost' && value.length > 700) {
      dispatch(updateForm({key: name, value: value.slice(0, 700)}));
      dispatch(setError({key: 'messageToHost', value: 'Message to the Host should be less than 700 characters'}));
      return;
    }
    dispatch(setError({key: 'messageToHost', value: null}));
    dispatch(updateForm({key: name, value: value}))
  }

  const onChangeVideo = (e) => {
    getVideoDuration(e.target.files[0]).then((duration) => {
      if (duration > 30) {
        dispatch(setError({key: 'video', value: 'Video duration should be less than 30 seconds.'}));
        return;
      }
      if (!e.target.files[0]) {
        return;
      }
      if (e?.target?.files[0].size > 60000000) {
        dispatch(setError({key: 'video', value: 'Video size should be less than 60mb.'}));
        return;
      }
      blobToDataURL(e?.target?.files[0], (dataUrl) => {
        dispatch(updateForm({
          key: 'video',
          value: dataUrl
        }));
      });
      dispatch(setError({key: 'video', value: null}));
      e.target.value = '';
    });
  }

  const onChangeImage = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (e?.target?.files[0].size > 4000000) {
      dispatch(setError({key: 'gallery', value: 'Image size should be less than 4mb.'}));
      return;
    }
    blobToDataURL(e?.target?.files[0], (dataUrl) => {
      dispatch(updateForm({
        key: 'gallery',
        value: !form?.gallery?.includes(dataUrl) ? [...form?.gallery || [], dataUrl] : form?.gallery || []
      }));
    });
    dispatch(setError({key: 'gallery', value: null}));
    e.target.value = '';
  };

  const onDeleteImage = (value) => {
    dispatch(updateForm({key: 'gallery', value: form?.gallery?.filter(item => item !== value)}))
  }

  const onDeleteVideo = () => {
    dispatch(updateForm({key: 'video', value: ''}));
  };

  const userInfo = {
    shortBio: user?.user?.shortBio || '',
    gender: user?.user?.gender || '',
    occupationSector: user?.user?.occupationSector || '',
    interests: user?.user?.interests || []
  }

  return (
    <>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Apply To Invitation</Typography>
        <Typography sx={{fontSize: 14, fontWeight: 500, mb: 2, display: 'flex', textTransform: 'capitalize'}}>
          {name} {uid && `#${uid}`}
        </Typography>
        <Stack direction={'row'} spacing={1} onClick={() => setOpen(true)} sx={{cursor: 'pointer', mb: 2}}>
          <InfoIcon sx={{color: '#c19a5b'}} />
          <Typography sx={{fontSize: 14, lineHeight: '20px', letterSpacing: '0.21px'}}>
            Certain information is automatically retrieved from your public profile details. You can edit this by editing your profile.
          </Typography>
        </Stack>
        <Label>Personal Message to the Host</Label>
        <Input
          name={'messageToHost'}
          value={form?.messageToHost || ''}
          onChange={onChange}
          sx={{mb: errors?.messageToHost ? 1 : 3}}
          multiline
          maxRows={4}
          minRows={4}
          error={Boolean(errors?.messageToHost)}
        />
        {errors?.messageToHost && <Typography sx={{fontSize: 14, mb: 3}} color={'red'}>{errors?.messageToHost}</Typography>}
        {requiredFields?.pictures && (
          <>
            <Label>Upload at least one picture of you</Label>
            <ImageRow
              fiveImages
              images={form?.gallery || []}
              onChange={onChangeImage}
              onDelete={onDeleteImage}
              error={errors?.gallery}
            />
          </>
        )
        }
        {requiredFields?.video && (
          <>
            <Label>Upload a Short Video (up to 30 sec.) of you. Supported format (mp4, mov)</Label>
            <Video video={form?.video || null} onChange={onChangeVideo} onDelete={onDeleteVideo} sx={{mb: errors?.video ? 1 : 4}} />
            {errors?.video && <Typography sx={{fontSize: 14, mb: 3, color: 'red'}}>{errors?.video}</Typography>}
          </>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenPreview(true)}
          sx={{py: 0.5}}
          startIcon={
            <Icon sx={{width: 'auto', height: 'auto'}}>
              <img src={previewIconFile} alt={'preview'} />
            </Icon>
          }
        >
          PREVIEW APPLICATION
        </Button>
      </FormWrapper>
      <FooterButtons showApply/>
      <InfoModal open={open} onClose={() => setOpen(false)} />
      <PreviewApplication open={openPreview} onClose={() => setOpenPreview(false)} application={{...form, ...userInfo}} />
    </>
  );
};

export default ApplyCoHostInvitation;
