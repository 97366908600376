import React, { useEffect, useState } from 'react';
import { Box, IconButton, ListItem, Stack, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '../../components/UI/Input';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useCreateConversationMutation } from './api';
import { useSelector } from 'react-redux';
import { useUsersQuery } from '../user-management/api';
import { useHistory } from 'react-router-dom';

const CreateConversation = ({onSuccess, conversations, onChangeConversation, activeValue}) => {
  const {user} = useSelector(store => store.user);
  const {data: users} = useUsersQuery();
  const [value, setValue] = useState([])
  const [message, setMessage] = useState('');
  const [onCreate, {isLoading}] = useCreateConversationMutation();
  const history = useHistory();

  useEffect(() => {
    if (activeValue && !conversations?.find(item => (item?.users?.length === 2 && item?.users?.find(user => user?.id === history.location.state?.activeUser?.id)))) {
      setValue([activeValue])
    }
  }, [activeValue, conversations, history.location.state?.activeUser?.id])

  const onChangeUser = (value) => {
    setValue(state => state.find(item => item.id === value.id) ? state.filter(item => item.id !== value.id) : [...state, value]);
  };

  const onChangeMessage = ({target: {value}}) => {
    if (!value) {
      setMessage('');
      return;
    }
    setMessage(value);
  };

  const onCreateMessage = () => {
    onCreate({users: value.map(item => item?.id), message: message}).then(res => {
      if (res.data) {
        onSuccess(res.data.id);
        if (history.location.state?.activeUser) {
          history.replace({ state: {} });
        }
      }
    });
  };

  const handleClose = () => {
    if (!value.length) return;
    const newConversationUsersId = [...value.map(item => item?.id), user.id];
    const activeConversation = conversations.find(item => (
      JSON.stringify(item.users.map(item => item.id).sort()) === JSON.stringify(newConversationUsersId.sort()))
    );
    if (activeConversation) {
      onChangeConversation(activeConversation.id)();
    }
  }

  return (
    <Stack direction={'column'} justifyContent={'space-between'} sx={{height: '100%'}}>
      <Box>
        <Typography sx={{fontWeight: 500, mr: 0.5, mb: 2, fontSize: 15}}>
          Please select user or users and send message
        </Typography>
        <Autocomplete
          sx={{mb: 3}}
          multiple
          disableCloseOnSelect
          disableClearable
          noOptionsText={'No users found'}
          options={users?.filter(item => item?.username) || []}
          getOptionLabel={(option) => option?.username}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <Input
              {...params}
              placeholder="Search user"
              sx={{'& .MuiOutlinedInput-root': {height: 'auto'}}}
            />
          )}
          value={value}
          onClose={handleClose}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              key={option.id}
              onClick={(e) => {
                onChangeUser({username: option?.username, id: option?.id, imgUrl: option?.imgUrl});
                props.onClick(e)
              }}
              value={{username: option?.username, id: option?.id}}
              sx={{
                opacity: '1 !important',
                '&[aria-disabled="true"]': {
                  color: '#ccc'
                }
              }}
            >
              {option.username}
            </ListItem>
          )}
          renderTags={(items) =>
            items.map((option) => (
              <Tip
                {...option}
                key={option?.id}
                // onDelete={onDeleteTag(option.id)}
                value={option.username}
              />
            ))
          }
        />
        <Box>
          {value?.length > 1 && (
            <Box sx={{position: 'relative', width: 87, height: 87, flexShrink: 0, mb: 2, mx: 'auto'}}>
              <Box sx={{...MultipleUsersAvatarSx, left: 0, bottom: 0, zIndex: 2}}>
                {value[0]?.imgUrl ?
                  (<img src={value[0]?.imgUrl} alt={`application-item`} />) :
                  (<AccountCircleIcon viewBox={'2 2 20 20'} preserveAspectRatio='none' />)
                }
              </Box>
              <Box sx={{...MultipleUsersAvatarSx, right: 0, top: 0, zIndex: 1}}>
                {value[0]?.imgUrl ? <img src={value[1]?.imgUrl} alt={`application-item`} /> : <AccountCircleIcon viewBox={'2 2 20 20'} preserveAspectRatio='none' />}
              </Box>
              {value?.length > 2 && (
                <Box sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  color: '#c19a5b',
                  fontWeight: '900',
                  fonSize: 18,
                }}>
                  +{value?.length - 2}
                </Box>
              )}
            </Box>
          )}
          {value.length === 1 && (
            <Box
              sx={{
                flexShrink: 0,
                borderRadius: '50%',
                width: 87,
                height: 87,
                overflow: 'hidden',
                mx: 'auto',
                mb: 2,
                '& > img, & > svg': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                },
              }}
            >
              {value[0]?.imgUrl ? <img src={value[0]?.imgUrl} alt={`application-item`} /> : <AccountCircleIcon viewBox={'2 2 20 20'} preserveAspectRatio='none' />}
            </Box>
          )}
          <Typography sx={{fontSize: 14, fontWeight: 600}} align={'center'}>{value.map(item => item?.username).join(', ')}</Typography>
        </Box>
      </Box>
      <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{mt: 5}}>
        <Input
          fullWidth
          placeholder={'Respond to Message…'}
          value={message}
          onChange={onChangeMessage}
        />
        <IconButton
          onClick={onCreateMessage} disabled={isLoading || !message || value?.length === 0}
        >
          <SendIcon sx={{color: '#C19A5B'}} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default CreateConversation;

const Tip = ({value}) => (
  <Box
    variant="outlined"
    sx={{
      display: 'flex',
      alignItems: 'center',
      p: '0.25rem 0.5rem',
      backgroundColor: '#C19A5B',
      borderRadius: '4px',
      gap: 0.5,
      cursor: 'pointer',
      '& + .MuiBox-root': {
        ml: 1
      }
    }}>
    <Typography sx={{fontWeight: 600, mr: 0.5, fontSize: 12}} color={'#fff'}>
      {value}
    </Typography>
  </Box>
);

const MultipleUsersAvatarSx = {
  borderRadius: '50%',
  overflow: 'hidden',
  border: '1px solid #fff',
  width: 57,
  height: 57,
  position: 'absolute',
  '& > img, & > svg': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
};
