import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import guest from '../../../assets/guest.svg';
import coHosts from '../../../assets/cohosts.svg';
import { updateDraft } from '../slice';
import { Typography, Icon, ToggleButton } from '@mui/material';
import VerticalButtonGroup from '../../../components/VerticalButtonGroup';
import FormButtons from './FormButtons';
import FormWrapper from './FormWrapper';
import AccommodationModal from './AccommodationModal';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import EventIcon from '@mui/icons-material/Event';

const SelectInvitationType = () => {
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleType = (e, value) => {
    dispatch(updateDraft({key: 'type', value: value}));
  }

  const handleExperienceType = (e, value) => {
    dispatch(updateDraft({key: 'experienceType', value: value}));
  }

  return (
    <>
      <FormWrapper direction={'column'} sx={{margin: '0px auto', maxWidth: 550, width: '100%'}}>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation</Typography>
        <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>SELECT EXPERIENCE TYPE</Typography>
        <VerticalButtonGroup
          value={newInvitation?.draft?.experienceType}
          onChange={handleExperienceType}
          exclusive
        >
          <ToggleButton value="trip">
            <ConnectingAirportsIcon sx={{color: '#C19A5B', fontSize: 30}} />
            <Typography
              sx={{
                fontSize: "14px !important",
                color: "#373632",
                fontWeight: "600",
                mt: 0.5,
                textTransform: 'initial',
              }}
            >Trip</Typography>
          </ToggleButton>
          <ToggleButton value="event">
            <EventIcon sx={{color: '#C19A5B', fontSize: 25}} />
            <Typography
              sx={{
                fontSize: "14px !important",
                color: "#373632",
                fontWeight: "600",
                mt: 0.5,
                textTransform: 'initial',
              }}
            >Event</Typography>
           {/* <Typography
              sx={{
                fontSize: "15px !important",
                color: "#373632",
                fontWeight: "normal",
                mt: 0.5,
                textTransform: 'initial',
              }}
            >
              To share the expenses of your trip
            </Typography>*/}
          </ToggleButton>
        </VerticalButtonGroup>
        {newInvitation?.draft?.experienceType && (
          <>
            <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2, mt: 3}}>SELECT INVITATION TYPE</Typography>
            <VerticalButtonGroup
              value={newInvitation?.draft?.type}
              onChange={handleType}
              aria-label="invitation type"
              exclusive
            >
              <ToggleButton
                value="guest"
                aria-label="guest"
              >
                <Icon sx={{display: 'flex', width: 20, height: 24, "& img": {width: "100%"}}}>
                  <img src={`${guest}`} alt={'guest'} />
                </Icon>
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    color: "#373632",
                    fontWeight: "600",
                    mt: 0.5,
                    textTransform: 'initial',
                  }}
                >Invite Guests</Typography>
                <Typography
                  sx={{
                    fontSize: "15px !important",
                    color: "#373632",
                    fontWeight: "normal",
                    mt: 0.5,
                    textTransform: 'initial',
                  }}
                >
                  To participate
                </Typography>
              </ToggleButton>
              <ToggleButton
                value="coHost"
                aria-label="coHost"
              >
                <Icon sx={{display: 'flex', width: 20, height: 24, "& img": {width: "100%"}}}>
                  <img src={`${coHosts}`} alt={'coHost'} />
                </Icon>
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    color: "#373632",
                    fontWeight: "600",
                    mt: 0.5,
                    textTransform: 'initial',
                  }}
                >Invite Hosts</Typography>
                <Typography
                  sx={{
                    fontSize: "15px !important",
                    color: "#373632",
                    fontWeight: "normal",
                    mt: 0.5,
                    textTransform: 'initial',
                  }}
                >
                  To share the expenses
                </Typography>
              </ToggleButton>
            </VerticalButtonGroup>
          </>
        )}
        <Typography
          sx={{
            color: '#C19A5B',
            fontSize: 15,
            textDecoration: 'underline',
            my: 3,
            mx: 'auto',
            cursor: 'pointer',
            maxWidth: 550, width: '100%'
          }}
          onClick={onOpen}
        >
          I would like Invite.Club to help me organise my experience
        </Typography>
      </FormWrapper>
      <FormButtons showNext isNextDisabled={!newInvitation?.draft?.type || !newInvitation?.draft?.experienceType} />
      <AccommodationModal open={open} onClose={onClose} />
    </>
  );
};

export default SelectInvitationType;
