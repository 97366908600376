import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from './UI/Button';
import { Stack } from '@mui/material';

const styles = () => ({
  card: {
    borderRadius: "10px !important",
    boxShadow: "unset !important",
  },
  header: {
    textAlign: "center",
    backgroundColor: "#F9F5EF",
    borderBottom: "1px solid #8F8F8F",
    "& span": {
      fontSize: "15px",
      fontWeight: 600,
    },
    "& .MuiCardHeader-action": {
      alignSelf: "center !important",
      marginTop: "0px !important",
    },
    "& button": {
      padding: "0px",
      "& svg": {
        fill: "#8F8F8F",
      },
    },
  },

  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    "@media(max-width:640px)": {
      flexDirection: 'column',
      gap: 16,
      alignItems: 'center',
    },
  },
  body: {
    maxWidth: "100%",
    padding: "43px 60px 95px !important",
    "@media(max-width:640px)": {
      padding: "43px 15px 45px !important",
    },
  },
  button: {
    marginTop: "30px",
    width: "100%",
  },
  joinHostButton: {
    width: "100%",
    maxWidth: "300px",
    marginRight: " 20px !important",
    padding: "15px 65px",
    fontSize: "14px !important",
    backgroundColor: "#373632 !important",
    boxShadow: "unset",
    lineHeight: 1,
    color: "white",
    whiteSpace: "nowrap",
    "@media(max-width:640px)": {
      marginRight: "0 !important",
    },
  },
  joinGuestButton: {
    width: "100%",
    maxWidth: "300px",
    padding: "15px 65px",
    color: "white",
    fontSize: "14px !important",
    boxShadow: "unset",
    lineHeight: 1,
    whiteSpace: "nowrap",
  },
  registerLinkWrapper: {
    padding: "0px !important",
    width: "100% !important",
  },
  registerLink: {
    padding: "0px 60px",
    marginBottom: "25px",
    fontSize: "16px",
    color: "#373632",
    "@media(max-width:640px)": {
      padding: "0px 15px",

    },
    "& span": {
      fontSize: "16px",
      cursor: "pointer",
      color: "#C19A5B",
      textDecoration: "underline",
    },
  },
});
class RegisterModal extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton
              aria-label="Close"
              onClick={this.props.handleRegisterClose}
            >
              <CloseIcon />
            </IconButton>
          }
          title="APPLY INVITE.CLUB"
        />
        <CardContent className={classes.body}>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
            <Button
              variant="contained"
              color="action"
              onClick={this.handleRegisterClose}
              component={Link}
              to="/join/host"
              fullWidth
            >
              Apply AS HOST
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRegisterClose}
              component={Link}
              to="/join/guest"
              fullWidth
            >
              Apply AS GUEST
            </Button>
          </Stack>
        </CardContent>
        <CardActions className={classes.registerLinkWrapper}>
          <span className={classes.registerLink}>
            Already have an account?{" "}
            <span onClick={this.props.handleLoginOpen}>Log in</span>
          </span>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(RegisterModal);
