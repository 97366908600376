import { styled } from '@mui/material/styles';
import { Radio } from '@mui/material';

const RadioButton = ({...props}) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      {...props}
    />
  );
};

export default RadioButton;

const Icon = styled("span")(() => ({
  borderRadius: "4px",
  border: "1px solid #C19A5B",
  width: 22,
  height: 22,
  position: "relative",
}));

const CheckedIcon = styled(Icon)({
  ':before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '3px',
    left: '7px',
    width: '5px',
    height: '10px',
    border: '1px solid #373632',
    borderWidth: ' 0 2px 2px 0',
    transform: 'rotate(45deg)',
  },
});
