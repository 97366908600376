import React from 'react';
import { Box } from '@mui/material';

const ScrollableSidebar = ({children, sx}) => (
  <Box sx={{flexGrow: 1, overflowY: 'auto', pr: {xs: 0, md: 2}, mr: {xs: 0, md: 1.5}, ...sx}}>
    {children}
  </Box>
);

export default ScrollableSidebar;
