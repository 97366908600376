import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles, styled } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions.js";
import Input from './UI/Input';
import { Button } from './UI/Button';

const styles = theme => ({
  header: {
    textAlign: "center",
    backgroundColor: "#F9F5EF",
    borderBottom: "1px solid #8F8F8F",
    "& span": {
      fontSize: "15px",
      fontWeight: 600,
    },
    "& .MuiCardHeader-action": {
      alignSelf: "center !important",
      marginTop: "0px !important",
      marginRight: "0px !important",
    },
    "& button": {
      padding: "0px",
      "& svg": {
        fill: "#8F8F8F",
      },
    },
  },
  card: {
    borderRadius: "10px !important",
    boxShadow: "unset !important",
  },
  label: {
    color: "#373632 !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  body: {
    padding: "25px 130px",
    margin: "auto",
    "@media(max-width:550px)": {
        padding: "25px 15px",
    },
  },
  button: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "14px 0px",
    width: "100%",
    fontSize: "14px !important",
    lineHeight: "18px !important",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    marginBottom: "30px",
    "& .MuiInputBase-root": {
      border: "1px solid #C19A5B",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "15px",
      color: "#F44336",
    },
    "& input": {
      padding: "15px 20px",
      "&::placeholder": {
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
  },
  passwordTextField: {
    marginBottom: "30px",
    borderRadius: "0px !important",
    backgroundColor: "transparent !important",

    "&.Mui-error": {
      border: "1px solid #F44336",
    },

    '& .MuiInputBase-root': {
      paddingRight: 12,
    },

    "& input": {
      padding: "15px 20px !important",
      "&::placeholder": {
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
  },
  loginModalLinkSpan: {
    fontSize: "14px",
  },
  loginModalLink: {
    fontSize: "14px",
    color: "#C19A5B",
    textDecoration: "underline",
    cursor: "pointer",
  },
  rememberMeCheck: {
    "& > span": {
      fontWeight: "400 !important",
      paddingTop: "0px",
      paddingBottom: "0px",
      marginBottom: "20px",
      marginTop: '20px'
    },
  },
  customError: {
    fontSize: "15px",
    color: "#F44336",
    marginBottom: "20px",
    marginTop: '20px'
  },
});

const BpIcon = styled("span")({
  borderRadius: "4px",
  border: "1px solid #C19A5B",
  width: 22,
  height: 22,
  position: "relative",
});
const BpCheckedIcon = styled(BpIcon)({
  "&:before": {
    display: "block",
    content: '""',
    position: "absolute",
    top: "3px",
    left: "7px",
    width: "5px",
    height: "10px",
    border: "solid #373632",
    borderWidth: " 0 2px 2px 0",
    transform: "rotate(45deg)",
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
class LoginModal extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: { usernameEr: "", passwordEr: "" },
      showPassword: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.username !== "" && this.state.password !== "") {
      const userData = {
        username: this.state.username,
        password: this.state.password,
      };
      this.props.loginUser(userData);
    } else {
      if (this.state.username === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            usernameEr: "Username cannot be empty",
          },
        }));
      }
      if (this.state.password === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            passwordEr: "Password cannot be empty",
          },
        }));
      }
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleClickShowPassword = () => {
    this.setState({
      ...this.state.password,
      showPassword: !this.state.showPassword,
    });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton
              aria-label="Close"
              onClick={this.props.handleLoginClose}
            >
              <CloseIcon />
            </IconButton>
          }
          title="WELCOME BACK"
        />
        <CardContent className={classes.body}>
          <form
            className={classes.loginForm}
            noValidate
            onSubmit={this.handleSubmit}
          >
            <InputLabel className={classes.label} htmlFor="username">
              Username
            </InputLabel>
            <Input
              id="username"
              name="username"
              type="text"
              placeholder="Enter Username"
              value={this.state.username}
              onChange={this.handleChange}
              helperText={errors.usernameEr}
              error={!!(errors.usernameEr || errors.error)}
              fullWidth
            />
            <InputLabel className={classes.label} style={{marginTop: '20px'}} htmlFor="password">
              Password
            </InputLabel>
            <Input
              id="password"
              name="password"
              placeholder="Enter Password"
              type={this.state.showPassword ? "text" : "password"}
              value={this.state.password}
              onChange={this.handleChange}
              error={!!(errors.passwordEr || errors.error)}
              helperText={errors.passwordEr}
              InputProps={{
                endAdornment:
                  <InputAdornment
                    style={{ backgroundColor: "transparent" }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff style={{ fill: "#696969" }} />
                      ) : (
                        <Visibility style={{ fill: "#696969" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
              }}
              fullWidth
            />
            {errors.error && (
              <Typography variant="body2" className={classes.customError}>
                {errors.error}
              </Typography>
            )}
            <FormControlLabel
              className={classes.rememberMeCheck}
              control={<BpCheckbox defaultChecked />}
              label="Remember me"
            />
            <span
              className={classes.loginModalLink}
              onClick={this.props.handleForgotPasswordOpen}
            >
              Forgot your password?
            </span>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
              }}
              disabled={loading}
            >
              LOG IN
              {loading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
            </Button>{" "}
          </form>
          <span className={classes.loginModalLinkSpan}>
            Don't have an account?{" "}
            <span
              className={classes.loginModalLink}
              onClick={this.props.handleRegisterOpen}
            >
              Become a member
            </span>
          </span>
        </CardContent>
      </Card>
    );
  }
}

LoginModal.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionToProps = {
  loginUser,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles)(LoginModal));
