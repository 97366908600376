import React from "react";
import { useHistory } from "react-router-dom";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import dayjs from "dayjs";
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import TravelPackageBadge from './UI/TravelPackageBadge';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button } from './UI/Button';

const InvitationCard = ({
  invitation: {
    id,
    name,
    travelPackage,
    startDate,
    endDate,
    deadlineOfApplications,
    numberOfGuest,
    numberOfCoHosts,
    status,
    imgUrl,
    totalApplications,
    acceptedApplications,
    type,
  }, showStatus = false, showApplications = false
}) => {
  const history = useHistory();

  const goToApplications = (e) => {
    e.stopPropagation();
    history.push(`/applications/${id}`);
  };

  return (
    <Card
      sx={{
        width: "100%",
        height: 340,
        position: "relative",
        borderRadius: "0px",
        boxShadow: "unset",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "visible",
        cursor: "pointer",
        backgroundColor: "#AFAFAF",
        backgroundPosition: "center",
        backgroundImage: imgUrl && `url(${imgUrl})`,
        '& > a': {
          textDecoration: "unset",
        },
      }}
      onClick={() => history.push(`/invitation/${id}`)}
    >
      {showApplications && (
        <Button
          variant={'contained'}
          color={'secondary'}
          sx={{
            textTransform: 'uppercase',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'calc(100% - 16px)',
            height: 'auto',
            py: 1,
          }}
          onClick={goToApplications}
        >
          {totalApplications} {totalApplications === 1 ? 'Application' : 'Applications'}
        </Button>
      )}
      <CardContent sx={{
        padding: "35px 15px 15px 15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}>
        {travelPackage && <TravelPackageBadge title={travelPackage.title} color={travelPackage.color} small />}
        <Stack direction={'column'} justifyContent={'flex-start'} sx={{zIndex: 2}}>
          <Typography sx={{
            color: "#FFFFFF",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}>
            {name}
          </Typography>
          <Typography sx={{
            color: "#FFFFFF",
            fontSize: "17px",
            fontWeight: "400",
          }}>
            {dayjs(startDate).format("DD MMM YYYY")} -{" "}
            {dayjs(endDate).format("DD MMM YYYY")}
          </Typography>
        </Stack>
        {showStatus && (
          <Typography
            sx={{
              marginTop: "auto",
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: "600",
              color: "#FFFFFF",
              marginBottom: "10px",
              zIndex: "5",
              display: 'flex',
              alignItems:'center'
            }}>
            {status === 'draft' && <DescriptionIcon sx={{ml: '-4px', mr: '2px'}} />}
            {status}
          </Typography>
        )}
        <Stack direction={'row'} justifyContent={'space-between'} sx={{zIndex: 2, width: "100%",}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <EventAvailableIcon sx={{color: status === 'completed' ? 'red' : '#fff', mr: 0.5, ml: -0.5}} />
            <Typography sx={{fontSize: "13px", fontWeight: "bold", color: status === 'completed' ? 'red' : '#fff'}}>
              {dayjs(deadlineOfApplications).format("DD MMM YYYY")}
            </Typography>
          </Box>
          {type === 'coHost' ? (
            <Typography sx={{fontSize: "13px", fontWeight: 500, color: "#FFFFFF"}}>
              {acceptedApplications || 0} / {numberOfCoHosts || 0} COHOSTS
            </Typography>
          ) : (
            <Typography sx={{fontSize: "13px", fontWeight: 500, color: "#FFFFFF"}}>
              {acceptedApplications || 0} / {numberOfGuest || 0} GUESTS
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default InvitationCard;
