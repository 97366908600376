import thunk from "redux-thunk";
import { configureStore } from '@reduxjs/toolkit';
import { mainApi } from '../services/mainApi';
import userReducer from "./reducers/userReducer";
import uiReducer from "./reducers/uiReducer";
import newInvitation from '../features/invitations/slice';
import application from '../features/applications/slice'
import inbox from '../features/inbox/slice'
import notifications from '../features/notifications/slice'

const middlewares = [thunk, mainApi.middleware];

const apiReducers = {
  [mainApi.reducerPath]: mainApi.reducer
}

const store = configureStore({
  reducer: {
    ...apiReducers,
    user: userReducer,
    UI: uiReducer,
    newInvitation,
    application,
    inbox,
    notifications
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
})

export default store;
