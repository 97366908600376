import React from 'react';
import { Typography, ToggleButton } from '@mui/material';

const ActivityItem = ({value, ...props}) => {
  switch(value) {
    case 'Music':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎵</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Books':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>📚</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Dancing':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>💃</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Travel':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🌍</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Art':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎨</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Podcasts':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎧</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Photography':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>📸</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Fitness':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🏋️‍♀️</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Gaming':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>️🎮</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Meditation':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🧘‍</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Parties':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🥳</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Wellness':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🌱</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Wine':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🍷</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Movies':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎥</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Sports':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>⚽️</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Fine Dining':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🍽️</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Cooking':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🍳</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Music Skills':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎶</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Diving':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🤿</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Tech Skills':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>💻</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Networking':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🤝</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Painting':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎨</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Mixology':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🍸</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Podcasting':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎙️</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Digital Art and Design':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🖌️</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Fashion':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>👗</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'DJing':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🎧</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Environmentalism':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>♻️</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    case 'Astrology':
      return (
        <ToggleButton value={value} {...props}>
          <Typography>🌌</Typography>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
    default:
      return (
        <ToggleButton value={value} {...props}>
          <Typography>{value}</Typography>
        </ToggleButton>
      )
  }
};

export default ActivityItem;
