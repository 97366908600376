import { timelineItemClasses } from '@mui/lab/TimelineItem';
import CustomTimelineItem from '../../../components/UI/CustomTimelineItem';
import React from 'react';
import Timeline from '@mui/lab/Timeline';
import ActivityItem from '../../../components/ActivityItem';
import ActivityWrapper from '../../../components/ActivityWrapper';
import ThumbSlider from '../../../components/ThumbSlider';
import Video from '../../../components/Video';
import { Grid, Typography, Link, Box } from '@mui/material';
import dayjs from 'dayjs';
import { Link as NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Application = ({application, isTwoColumnsLayout = false}) => {
  const {user} = useSelector(store => store.user);

  return (
    <Grid container>
      <Grid item xs={12} sm={isTwoColumnsLayout ? 6 : 12}>
        <Timeline
          sx={{
            m: 0,
            p: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            '& .MuiTimelineItem-root:last-of-type .MuiTimelineSeparator-root .MuiTimelineConnector-root': {
              display: 'none'
            }
          }}>
          {application?.messageToHost && (
            <CustomTimelineItem title={'Personal Greeting'} description={application?.messageToHost} />
          )}
          {application?.type === 'guest' && application?.experienceType === 'trip' && (
            <>
              {application?.departurePoint && (
                <CustomTimelineItem title={'Departure Point'} description={application?.departurePoint?.description} />
              )}
              {application?.returnPoint && (
                <CustomTimelineItem title={'Return Point'} description={application?.returnPoint?.description} />
              )}
              <CustomTimelineItem title={'You have been to this location'} description={application?.visitedBefore ? 'Yes' : 'No'} />
            </>
          )}
          {application?.gallery?.length > 0 && (
            <CustomTimelineItem title={'Photos'}>
              <ThumbSlider images={application?.gallery} />
            </CustomTimelineItem>
          )}
          {application?.video && (
            <CustomTimelineItem title={'Video'}>
              <Video video={application?.video} videoHeight={{xs: 200, sm: 345}} />
            </CustomTimelineItem>
          )}
          {application?.shortBio && (
            <CustomTimelineItem title={'Short Bio'} description={application?.shortBio} />
          )}
          {application?.gender && (
            <CustomTimelineItem title={'Gender'} description={application?.gender} />
          )}
          {application?.type === 'guest' && application?.experienceType === 'trip' && (
            <>
              {application?.nationality && (
                <CustomTimelineItem title={'Nationality'} description={application?.nationality} />
              )}
              {application?.age > 0 && (
                <CustomTimelineItem title={'Age'} description={application?.age} />
              )}
              {application?.birthDate && (
                <CustomTimelineItem title={'Age'} description={dayjs().diff(dayjs(application?.birthDate), 'year')} />
              )}
            </>
          )}
          {application?.occupationSector && (
            <CustomTimelineItem title={'Industry'} description={application?.occupationSector} />
          )}
          {application?.interests?.length > 0 && (
            <CustomTimelineItem title={'Interests'}>
              <ActivityWrapper>
                {application?.interests?.map(item => (<ActivityItem value={item} key={item}/>))}
              </ActivityWrapper>
            </CustomTimelineItem>
          )}
          {application?.type === 'guest' && application?.experienceType === 'trip' && (
            <>
              {application?.specialRequirements && (
                <CustomTimelineItem title={'Special Requirements'} description={application?.specialRequirements} />
              )}
              {application?.linkedApplicationUser?.username && user?.id === application?.user?.id && (
                <CustomTimelineItem title={'Linked with'}>
                  <Typography sx={{fontWeight: 600, fontSize: 15}}>{application?.linkedApplicationUser?.username} </Typography>
                </CustomTimelineItem>
              )}
            </>
          )}
          {application?.user?.id && user?.id !== application?.user?.id && (
            <CustomTimelineItem title={application?.type === 'guest' ? 'Guest' : 'CoHost'}>
              <Link component={NavLink} to={`/user/${application?.user?.id}`} sx={{'&:hover .MuiTypography-root':{textDecoration: 'underline'}}}>
                {application?.user?.imgUrl?.length > 0 && (
                  <Box sx={{width: 46, height: 46, mb: 1, '& > img': {width: '100%', height: '100%', objectFit: 'cover'}, borderRadius: '50%', overflow: 'hidden'}}>
                    <img src={application?.user?.imgUrl[0]} alt="host" />
                  </Box>
                )}
                <Typography sx={{fontSize: 12, color: '#373632', display: 'inline-block'}}>
                  @{application?.user?.username}
                </Typography>
              </Link>
            </CustomTimelineItem>
          )}
        </Timeline>
      </Grid>
      {isTwoColumnsLayout && (
        <Grid item xs={12} sm={6}>
          {application?.gallery?.length > 0 && (
            <Box sx={{mb: 3}}>
              <Typography sx={{
                color: "#C19A5B",
                fontSize: 16,
                fontWeight: 500,
                mb: 1,
                lineHeight: '1'
              }}>
                Photos
              </Typography>
              <ThumbSlider images={application?.gallery} />
            </Box>
          )}
          {application?.video && (
            <>
              <Typography sx={{
                color: "#C19A5B",
                fontSize: 16,
                fontWeight: 500,
                mb: 1,
                lineHeight: '1'
              }}>
                Video
              </Typography>
              <Video video={application?.video} videoHeight={{xs: 200, sm: 345}} />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Application;
