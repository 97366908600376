import { InputAdornment, Typography } from '@mui/material';
import React from 'react';
import FormButtons from '../FormButtons';
import FormWrapper from '../FormWrapper';
import Label from '../../../../components/UI/Label';
import GoogleAutocomplete from '../../../../components/GoogleAutocomplete';
import DatePicker from '../../../../components/UI/DatePicker';
import dayjs from 'dayjs';
import AccommodationTypeSelect from '../../../../components/AccommodationTypeSelect';
import { useDispatch, useSelector } from 'react-redux';
import { updateDraft } from '../../slice';
import Input from '../../../../components/UI/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '../../../../components/UI/Select';

const CoHostStepOne = () => {
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const isDisabled = !newInvitation?.draft?.locationFrom || !newInvitation?.draft?.locationTo || !newInvitation?.draft?.startDate ||
    !newInvitation?.draft?.endDate || !newInvitation?.draft?.typeOfAccommodation || !newInvitation?.draft?.additionalInfo || !newInvitation?.draft?.totalRooms ||
    !newInvitation?.draft?.roomsTaken || !newInvitation?.draft?.tripType || !newInvitation?.draft?.totalCost;

  const handleDateChange = (e) => (key) => {
    dispatch(updateDraft({key, value: dayjs(e?.$d || null).format()}));
  };

  const handleChange = ({target: {name, value, max}}) => {
    dispatch(updateDraft({key: name, value}));
    if (max && +value > +max) {
      dispatch(updateDraft({key: name, value: max}));
    }
  };

  const handleLocation = (key) => (value) => {
    if (key === 'locationFrom' && !newInvitation?.draft?.locationTo) {
      dispatch(updateDraft({key: 'locationTo', value}));
    }
    dispatch(updateDraft({key, value}));
  };

  return (
   <>
     <FormWrapper>
       <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation for Hosts</Typography>
       <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>TRIP INFORMATION</Typography>
       <Label>Starting Location</Label>
       <GoogleAutocomplete
         sx={{mb: 4}}
         value={newInvitation?.draft?.locationFrom || null}
         handleChange={handleLocation('locationFrom')}
         error={newInvitation?.error?.locationFrom}
       />
       <Label>Ending Location</Label>
       <GoogleAutocomplete
         sx={{mb: 4}}
         value={newInvitation?.draft?.locationTo || null}
         handleChange={handleLocation('locationTo')}
         error={newInvitation?.error?.locationTo}
       />
       <Label>From</Label>
       <DatePicker
         name={'startDate'}
         value={newInvitation?.draft?.startDate || null}
         onChange={(e) => handleDateChange(e)('startDate')}
         minDate={dayjs().add(1, "day")}
         maxDate={
           newInvitation?.draft?.endDate
             ? dayjs(newInvitation?.draft?.endDate).add(-1, "day")
             : dayjs().add(1, "year")
         }
         sx={{mb: 4}}
       />
       <Label>To</Label>
       <DatePicker
         name={'endDate'}
         value={newInvitation?.draft?.endDate || null}
         onChange={(e) => handleDateChange(e)('endDate')}
         minDate={
           newInvitation?.draft?.startDate ? dayjs(newInvitation?.draft?.startDate).add(1, "day") : dayjs().add(2, "day")
         }
         sx={{mb: 4}}
       />
       <Label>Accommodation</Label>
       <AccommodationTypeSelect
         name="typeOfAccommodation"
         value={newInvitation?.draft?.typeOfAccommodation || ''}
         onChange={handleChange}
         sx={{mb: 4}}
       />
       <Label>Trip Information</Label>
       <Input
         name="additionalInfo"
         placeholder="Fill in"
         multiline
         maxRows={5}
         value={newInvitation?.draft?.additionalInfo}
         onChange={handleChange}
         sx={{mb: 4}}
       />
       <Label>Associated Event •<em>Optional</em></Label>
       <Input
         name="associatedEvent"
         placeholder="Fill in"
         value={newInvitation?.draft?.associatedEvent || ''}
         onChange={handleChange}
         sx={{mb: 4}}
       />
       <Label>Link of Accommodation <em>• Optional</em></Label>
       <Input
         name={'linkOfAccommodation'}
         value={newInvitation?.draft?.linkOfAccommodation || ''}
         onChange={handleChange}
         sx={{mb: 4}}
       />
       <Label>Accommodates up to:</Label>
       <Input
         name={'totalRooms'}
         type={'number'}
         value={newInvitation?.draft?.totalRooms || ''}
         inputProps={{inputMode: 'numeric', min: 1}}
         onChange={handleChange}
         sx={{mb: 4}}
       />
       <Label>People available</Label>
       <Input
         name={'roomsTaken'}
         type={'number'}
         inputProps={{inputMode: 'numeric', min: 0, max: newInvitation?.draft?.totalRooms}}
         value={newInvitation?.draft?.roomsTaken || ''}
         onChange={handleChange}
         disabled={!newInvitation?.draft?.totalRooms}
         sx={{mb: 4}}
       />
       <Label>Type of Trip</Label>
       <Select
         name={'tripType'}
         value={newInvitation?.draft?.tripType || ''}
         onChange={handleChange}
         sx={{mb: 4}}
       >
         <MenuItem value={"singles"}>Singles</MenuItem>
         <MenuItem value={"couples"}>Couples</MenuItem>
         <MenuItem value={"family"}>Both</MenuItem>
       </Select>
       <Label>Accommodation Cost</Label>
       <Input
         name={'totalCost'}
         value={newInvitation?.draft?.totalCost || ''}
         type={'number'}
         InputProps={{
           startAdornment: <InputAdornment position="start">$</InputAdornment>,
         }}
         onChange={handleChange}
         sx={{mb: 4}}
       />
     </FormWrapper>
     <FormButtons showPrev showNext isNextDisabled={isDisabled} />
   </>
  );
};

export default CoHostStepOne;
