import React from 'react';
import "./App.css";
import { Route, Switch } from 'react-router-dom';
import axios from "axios";
import { logoutUser, getUserData } from "./redux/actions/userActions";
import Home from "./pages/Home";
import Onboarding from "./pages/static/Onboarding";
import settingsSecurityAndLogin from "./pages/settingsSecurityAndLogin";
import settingsNotifications from "./pages/settingsNotifications";
import HowItWorks from "./pages/static/HowItWorks";
import TermsConditions from "./pages/static/TermsConditions";
import PrivacyPolicy from "./pages/static/PrivacyPolicy";
import FAQ from "./pages/static/FAQ";
import { LicenseInfo } from "@mui/x-license-pro";
import Contact from "./pages/static/Contact";
import ContactInner from "./pages/static/ContactInner";
import PageNotFound from './components/StaticPages/PageNotFound';
import PrivateRoute from './components/Routes/PrivateRoute';
import AuthRoute from './components/Routes/AuthRoute';
import { SET_AUTHENTICATED } from './redux/types';
import store from './redux/store';
import Invitations from './features/invitations';
import CreateInvitation from './features/invitations/create-invitation';
import Invitation from './features/invitations/invitation/Invitation';
import InvitationsGallery from './features/invitations/invitations-gallery';
import ApplyInvitation from './features/applications/apply-invitation';
import EditApplication from './features/applications/edit-application';
import Applications from './features/applications';
import InvitationApplications from './features/applications/InvitationApplications';
import Profile from './features/profile';
import CompleteProfile from './features/profile/CompleteProfile';
import ProfileReferrals from './features/profile/ProfileReferrals';
import Notifications from './features/notifications';
import Inbox from './features/inbox';
import UserManagement from './features/user-management';
import AddUser from './features/user-management/AddUser';
import UserProfile from './features/user-management/UserProfile';
import SignUp from './features/auth/sign-up';
import FoundingMembersProgram from './pages/static/FoundingMembersProgram';
import FinishApplyMembership from './features/auth/FinishApplyMembership';
import EditInvitation from './features/invitations/edit-invitation';

LicenseInfo.setLicenseKey(
  "b1aa006cc295fe2181198a6a5c5549a1Tz00NDYxNixFPTE2ODUyODU3NTgwMjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);
axios.defaults.baseURL = 'https://api.invite.club/v1/';
// axios.defaults.baseURL = "http://localhost:3164/v1/";//local.env

if (localStorage.getItem("IdToken")) {
  const token = JSON.parse(localStorage.getItem("IdToken"))?.Token;
  const tokenExp = JSON.parse(localStorage.getItem("IdToken"))?.Exp;

  if (tokenExp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}

const App = () => {
  return (
    <Switch>
      <AuthRoute exact path="/" component={Home} />
      <AuthRoute exact path="/login" component={Home}/>
      <AuthRoute exact path="/auth/reset-password" component={Home} />
      <AuthRoute exact path="/join" component={SignUp} />
      <AuthRoute exact path="/finish-applying" component={FinishApplyMembership} />
      <PrivateRoute exact path="/how-it-works" component={HowItWorks} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/contact/:type" component={ContactInner} />
      <Route exact path="/terms-and-conditions" component={TermsConditions}/>
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/founding/members/info/" component={FoundingMembersProgram} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/complete-your-profile" component={CompleteProfile}/>
      <PrivateRoute exact path="/profile-referrals" component={ProfileReferrals}/>
      <PrivateRoute exact path="/onboarding" component={Onboarding} />
      <PrivateRoute exact path="/notifications" component={Notifications} />
      <PrivateRoute exact path="/user-management" component={UserManagement} />
      <PrivateRoute exact path="/add-user" component={AddUser} />
      <PrivateRoute exact path="/user/:id" component={UserProfile} />
      <PrivateRoute exact path="/settings" component={settingsSecurityAndLogin}/>
      <PrivateRoute exact path="/settings/notifications/" component={settingsNotifications}/>
      <PrivateRoute exact path="/invitations-gallery" component={InvitationsGallery}/>
      <PrivateRoute exact path="/my-invitations" component={Invitations} />
      <PrivateRoute exact path="/create-invitation" component={CreateInvitation}/>
      <PrivateRoute exact path="/create-invitation/:id" component={CreateInvitation}/>
      <PrivateRoute exact path="/invitation/:id" component={Invitation} />
      <PrivateRoute exact path="/invitation/:id/apply" component={ApplyInvitation}/>
      <PrivateRoute exact path="/invitation/:id/edit" component={EditInvitation}/>
      <PrivateRoute exact path="/my-applications" component={Applications} />
      <PrivateRoute exact path="/my-applications/:id/edit" component={EditApplication}/>
      <PrivateRoute exact path="/applications/:id" component={InvitationApplications}/>
      <PrivateRoute exact path="/inbox" component={Inbox} />
      <Route path={'*'} component={PageNotFound}/>
    </Switch>
  );
}

export default App;
