import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const Input = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: 55,
    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: "#C19A5B",
      borderWidth: 1,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: "#F44336",
      borderWidth: 1
    },
  },
  '& .MuiInputBase-adornedStart': {
    '& input': {
      paddingLeft: 0,
    }
  },
  '& .MuiInputBase-multiline': {
    padding: "15px 20px",
    height: 'auto',
    minHeight: 55,
  },
  '&.MuiOutlinedInput-root': {
    background: theme.palette.background.paper,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: "#C19A5B",
    borderWidth: 1
  },
  "& .MuiFormHelperText-root": {
    fontSize: "15px",
    color: "#F44336",
    marginLeft: 0,
  },
  "& input": {
    padding: "15px 20px",
    "&::placeholder": {
      color: "#9b9b9b",
      fontSize: "14px",
      opacity: 1,
    },
    "& #date-time-edit span": {
      color: "#8F8F8F",
      fontSize: "16px",
      opacity: 1,
    },
  },
  "& textarea": {
    padding: 0,
    "&::placeholder": {
      color: "#9b9b9b",
      fontSize: "14px",
      opacity: 1,
    },
  },
}));

export default Input;
