import React from 'react';
import { useTravelCategoriesQuery } from '../../../services/mainApi';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import TravelPackage from '../../../components/TravelPackage';
import { setActiveTravelPackage, updateDraft } from '../slice';

const TravelPackages = ({travelPackage}) => {
  const {data, isLoading} = useTravelCategoriesQuery({params: {active: true}});
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();

  const onChange = (e, value) => {
    dispatch(updateDraft({key: 'travelPackage', value: value?.id}));
    dispatch(setActiveTravelPackage(value))
  };
  return !isLoading && (
    <ToggleButtonGroup
      value={travelPackage}
      onChange={onChange}
      sx={{width: "100%", display: "flex", flexDirection: "column"}}
      exclusive
    >
      {data?.data.map(item => (
        <ToggleButton
          value={item}
          key={item.id}
          sx={{
            p: 0,
            width: "100%",
            marginBottom: "30px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            backgroundColor: "transparent",
            border: 'none',
            '&:hover': {
              backgroundColor: "transparent",
            }
          }}
        >
          <TravelPackage
            title={item?.title}
            color={item?.color}
            hintItems={item?.hintItems}
            checkboxItems={item?.checkboxItems}
            selected={item?.id === newInvitation?.draft?.travelPackage}
            selectable
          />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default TravelPackages;
