import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  DELETE_NOTIFICATION,
  MARK_NOTIFICATIONS_READ,
  SET_CONVERSATIONS,
  LOADING_CONVERSATIONS,
  SET_FORGOT_PASSWORD,
  STOP_LOADING_UI,
  SET_REFERRALS,
  LOADING_REFERRALS,
  DELETE_CONVERSATION,
  SET_VALIDATE_TOKEN,
  SET_UNVALIDATE_TOKEN,
  SET_RESET_PASSWORD,
} from "../types";
import axios from "axios";
import { mainApi } from '../../services/mainApi';

const setAuthorizationHeader = (jwtToken) => {
  const IdToken = `Bearer ${jwtToken}`;
  const tokenData = { Token: IdToken, Exp: Date.now() * 1000 };
  localStorage.setItem("IdToken", JSON.stringify(tokenData));
  // localStorage.setItem("IdToken", `Bearer ${jwtToken}`);
  axios.defaults.headers.common["Authorization"] = IdToken;
};

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/auth/login", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.jwtToken);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      window.location.href = "/invitations-gallery";
      // history.push("/home");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const forgotPassword = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/auth/forgot-password/", userData)
    .then(() => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_FORGOT_PASSWORD });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const validateToken = (token) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/auth/validate-reset-token/", token)
    .then((res) => {
      dispatch({ type: STOP_LOADING_UI });
      if (res.status === 200) {
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: SET_VALIDATE_TOKEN });
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: res.data.message,
        });
        dispatch({ type: SET_UNVALIDATE_TOKEN });
      }
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
      dispatch({ type: SET_UNVALIDATE_TOKEN });
      window.location.href = "/";
    });
};
export const resetPassword = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/auth/reset-password/", userData)
    .then((res) => {
      dispatch({ type: STOP_LOADING_UI });
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_RESET_PASSWORD });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const signupUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/auth/register", newUserData)
    .then((res) => {
      if (res.data.success === true) {
        let loginDetails = {
          username: newUserData["username"],
          password: newUserData["password"],
        };
        // dispatch(loginUser());
        dispatch({ type: CLEAR_ERRORS });

        axios
          .post("/auth/login", loginDetails)
          .then((res) => {
            setAuthorizationHeader(res.data.jwtToken);
            dispatch(getUserData());
            dispatch({ type: CLEAR_ERRORS });
            window.location.href = "/onboarding";
          })
          .catch((err) => {
            dispatch({
              type: SET_ERRORS,
              payload: err.response.data,
            });
          });
      }
      // history.push("/invitations-gallery");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  return axios
    .get("/auth/me")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
      dispatch(getNotifications());
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editUserDetails = (userDetails, userID) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .patch(`/users/${userID}`, userDetails)
    .then((res) => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err.response.data));
};

export const editUserImage = (userImage, userID) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .patch(`/users/image/${userID}`, userImage)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: {imgError: err.response.data.error},
      })
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("IdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
  window.location.href = "/";
};

export const getNotifications = () => (dispatch) => {
  dispatch(mainApi.endpoints.notifications.initiate(null, {subscriptionOptions: { pollingInterval: 15000 }}));
};
export const getReferrals = () => (dispatch) => {
  dispatch({ type: LOADING_REFERRALS });
  axios
    .get("/credits-by-ref-code")
    .then((res) => {
      dispatch({
        type: SET_REFERRALS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
export const getConversations = () => (dispatch) => {
  dispatch({ type: LOADING_CONVERSATIONS });
  axios
    .get("/conversations")
    .then((res) => {
      if (res.data.count !== 0) {
        dispatch({
          type: SET_CONVERSATIONS,
          payload: res.data.data,
        });
      } else {
        console.log("No Conversations");
      }
    })
    .catch((err) => console.log(err));
};
export const deleteConversation = (conversationID) => (dispatch) => {
  axios
    .delete(`/conversations/${conversationID}`)
    .then((res) => {
      dispatch({ type: DELETE_CONVERSATION, payload: conversationID });
    })
    .catch((err) => console.log(err));
};
export const markNotificationsRead = (notificationIds) => (dispatch) => {
  axios
    .patch("/notifications/update-status", notificationIds)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: MARK_NOTIFICATIONS_READ,
        });
        // dispatch({ type: CLEAR_NOTIFICATIONS });
        // dispatch(getNotifications());
      }
    })
    .catch((err) => console.log(err.response.data));
};

export const deleteNotification = (notificationId) => (dispatch) => {
  axios
    .delete(`/notifications/${notificationId}`)
    .then((res) => {
      dispatch({ type: DELETE_NOTIFICATION, payload: notificationId });
    })
    .catch((err) => console.log(err));
};

export const closeAccount = (userID) => (dispatch) => {
  axios
    .delete(`/users/${userID}`)
    .then(() => {
      localStorage.removeItem("IdToken");
      delete axios.defaults.headers.common["Authorization"];
      window.location.href = "/";
      dispatch({ type: SET_UNAUTHENTICATED });
    })
    .catch((err) => console.log(err));
};
