import React from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setError, updateDraft } from '../../slice';
import FormWrapper from '../FormWrapper';
import DatePicker from '../../../../components/UI/DatePicker';
import AccommodationTypeSelect from '../../../../components/AccommodationTypeSelect';
import GoogleAutocomplete from '../../../../components/GoogleAutocomplete';
import TravelPackages from '../TravelPackages';
import Label from '../../../../components/UI/Label';
import FormButtons from '../FormButtons';
import { MenuItem, Typography } from '@mui/material';
import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';

const GuestStepOne = () => {
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const isDisabled = !newInvitation?.draft?.locationFrom || !newInvitation?.draft?.startDate ||
    !newInvitation?.draft?.endDate || !newInvitation?.draft?.typeOfAccommodation || !newInvitation?.draft?.travelPackage ||
    !newInvitation?.draft?.numberOfCoHosts || (newInvitation?.draft?.numberOfCoHosts !== '0' && !newInvitation?.draft?.genderOfCoHosts) ||
    !newInvitation?.draft?.additionalInfo;

  const handleDateChange = (e) => (key) => {
    dispatch(updateDraft({key, value: dayjs(e?.$d || null)?.format()}));
  };

  const handleChange = ({target: {name, value, type}}) => {
    if (type === 'number') {
      dispatch(updateDraft({key: name, value: value < 0 ? Math.abs(value) : value}));
      return;
    }
    dispatch(updateDraft({key: name, value}));
  };

  const handleLocation = (key) => (value) => {
    dispatch(setError({key, value: null}))
    dispatch(updateDraft({key, value}));
    if (key === 'locationFrom' && !newInvitation?.draft?.locationTo) {
      dispatch(updateDraft({key: 'locationTo', value}));
    }
    if (!value?.city || !value?.country) {
      dispatch(setError({key, value: 'Please provide city and country'}))
    }
  };

  return (
    <>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation for Guests</Typography>
        <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>TRIP INFORMATION</Typography>
        <Label>Starting Location</Label>
        <GoogleAutocomplete
          value={newInvitation?.draft?.locationFrom || null}
          handleChange={handleLocation('locationFrom')}
          error={newInvitation?.errors?.locationFrom}
        />
        <Label>Ending Location</Label>
        <GoogleAutocomplete
          value={newInvitation?.draft?.locationTo || null}
          handleChange={handleLocation('locationTo')}
          error={newInvitation?.errors?.locationTo}
        />
        <Label>From</Label>
        <DatePicker
          name={'startDate'}
          value={newInvitation?.draft?.startDate || null}
          onChange={(e) => handleDateChange(e)('startDate')}
          minDate={dayjs().add(1, "day")}
          maxDate={
            newInvitation?.draft?.endDate
              ? dayjs(newInvitation?.draft?.endDate).add(-1, "day")
              : dayjs().add(1, "year")
          }
          sx={{mb: 4}}
        />
        <Label>To</Label>
        <DatePicker
          name={'endDate'}
          value={newInvitation?.draft?.endDate || null}
          onChange={(e) => handleDateChange(e)('endDate')}
          minDate={
            newInvitation?.draft?.startDate ? dayjs(newInvitation?.draft?.startDate).add(1, "day") : dayjs().add(2, "day")
          }
          sx={{mb: 4}}
        />
        <Label>Accommodation</Label>
        <AccommodationTypeSelect
          name="typeOfAccommodation"
          value={newInvitation?.draft?.typeOfAccommodation || ''}
          onChange={handleChange}
          sx={{mb: 4}}
        />
        <Label>Trip information</Label>
        <Input
          name="additionalInfo"
          placeholder="Fill in"
          multiline
          maxRows={5}
          value={newInvitation?.draft?.additionalInfo}
          onChange={handleChange}
          sx={{mb: 4}}
        />
        <Label>Associated Event •<em>Optional</em></Label>
        <Input
          name="associatedEvent"
          placeholder="Fill in"
          value={newInvitation?.draft?.associatedEvent || ''}
          onChange={handleChange}
          sx={{mb: 4}}
        />
        <Label>Expected Number of CoHosts</Label>
        <Input
          sx={{mb: 4}}
          name="numberOfCoHosts"
          type="number"
          value={newInvitation?.draft?.numberOfCoHosts || ''}
          onChange={handleChange}
          inputProps={{inputMode: 'numeric', min: 0}}
          fullWidth
        />
        <Label>Gender of CoHosts</Label>
        <Select
          name="genderOfCoHosts"
          value={newInvitation?.draft?.genderOfCoHosts || ''}
          onChange={handleChange}
          sx={{mb: 4}}
          disabled={newInvitation?.draft?.numberOfCoHosts === '0'}
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"both"} disabled={newInvitation?.draft?.numberOfCoHosts === '1'}>Both</MenuItem>
        </Select>
        <Label id="TravelPackageToggle">Travel Category</Label>
        <TravelPackages value={newInvitation?.draft?.travelPackage || null} />
      </FormWrapper>
      <FormButtons showPrev showNext isNextDisabled={isDisabled} />
    </>
  );
}

export default GuestStepOne;
