import PageWrapper from '../../components/PageWrapper';
import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useUserQuery } from './api';
import dayjs from 'dayjs';
import ActivityWrapper from '../../components/ActivityWrapper';
import ActivityItem from '../../components/ActivityItem';

const UserProfile = () => {
  const history = useHistory();
  const {id} = useParams();
  const {data: user, isLoading, isError} = useUserQuery(id, {skip: !id});

  return (
    <PageWrapper>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} sx={{mb: 3}}>
        <IconButton onClick={() => history.goBack()} sx={{'& > svg': {color: '#ccc', fontSize: 32}}}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {user && !isLoading && (
        <Stack direction={'column'} alignItems={'center'} sx={{ mx: 'auto', maxWidth: 500}}>
          <Typography sx={{fontSize: "16px", color: "#373632", fontWeight: "600", mb: 1.2}}>{user?.username}</Typography>
          {user?.shortBio && ( <Typography
            sx={{
              maxWidth: 250,
              width: '100%',
              fontWeight: 'normal',
              fontSize: 16,
              textAlign: 'center',
              lineHeight: '24px',
              mb: 3,
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden'
            }}
          >
            {user?.shortBio}
          </Typography> )}
          <Grid container spacing={1} sx={{mt: 2}}>
            {Boolean(user?.gender) && (
              <>
                <Grid item xs={6} sx={{}}>
                  <Typography sx={{fontWeight: 500}}>Gender</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.gender}</Typography>
                </Grid>
              </>
            )}
            {Boolean(user?.nationality)&& (
              <>
                <Grid item xs={6} sx={{}}>
                  <Typography sx={{fontWeight: 500}}>Nationality</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.nationality}</Typography>
                </Grid>
              </>
            )}
            {Boolean(user?.birthDate)&& (
              <>
                <Grid item xs={6} sx={{}}>
                  <Typography sx={{fontWeight: 500}}>Date of birth</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Typography sx={{fontWeight: 300}}>{dayjs(user?.birthDate).format("DD/MM/YYYY")}</Typography>
                </Grid>
              </>
            )}
            {Boolean(user?.languages) && Boolean(user?.languages?.length) && (
              <>
                <Grid item xs={6} sx={{}}>
                  <Typography sx={{fontWeight: 500}}>Languages</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#373632',
                          color: '#C19A5B',
                          padding: 1,
                          fontSize: 14,
                          fontWeight: 300,
                          '& .MuiTooltip-arrow': {
                            color: '#373632',
                          },
                        },
                      },
                    }}
                    title={user?.languages?.join(', ')} placement="top" arrow sx={{fontSize: 100}}>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        fontWeight: 300
                      }}
                    >
                      {user?.languages?.join(', ')}
                    </Typography>
                  </Tooltip>
                </Grid>
              </>
            )}
            {Boolean(user?.occupationSector)&& (
              <>
                <Grid item xs={6} sx={{}}>
                  <Typography sx={{fontWeight: 500}}>Industry</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.occupationSector}</Typography>
                </Grid>
              </>
            )}
            {Boolean(user?.interests) && Boolean(user?.interests?.length) && (
              <>
                <Grid item xs={6} sx={{}}>
                  <Typography sx={{fontWeight: 500}}>Interests</Typography>
                </Grid>
                <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <ActivityWrapper>
                    {user?.interests?.map(interest => <ActivityItem value={interest} key={interest} />)}
                  </ActivityWrapper>
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      )}
      {isError && !isLoading && (
        <Typography>User was not found</Typography>
      )}
    </PageWrapper>
  );
};

export default UserProfile;
