import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import PageWrapper from '../../../components/PageWrapper';
import SelectInvitationType from './SelectInvitationType';
import GuestInvitationForm from './TripGuestInvitationForm';
import CoHostInvitationForm from './TripCoHostInvitationForm';
import { clearSlice, setDraft } from '../slice';
import { useDraftQuery } from '../api';
import { useHistory, useParams } from 'react-router-dom';
import EventGuestInvitationForm from './EventGuestInvitationForm';
import EventCoHostInvitationForm from './EventCoHostInvitationForm';

const CreateInvitation = () => {
  const {newInvitation, user} = useSelector(store => store);
  const dispatch = useDispatch();
  const {id} = useParams();
  const {data} = useDraftQuery(id, {skip: !id});
  const history = useHistory();
  const notFilledProfileInfo = Object.keys(user?.user)?.length > 0 && (user?.user?.gender === "" || user?.user?.occupationSector === "" ||
    user?.user?.shortBio === "" || user?.user?.nationality === "" || user?.user?.birthDate === "" ||
    !user?.user?.languages?.length || !user?.user?.interests?.length);

  useEffect(() => () => dispatch(clearSlice()), []); //eslint-disable-line

  useEffect(() => {
    if (notFilledProfileInfo) {
      history.push({pathname: '/complete-your-profile', state: {path: history.location.pathname}});
    }
  }, []);//eslint-disable-line

  useEffect(() => {
    if (data) {
      dispatch(setDraft(data));
    } else {
      dispatch(clearSlice());
    }
  }, [dispatch, data])

  return (
    <PageWrapper sx={{pb: {xs: '20px', md: '140px'}}}>
      <Stack direction={'column'} sx={{flexGrow: 1}}>
        {newInvitation?.step === 0 && <SelectInvitationType />}
        {newInvitation?.step > 0 && newInvitation.draft.type === 'guest' && newInvitation.draft.experienceType === 'trip' && <GuestInvitationForm />}
        {newInvitation?.step > 0 && newInvitation.draft.type === 'coHost' && newInvitation.draft.experienceType === 'trip' &&  <CoHostInvitationForm />}
        {newInvitation?.step > 0 && newInvitation.draft.type === 'guest' && newInvitation.draft.experienceType === 'event' && <EventGuestInvitationForm />}
        {newInvitation?.step > 0 && newInvitation.draft.type === 'coHost' && newInvitation.draft.experienceType === 'event' && <EventCoHostInvitationForm />}
      </Stack>
    </PageWrapper>
  )
};

export default CreateInvitation;
