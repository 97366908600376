import React from 'react';
import ModalTitle from '../../../components/UI/DialogTitle';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Button } from '../../../components/UI/Button';

const SaveDraftModal = ({open, onConfirm, onCancel, onClose}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 500,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>Do you want to save as draft?</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          Yes
        </Button>
        <Button
          fullWidth
          sx={{mt: 2}}
          variant="outlined"
          color="error"
          onClick={onCancel}
        >
          No
        </Button>
      </DialogContent>
    </Dialog>
  );
};
export default SaveDraftModal;
