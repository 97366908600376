import PageWrapper from '../../components/PageWrapper';
import { useApplicationQuery, useApplicationsQuery } from './api';
import React, { useEffect, useState } from 'react';
import ToggleFullscreenButton from '../../components/ToggleFullscreenButton';
import { Box, Dialog, DialogContent, Stack, Typography, useMediaQuery } from '@mui/material';
import ApplicationFilters from './ApplicationFilters';
import dayjs from 'dayjs';
import Application from './application';
import PreviewInvitationModal from '../invitations/PreviewInvitationModal';
import { useInvitationQuery } from '../invitations/api';
import CreateIcon from '@mui/icons-material/Create';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../components/UI/Button';
import ScrollableSidebar from '../../components/ScrollableSidebar';
import SidebarItem from '../../components/SidebarItem';
import { theme } from '../../services/Theme';
import ModalTitle from '../../components/UI/DialogTitle';

const Applications = () => {
  const [params, setParams] = useState({});
  const [selectedApp, setSelectedApp] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [openInvitation, setOpenInvitation] = useState(false);
  const [openApplicationModal, setOpenApplicationModal] = useState(false);
  const {data: applications, isLoadingApplications} = useApplicationsQuery({params});
  const {data: application} = useApplicationQuery(selectedApp?.id, {skip: !applications || !selectedApp?.id});
  const {data: invitation} = useInvitationQuery(
    application?.invitation?.id,
    {skip: !application || !openInvitation}
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  useEffect(() => {
    if (applications && !selectedApp) {
      setSelectedApp(applications[0]);
    }
  }, [applications]); //eslint-disable-line

  const onFilter = (name, value) => {
    setSelectedApp(null);
    if (!value.length) {
      const {[name]: filter, ...restFilters} = params;
      setParams(restFilters);
      return;
    }
    setParams(state => ({...state, [name]: value}));
  }

  const onApplicationClick = (application) => () => {
    if (isMobile) {
      setOpenApplicationModal(true);
    }
    setSelectedApp(application);
  }

  return (
    <PageWrapper sx={{ overflowY: 'hidden', height: 'calc(100vh - 45px)'}}>
      <ToggleFullscreenButton isFullScreen={fullScreen} onChange={() => setFullScreen(state => !state)} />
      <Stack direction={'row'} sx={{height: '100%'}}>
        <Box sx={{
          width: {xs: '100%', md: '40%'},
          height: '100%',
          display: fullScreen ? 'none' : 'flex', flexDirection: 'column',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            top: -30,
            right: 0,
            width: '1px',
            height: 'calc(100% + 60px)',
            backgroundColor: '#C19A5B',
            display: {xs: 'none', md: 'block'},
          }
        }}
        >
          <Typography sx={{fontWeight: 500, mb: 3,}}>
            My Applications
          </Typography>
          <ApplicationFilters
            sx={{pr: 3.5, mb: 3}}
            itemsCount={applications?.length}
            handleClear={() => setParams({})}
            handleFilter={onFilter}
          />
          <ScrollableSidebar>
            {applications?.map((item) => (
              <SidebarItem
                key={item?.id}
                onClick={onApplicationClick(item)}
                selected={selectedApp?.id === item?.id}
                image={item?.invitation?.imgUrl}
              >
                <Stack direction={'column'} spacing={0.5}>
                  <Typography sx={{fontSize: 15, fontWeight: 600}}>
                    {item?.invitation?.name} {item?.invitation?.uid && `#${item?.invitation?.uid}`}
                  </Typography>
                  <Typography sx={{fontSize: 14, fontWeight: 500}}>
                    {dayjs(item?.invitation?.startDate).format("DD MMM YYYY")} - {dayjs(item?.invitation?.endDate).format("DD MMM YYYY")}
                  </Typography>
                  <Typography sx={{fontSize: 11, fontWeight: 600, textTransform: 'uppercase'}}>{item?.status}</Typography>
                </Stack>
              </SidebarItem>
            ))}
            {applications?.length === 0 && !isLoadingApplications && (
              <>
                <Typography align={'center'}>You have no applications</Typography>
                <Button
                  variant={'contained'}
                  sx={{maxWidth: '260', mx: 'auto', mt: 2, display: 'block'}}
                  onClick={() => history.push({pathname: '/profile-referrals', state: {isOpenRefModal: true}})}
                >
                  invite/refer friends
                </Button>
              </>
            )}
          </ScrollableSidebar>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', md: 'block'},
            width: fullScreen ? '100%' : '60%',
            position: 'relative',
            height: '100%',
            pl: fullScreen ? 0 : 3,
            overflowY:'auto'
          }}
        >
          <ApplicationDetails
            twoColumns={fullScreen}
            application={application}
            selectedApplication={selectedApp}
            onOpenInvitation={() => setOpenInvitation(true)}
          />
        </Box>
      </Stack>
      <PreviewInvitationModal
        showTitle={false}
        open={openInvitation}
        onClose={() => setOpenInvitation(false)}
        invitation={invitation}
      />
      <Dialog
        fullScreen
        open={openApplicationModal && isMobile}
        onClose={() => setOpenApplicationModal(false)}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 1300,
            width: '100%',
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "#b0b0b04d",
          },
        }}
      >
        <ModalTitle onClose={() => setOpenApplicationModal(false)} />
        <DialogContent sx={{p: 3, mt: 3}}>
          <ApplicationDetails
            application={application}
            selectedApplication={selectedApp}
            onOpenInvitation={() => setOpenInvitation(true)}
          />
        </DialogContent>
      </Dialog>
    </PageWrapper>
  );
};

export default Applications;

const ApplicationDetails = ({selectedApplication, application, onOpenInvitation, twoColumns}) => {
  return (
    selectedApplication ? (
        <>
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
            <Box>
              <Typography sx={{fontSize: 18, fontWeight: 600, mb: 1}}>
                {selectedApplication?.invitation?.name}  {selectedApplication?.invitation?.uid && `#${selectedApplication?.invitation?.uid}`}
              </Typography>
              <Typography sx={{fontSize: 16, mb: 1}}>
                {dayjs(selectedApplication?.invitation?.startDate).format("DD MMM YYYY")} - {dayjs(selectedApplication?.invitation?.endDate).format("DD MMM YYYY")}
              </Typography>
              <Typography sx={{fontSize: 13, textTransform: 'uppercase', mb: 1, display: 'flex', alignItems: 'center'}}>
                status: <Typography component={'span'} sx={{fontWeight: 500}}>{selectedApplication?.status}</Typography>
              </Typography>
              <Typography
                onClick={onOpenInvitation}
                sx={{color: '#c19a5b', fontSize: 14, mb: 2, textDecoration: 'underline', cursor: 'pointer'}}
              >
                View Invitation
              </Typography>
              <Button
                sx={{height: 'auto', py: 0.5, mb: 3, }}
                variant="contained"
                startIcon={<CreateIcon />}
                component={Link}
                to={`/my-applications/${application?.id}/edit`}
                disabled={application?.status === 'cancelled'}
              >
                EDIT APPLICATION
              </Button>
            </Box>
            {selectedApplication?.invitation?.imgUrl && (
              <Box sx={{width: '100%', maxWidth: 250, '& img': {width: '100%', height: 'auto'}}}>
                <img src={selectedApplication?.invitation?.imgUrl} alt="invitation"/>
              </Box>
            )}
          </Stack>
          <Application application={{...application, ...application?.user}} isTwoColumnsLayout={twoColumns} />
        </>
    ) :
      <Typography>Please select the application</Typography>
  );
};
