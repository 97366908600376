import React, { useState } from 'react';
import { Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FiltersInfo from './FiltersInfo';

const type = ["coHost", "guest"];
const experienceType = ["trip", "event"];
const status = [
  {name: 'active', value: 'active'},
  {name: 'completed', value: 'completed'},
  {name: 'draft', value: 'draft'}
];

const InvitationsFilters = ({handleClear, handleFilter, itemsCount, sx}) => {
  const [typeValue, setTypeValue] = useState([]);
  const [statusValue, setStatusValue] = useState([]);
  const [experienceTypeValue, setExperienceTypeValue] = useState([]);
  const filtersCount = (typeValue?.length || 0) + (statusValue?.length || 0);

  const handleFiltersChange = ({target: {name, value}}) => {
    if (name === 'type') {
      setTypeValue(value);
    }
    if (name === 'status') {
      setStatusValue(value)
    }
    if (name === 'experienceType') {
      setExperienceTypeValue(value);
    }
    handleFilter(name, value);
  };

  const onClearFilters = () => {
    setStatusValue([]);
    setTypeValue([]);
    setExperienceTypeValue([]);
    handleClear();
  }

  return (
    <Stack
      direction={'column'}
      justifyContent={'space-between'}
      sx={{width: '100%', ...sx}}
      spacing={2}
    >
      <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
        <FormControl>
          <Select
            sx={getStyles(experienceTypeValue.length > 0)}
            name={'experienceType'}
            multiple
            autoWidth
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            value={experienceTypeValue}
            MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
            onChange={handleFiltersChange}
            input={<OutlinedInput />}
            renderValue={() => {
              return <span>EXPERIENCE TYPE</span>;
            }}
          >
            {experienceType.map((type) => (
              <MenuItem sx={{
                minWidth: "150px",
                padding: "5px 10px !important",
                "& span": {
                  fontSize: "12px",
                  fontWeight: 600,
                },
                "& .MuiCheckbox-root": {
                  padding: "0px !important",
                  marginRight: "8px",
                },
              }} key={type} value={type}>
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
                  disableRipple
                  color="default"
                  checked={experienceTypeValue.indexOf(type) > -1}
                />
                <ListItemText primary={type} sx={{textTransform: "uppercase"}} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            sx={getStyles(typeValue.length > 0)}
            name={'type'}
            multiple
            autoWidth
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            value={typeValue}
            MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
            onChange={handleFiltersChange}
            input={<OutlinedInput />}
            renderValue={() => {
              return <span>TYPE</span>;
            }}
          >
            {type.map((type) => (
              <MenuItem sx={{
                minWidth: "150px",
                padding: "5px 10px !important",
                "& span": {
                  fontSize: "12px",
                  fontWeight: 600,
                },
                "& .MuiCheckbox-root": {
                  padding: "0px !important",
                  marginRight: "8px",
                },
              }} key={type} value={type}>
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
                  disableRipple
                  color="default"
                  checked={typeValue.indexOf(type) > -1}
                />
                <ListItemText primary={type} sx={{textTransform: "uppercase"}} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            name={'status'}
            sx={getStyles(statusValue.length > 0)}
            multiple
            autoWidth
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            value={statusValue}
            onChange={handleFiltersChange}
            MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
            input={<OutlinedInput />}
            renderValue={() => {
              return <span>STATUS</span>;
            }}
          >
            {status.map((status) => (
              <MenuItem
                sx={{
                  minWidth: "150px",
                  padding: "5px 10px !important",
                  "& span": {
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                  "& .MuiCheckbox-root": {
                    padding: "0px !important",
                    marginRight: "8px",
                  },
                }}
                value={status.value}
                key={status.value} >
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
                  disableRipple
                  color="default"
                  checked={statusValue.indexOf(status.value) > -1}
                />
                <ListItemText
                  sx={{textTransform: "uppercase"}}
                  primary={status.name}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {(typeValue.length > 0 || statusValue.length > 0) && (
        <FiltersInfo filtersCount={filtersCount} onClear={onClearFilters} />
      )}
    </Stack>
  );
};

const getStyles = (active) => ({
  "& fieldset": {
    borderColor: "#fff0",
  },
  "& .MuiSelect-select": {
    minWidth: "150px",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "15px",
    border: "1px solid #C19A5B !important",
    boxShadow: "unset !important",
    boxSizing: "border-box",
    background: active ? '#C19A5B' : '#fff',
  },
  "& svg": {
    color: active ? '#fff' : "#373632",
    right: "5px !important",
  },
  "& .MuiSelect-select[aria-expanded='false']": {
    color: active ? '#fff' : "#373632",
    width: "auto",
    padding: "9px 29px 8px 10px !important",
  },
  "& .MuiSelect-select[aria-expanded='true']": {
    color: active ? '#fff' : "#373632",
    border: "none",
    width: "auto",
    padding: "9px 29px 8px 10px !important",
  },
  "& .MuiSelect-select[aria-expanded='false']:hover ~ fieldset": {
    borderColor: "#C19A5B",
  },
  "& .MuiSelect-select[aria-expanded='true'] ~ fieldset": {
    borderColor: "#C19A5B",
  },

  "& .MuiSelect-select:focus ~ fieldset": {
    borderColor: "#F9F5EF",
    zIndex: "-1",
  },
});

export default InvitationsFilters;
