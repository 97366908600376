import React, { useState } from 'react';
import {
  Container,
  Stack,
  Link,
  Box,
  MenuItem,
  Typography,
  useMediaQuery,
  IconButton,
  List,
  Portal,
  Dialog
} from '@mui/material';
import { Button } from './UI/Button';
import Dropdown from '../components/UI/Dropdown'
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import whiteLogo from "./../assets/inviteclub_public_white.svg";
import goldLogo from "./../assets/inviteclub_public_gold.svg";
import { theme } from '../services/Theme';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuMainInfo } from './Navbar';
import { FooterNav } from './Footer';
import LoginModal from './LoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { LogOutModal } from '../features/profile';
const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/' || location.pathname === '/login' || location.pathname === '/auth/reset-password';
  const isFoundingMemberPage = location.pathname === '/founding/members/info';
  const isSignUpPage = location.pathname === '/join';
  const {authenticated, user = {}} = useSelector(store => store.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const [openLogin, setOpenLogin] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openLogOutModal, setOpenLogOutModal] = useState(false);

  const handleMenu = () => {
    setIsMenuOpen(state => !state);
  };

  const handleOpenLogin = () => {
    setIsMenuOpen(false);
    setOpenLogin(true);
    setOpenForgotPassword(false);
  };
  const handleOpenRegister = () => {
    if (isFoundingMemberPage) {
      history.push({pathname: '/join', state: {isFoundingMember: true}});
    } else {
      history.push('/join');
    }
  };

  const handleOpenForgotPassword = () => {
    setIsMenuOpen(false);
    setOpenForgotPassword(true);
    setOpenLogin(false);
  };

  return (
    <Box sx={{width: '100%', py: {xs: 0, md: 4}, backgroundColor: {xs: '#373632', md: 'transparent'}, position: {xs: 'fixed', md: 'relative'}, top: 0, left: 0, zIndex: 1000}}>
      <Container sx={{maxWidth: 1320}} maxWidth={'xl'}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{width: '100%', height: {xs: 68, md: 'auto'}}}>
          {isMobile && (
            <IconButton edge="start" color="inherit" onClick={handleMenu} sx={{p: 0, m: 0}}>
              <MenuIcon
                sx={{
                  width: "40px",
                  height: "40px",
                  color: isMenuOpen ? "#C19A5B" : "#fff",
                }}
              />
            </IconButton>
          )}
          <Stack direction={'row'} alignItems={'center'}>
            <Link
              to={'/'}
              component={NavLink}
              sx={{
                maxWidth: {xs: 130, md: 185},
                width: '100%',
                '& > img': {
                  width: '100%',
                  height: 'auto'
                },
                position: {xs: 'absolute', md: 'static'},
                top: '50%',
                left: '50%',
                transform: {xs: 'translate(-50%, -50%)', md: 'translate(0, 0)'}
              }}
            >
              <img src={!isMenuOpen && isMobile ? whiteLogo : goldLogo} alt="invite-club-logo"/>
            </Link>
            {!isMobile && (
              <Box sx={{ml: 5}}>
                {authenticated && (
                  <Link
                    underline={'hover'}
                    component={NavLink}
                    sx={{color: "#C19A5B", fontSize: 15, fontWeight: 500, '&.active': {textDecoration: 'underline'},  fontFamily: 'GothamBook, sans-serif !important'}}
                    to={'/how-it-works'}
                  >
                    How It Works
                  </Link>
                )}
              </Box>
            )}
          </Stack>
          {!authenticated && !isHomePage && !isMobile && !isSignUpPage && (
            <Box>
              <Button variant={'contained'} color={'primary'} sx={{height: 32}} onClick={handleOpenRegister}>
               Apply for membership
              </Button>
              <Button variant={'outlined'} color={'primary'} sx={{height: 32, ml: 2}} onClick={() => setOpenLogin(true)}>
                LOGIN
              </Button>
            </Box>
          )}
          {authenticated && !isMobile && !isHomePage && (
            <Dropdown
              sx={{display: 'flex', alignItems: 'center'}}
              showArrow
              dropdownItems={
                <Box>
                  <MenuItem
                    sx={{fontSize: 12, fontWeight: 500}}
                    component={NavLink}
                    to={"/profile"}
                  >
                    PROFILE
                  </MenuItem>
                  <MenuItem
                    sx={{fontSize: 12, fontWeight: 500}}
                    to={"/profile"}
                    onClick={() => setOpenLogOutModal(true)}
                  >
                    LOGOUT
                  </MenuItem>
                </Box>
              }
            >
              <Box sx={{borderRadius: '50%', overflow: 'hidden', width: 38, height: 38, '& > img, & > svg': {width: '100%', height: '100%', objectFit: 'cover'}}}>
                {user?.imgUrl?.length > 0 ? (
                  <img src={user?.imgUrl[0]} alt="user-profile"/>
                ) :  <AccountCircle/>}
              </Box>
              <Typography sx={{fontSize: 13, fontWeight: 600, ml: 1}}>{user?.username}</Typography>
            </Dropdown>
          )}
        </Stack>
        {isMobile && (
          <Portal>
            <Box
              sx={{
                width: '100%',
                height: 'calc(100vh - 68px)',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 100000,
                transform: `${isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'}`,
                mt: '68px',
                backgroundColor: '#373632',
                transition: 'all 200ms linear 200ms'
              }}
            >
              {authenticated ? <MenuMainInfo /> : (
                <Box sx={{height: "100%", width: "100%", py: 4, px: {xs: 2, sm: 3}}}>
                  <List sx={{display: {xs: 'flex', md: 'none', flexDirection: 'column'}}}>
                    <Stack>
                      <FooterNav sx={{color: '#C19A5B', mt: 3}} />
                    </Stack>
                    <Button variant={'contained'} color={'primary'} sx={{height: 42, mt: 6}} onClick={handleOpenRegister}>
                     Apply for membership
                    </Button>
                    <Button variant={'outlined'} color={'primary'} sx={{height: 42, mt: 3}} onClick={handleOpenLogin}>
                      LOGIN
                    </Button>
                  </List>
                </Box>
              )}
            </Box>
          </Portal>
        )}
      </Container>
      <Dialog
        fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: {xs: '100%', sm: 600},
            width: '100%',
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "#b0b0b04d",
          },
        }}
        open={openLogin}
        onClose={() => setOpenLogin(false)}
      >
        <LoginModal
          handleRegisterOpen={handleOpenRegister}
          handleLoginClose={() => setOpenLogin(false)}
          handleForgotPasswordOpen={handleOpenForgotPassword}
        />
      </Dialog>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 600,
            width: '100%',
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "#b0b0b04d",
          },
        }}
        open={openForgotPassword}
        onClose={() => setOpenForgotPassword(false)}
      >
        <ForgotPasswordModal
          handleForgotPasswordClose={() => setOpenForgotPassword(false)}
        />
      </Dialog>
      <LogOutModal open={openLogOutModal} onClose={() => setOpenLogOutModal(false)} />
    </Box>
  );
};

export default Header;

