import { useSelector } from 'react-redux';
import GuestStepOne from './GuestStepOne';
import GuestStepTwo from './GuestStepTwo';
import ProgressBar from '../ProgressBar';

const GuestInvitationForm = () => {
  const {newInvitation: {step}} = useSelector(store => store);

  return (
    <>
      <ProgressBar />
      {step === 1 && <GuestStepOne />}
      {step === 2 && <GuestStepTwo />}
    </>
  );
};

export default GuestInvitationForm;
