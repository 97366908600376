import React, { Component } from "react";
import violation from "./../../assets/contact/report.svg";
import privacy from "./../../assets/contact/privacy.svg";
import more from "./../../assets/contact/more.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Link } from 'react-router-dom';
import StaticPageWrapper from '../../components/StaticPagesWrapper';

const styles = {
  contactWrapper: {
    minHeight: "calc(100vh - 250px)",
  },
  contactTitle: {
    fontSize: "24px",
    color: "#373632",
    lineHeight: "29px",
    fontWeight: "bold",
  },
  contactSubtitle: {
    width: "100%",
    display: "block",
    textAlign: "center",
    color: "#373632",
    fontSize: "22px",
    lineHeight: "35px",
    fontWeight: "500",
    margin: "120px 0px 43px 0px",
  },
  contactButtonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    "@media (max-width:500px)": {
      flexDirection: "column",
    },
  },
  contactButton: {
    width: "31%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F9F5EF",
    borderRadius: "2px",
    padding: "50px",
    boxSizing: "border-box",
    textDecoration: "none",
    "&:hover $contactButtonText": {
      color: "#C19A5B",
    },
    "@media (max-width:500px)": {
      width: "100%",
      marginBottom: "10px",
    },
  },
  contactButtonImg: {
    marginBottom: "20px",
  },
  contactButtonText: {
    color: "#373632",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "21px",
    textAlign: "center",
    transition: "all 0.3s ease-in-out",
  },
};
class Contact extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: true,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <StaticPageWrapper>
        <div className={"static-wrapper " + classes.contactWrapper}>
          <span className={classes.contactTitle}>Contact us</span>
          <span className={classes.contactSubtitle}>Select a topic</span>
          <div className={classes.contactButtonsWrapper}>
            <Link className={classes.contactButton} to="/contact/violation">
              <img className={classes.contactButtonImg} src={violation} alt={'violation'} />
              <span className={classes.contactButtonText}>
                Report a violation of Club terms
              </span>
            </Link>
            <Link className={classes.contactButton} to="/contact/privacy">
              <img className={classes.contactButtonImg} src={privacy} alt={'privacy'}/>
              <span className={classes.contactButtonText}>
                Privacy on Invite.Club
              </span>
            </Link>
            <Link className={classes.contactButton} to="/contact/other">
              <img className={classes.contactButtonImg} src={more} alt={'dots'} />
              <span className={classes.contactButtonText}>Other</span>
            </Link>
          </div>
        </div>
      </StaticPageWrapper>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps)(withStyles(styles)(Contact));
