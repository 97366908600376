import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitMessageModal from "../../components/StaticPages/Contact/SubmitMessageModal";
import { withStyles } from "@material-ui/styles";
import { InputLabel, TextField, Modal, Fade, Container } from '@mui/material';
import { CircularProgress } from "@material-ui/core";
import { Link } from 'react-router-dom';
import StaticPageWrapper from '../../components/StaticPagesWrapper';
import { Button } from '../../components/UI/Button';

const styles = {
  contactWrapper: {
    minHeight: "calc(100vh - 280px)",
  },
  breadcrumb: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "14px",
    lineHeight: "17px",
    "& a": {
      textDecoration: "none !important",
      color: 'inherit'
    },
  },
  contactInner: {
    maxWidth: "500px",
    width: "100%",
    margin: "auto",
  },
  title: {
    display: "block",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#373632",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  label: {
    color: "#373632 !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    "& em": {
      fontWeight: "400",
    },
  },
  textField: {
    borderRadius: "0px !important",
    marginBottom: "30px !important",
    "& .MuiOutlinedInput-root": {
      padding: "0px",
    },
    "& input": {
      padding: "15px 20px !important",
      borderRadius: "0px !important",
      "&::placeholder": {
        fontFamily: "Roboto !important",
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
    "& .MuiInputBase-root": {
      border: "1px solid #C19A5B",
      borderRadius: "0px !important",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Roboto !important",
      fontSize: "15px",
      color: "#F72525",
      letterSpacing: "0.23px",
    },
    "& .MuiInput-multiline": { padding: "0px !important" },
    "& #description": {
      padding: "15px 20px !important",
      lineHeight: "24px",
      "&::placeholder": {
        fontFamily: "Roboto !important",
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
    "& fieldset": {
      display: "none",
    },
  },
  contactSubtitle: {
    display: "block",
    color: "#373632 !important",
    fontSize: "16px !important",
    lineHeight: "20px",
    fontWeight: "600",
    marginBottom: "15px",
  },
  captcha: {
    margin: "auto",
    "& div": {
      margin: "auto",
    },
  },
  captchaError: {
    display: "block",
    fontFamily: "Roboto !important",
    fontSize: "15px",
    color: "#d32f2f",
    letterSpacing: "0.23px",
  },
  button: {
    marginTop: "30px !important",
    marginBottom: "20px !important",
    padding: "14px 0px !important",
    width: "100% !important",
    fontSize: "16px !important",
    lineHeight: "18px !important",
    backgroundColor: "#C19A5B !important",
    fontFamily: "'Montserrat', sans-serif !important ",
    boxShadow: "unset !important",
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
      backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    },
  },
  successMessage: {
    display: "block",
    color: "#373632 !important",
    fontSize: "16px !important",
    lineHeight: "20px",
    fontWeight: "600",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "0px",
    outline: "unset !important",
    "& .MuiBackdrop-root": {
      backgroundColor: "#b0b0b04d",
    },
  },
  submitMessageModal: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    outline: "unset",
  },
};
class ContactInner extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: true,
      type: null,
      description: "",
      username: "",
      fullName: "",
      email: "",
      captcha: false,
      success: "",
      title: "",
      errors: {},
      openSubmitMessageModal: false,
    };
  }

  componentDidMount() {
    const type = this.props.match.params.type;
    this.setState({ type: type });
  }
  handleChange = (event) => {
    switch (event.target.name) {
      case "description":
        this.setState({
          [event.target.name]: event.target.value,
        });
        if ("description" in this.state.errors) {
          delete this.state.errors["description"];
        }
        break;
      case "fullName":
        this.setState({
          [event.target.name]: event.target.value,
        });
        if ("fullName" in this.state.errors) {
          delete this.state.errors["fullName"];
        }
        break;
      case "username":
        this.setState({
          [event.target.name]: event.target.value,
        });
        if ("username" in this.state.errors) {
          delete this.state.errors["username"];
        }
        break;
      case "email":
        this.setState({
          [event.target.name]: event.target.value,
        });
        if ("email" in this.state.errors) {
          delete this.state.errors["email"];
        }
        break;
      default:
        this.setState({
          [event.target.name]: event.target.value,
        });
    }
  };
  onCaptchaChange = (value) => {
    if ("captcha" in this.state.errors) {
      delete this.state.errors["captcha"];
    }
    this.setState({ captcha: true });
  };
  onExpiredCaptcha = (value) => {
    this.setState({ captcha: false }, () => {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          captcha: "Captcha expired. Please click again!",
        },
      }));
    });
  };
  handleSubmit = (event) => {
    if (
      this.state.description === "" ||
      this.state.captcha === false ||
      this.state.fullName === "" ||
      this.state.email === "" ||
      !this.state.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      if (this.state.description === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            description: "This is a required field",
          },
        }));
      }
      if (this.state.fullName === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            fullName: "This is a required field",
          },
        }));
      }
      if (this.state.email === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            email: "This is a required field",
          },
        }));
      } else {
        if (
          !this.state.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.setState((prevState) => ({
            errors: {
              ...prevState.errors,
              email: "Please add a valid email address",
            },
          }));
        }
      }
      if (this.state.captcha === false) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            captcha: "Please fill the Captcha in order to send your message",
          },
        }));
      }
    } else {
      if (this.state.username !== "") {
        axios
          .post("/auth/check-value/username", {
            username: document.getElementsByName("username")[0].value,
          })
          .then((res) => {
            if (!res.data.result) {
              this.setState((prevState) => ({
                errors: {
                  ...prevState.errors,
                  username: "Username doesn't exist",
                },
              }));
            } else {
              let formData = {
                name: this.state.fullName,
                email: this.state.email,
                message: this.state.description,
                topic: this.state.type,
                privacyPolicyAccepted: true,
                username: this.state.username,
              };

              axios
                .post("/contacts", formData)
                .then((res) => {
                  if (res.status === 200) {
                    this.setState({
                      fullName: "",
                      email: "",
                      description: "",
                      username: "",
                      success:
                        "We have received your message.&lt;br/&lt;We will contact you soon.",
                      title: "Success!",
                      openSubmitMessageModal: true,
                    });
                  } else {
                    this.setState({
                      fullName: "",
                      email: "",
                      description: "",
                      username: "",
                      success: "Please try again later.",
                      title: "Something went wrong!",
                      openSubmitMessageModal: true,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({
                    fullName: "",
                    email: "",
                    description: "",
                    success: "Please try again later.",
                    title: "Something went wrong!",
                    openSubmitMessageModal: true,
                  });
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let formData = {
          name: this.state.fullName,
          email: this.state.email,
          message: this.state.description,
          topic: this.state.type,
          privacyPolicyAccepted: true,
        };
        axios
          .post("/contacts", formData)
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                fullName: "",
                email: "",
                description: "",
                success:
                  "We have received your message.<br/>We will contact you soon.",
                title: "Success!",
                openSubmitMessageModal: true,
              });
            } else {
              this.setState({
                fullName: "",
                email: "",
                description: "",
                success: "Please try again later.",
                title: "Something went wrong!",
                openSubmitMessageModal: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              fullName: "",
              email: "",
              description: "",
              success: "Please try again later.",
              title: "Something went wrong!",
              openSubmitMessageModal: true,
            });
          });
      }
    }
  };

  handleSubmitMessageClose = () => {
    this.setState({ openSubmitMessageModal: false });
  };
  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <StaticPageWrapper>
        <Container sx={{maxWidth: 1320}} maxWidth={'xl'}>
          <div className={classes.breadcrumb}>
            <Link to="/">Home</Link>&nbsp;{" > "}&nbsp;
            <Link to="/contact">Contact</Link>&nbsp;{" > "}&nbsp;
            {this.state.type === "violation" ? (
              <span>Report a violation of Club terms</span>
            ) : this.state.type === "privacy" ? (
              <span>Privacy on Invite.Club</span>
            ) : this.state.type === "other" && <span>Other</span>}
          </div>
        </Container>
        <div className={"static-wrapper " + classes.contactWrapper}>
          <div className={classes.contactInner}>
            <span className={classes.title}>Help Center</span>
            <InputLabel className={classes.label} htmlFor="description">
              Description
            </InputLabel>
            <TextField
              id="description"
              name="description"
              type="text"
              placeholder="Write something..."
              multiline
              minRows={5}
              className={classes.textField}
              value={this.state.description}
              onChange={this.handleChange}
              helperText={errors.description}
              error={!!errors.description}
              fullWidth
            />
            <span className={classes.contactSubtitle}>YOUR INFORMATION</span>
            <InputLabel className={classes.label} htmlFor="username">
              Username <em>• Optional</em>
            </InputLabel>
            <TextField
              id="username"
              name="username"
              type="text"
              placeholder="Enter your username"
              className={classes.textField}
              value={this.state.username}
              onChange={this.handleChange}
              helperText={errors.username}
              error={!!errors.username}
              fullWidth
            />
            <InputLabel className={classes.label} htmlFor="fullName">
              Full name
            </InputLabel>
            <TextField
              id="fullName"
              name="fullName"
              type="text"
              placeholder="Enter your full name"
              className={classes.textField}
              value={this.state.fullName}
              onChange={this.handleChange}
              helperText={errors.fullName}
              error={!!errors.fullName}
              fullWidth
            />
            <InputLabel className={classes.label} htmlFor="email">
              Email Address
            </InputLabel>
            <TextField
              id="email"
              name="email"
              type="text"
              placeholder="Enter your email address"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange}
              helperText={errors.email}
              error={!!errors.email}
              fullWidth
            />
            <ReCAPTCHA
              className={classes.captcha}
              sitekey="6Lflj3wgAAAAAAcCalIYZjskcaXLcpYvx5nXRlt0"
              onChange={this.onCaptchaChange}
              onExpired={this.onExpiredCaptcha}
            />
            {errors.captcha !== undefined && (
              <span className={classes.captchaError}>{errors.captcha}</span>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading || Object.keys(errors).length > 0}
              onClick={this.handleSubmit}
              fullWidth
              sx={{mt: 3}}
            >
              SEND
              {loading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
            </Button>
            <Modal
              className={classes.modal}
              open={this.state.openSubmitMessageModal}
              onClose={this.handleSubmitMessageClose}
              closeAfterTransition
            >
              <Fade in={this.state.openSubmitMessageModal}>
                <div className={classes.submitMessageModal}>
                  <SubmitMessageModal
                    handleSubmitMessageClose={this.handleSubmitMessageClose}
                    success={this.state.success}
                    title={this.state.title}
                  />
                </div>
              </Fade>
            </Modal>
          </div>
        </div>
      </StaticPageWrapper>
    );
  }
}

ContactInner.propTypes = {
  classes: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});
export default connect(mapStateToProps)(withStyles(styles)(ContactInner));
