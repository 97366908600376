import React, { useState } from 'react';
import FormWrapper from '../FormWrapper';
import { FormControlLabel, Icon, MenuItem, Typography } from '@mui/material';
import FormButtons from '../FormButtons';
import dayjs from 'dayjs';
import Label from '../../../../components/UI/Label';
import Input from '../../../../components/UI/Input';
import { useDispatch, useSelector } from 'react-redux';
import { updateDraft } from '../../slice';
import Select from '../../../../components/UI/Select';
import DatePicker from '../../../../components/UI/DatePicker';
import CustomTooltip from '../../../../components/UI/CustomTooltip';
import CustomSwitch from '../../../../components/UI/CustomSwitch';
import previewIconFile from '../../../../assets/create_inv/preview.svg';
import { Button } from '../../../../components/UI/Button';
import PreviewInvitationModal from '../../PreviewInvitationModal';

const GuestStepThree = () => {
  const [open, setOpen] = useState(false);
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const isDisabled = !newInvitation?.draft?.numberOfGuest || !newInvitation?.draft?.genderOfGuests ||
    !newInvitation?.draft?.bedroomType || !newInvitation?.draft?.deadlineOfApplications;
/*  const [addHostImages] = useAddHostImagesMutation();
  const [addCoHostImages] = useAddCoHostImagesMutation();
  const [deleteImage] = useDeleteImageMutation();*/

  const onChange = ({target: {name, value, type}}) => {
    if (type === 'number') {
      dispatch(updateDraft({key: name, value: Math.abs(+value || 1)}));
      return;
    }
    dispatch(updateDraft({key: name, value}));
  };

  const onDateChange = (e) => {
    dispatch(updateDraft({key: 'deadlineOfApplications', value: dayjs(e?.$d || null).format()}))
  };

  const handleSwitch = ({target: {name, checked}}) => {
    dispatch(updateDraft({key: 'requiredFields', value: {...newInvitation?.draft?.requiredFields, [name]: checked }}))
  }

  // const handleImage = (name) => (e) => {
  //   if (!e?.target?.files[0]) {
  //     return;
  //   }
  //   if (e?.target?.files[0].size > 4000000) {
  //     dispatch(setError({key: name, value: 'Image size should be less than 4mb.'}));
  //     return;
  //   }
  //   if (newInvitation?.draft?.id) {
  //     if (name === 'hostGallery') {
  //       addHostImages({id: newInvitation?.draft?.id, images: [e?.target?.files[0]]});
  //     }
  //     if (name === 'coHostsGallery') {
  //       addCoHostImages({id: newInvitation?.draft?.id, images: [e?.target?.files[0]]});
  //     }
  //     dispatch(setError({key: name, value: null}));
  //     e.target.value = '';
  //     return;
  //   }
  //   blobToDataURL(e?.target?.files[0], (dataUrl) => {
  //     dispatch(updateDraft({
  //       key: name,
  //       value: !newInvitation?.draft[name].includes(dataUrl) ? [...newInvitation?.draft[name], dataUrl] : newInvitation?.draft[name]
  //     }));
  //   });
  //   dispatch(setError({key: name, value: null}));
  //   e.target.value = '';
  // };

  // const handleDeleteImage = (name) => (item) => {
  //   if (item.startsWith('data:')) {
  //     dispatch(updateDraft({key: name, value: newInvitation?.draft[name].filter(img => img !== item)}));
  //   } else {
  //     deleteImage({id: newInvitation?.draft?.id, body: {filename: item}}).then(
  //       dispatch(updateDraft({key: name, value: newInvitation?.draft[name].filter(img => img !== item)}))
  //     );
  //   }
  // };

  return (
    <>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation for Guests</Typography>
        <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>GUEST INFORMATION</Typography>
        <Label>Number Of Guests Invited</Label>
        <Input
          name="numberOfGuest"
          placeholder="Choose number of guests"
          type="number"
          value={newInvitation?.draft?.numberOfGuest || ''}
          onChange={onChange}
          sx={{mb: 4}}
          inputProps={{inputMode: 'numeric', min: 1}}
          helperText={newInvitation?.errors?.numberOfGuest}
          error={!!newInvitation?.errors?.numberOfGuest}
          fullWidth
        />

        <Label>Gender Of Guests</Label>
        <Select
          name="genderOfGuests"
          value={newInvitation?.draft?.genderOfGuests || ''}
          onChange={onChange}
          displayEmpty
          fullWidth
          sx={{mb: 4}}
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"both"}>Both</MenuItem>
        </Select>
        {/*<Label>Pictures of Host •<em>Optional</em></Label>
        <ImageRow
          images={newInvitation?.draft?.hostGallery}
          error={newInvitation?.errors?.hostGallery}
          onChange={handleImage('hostGallery')}
          onDelete={handleDeleteImage('hostGallery')}
        />
        <Label>Pictures of CoHost •<em>Optional</em></Label>
        <ImageRow
          images={newInvitation?.draft?.coHostsGallery}
          error={newInvitation?.errors?.coHostsGallery}
          onChange={handleImage('coHostsGallery')}
          onDelete={handleDeleteImage('coHostsGallery')}
        />*/}
        <Label>
          Type of Bedroom
          <CustomTooltip tooltipText={'Double room is shared with a person of the same gender'} />
        </Label>
        <Select
          name="bedroomType"
          value={newInvitation?.draft?.bedroomType || ''}
          onChange={onChange}
          displayEmpty
          fullWidth
          sx={{mb: 4}}
        >
          <MenuItem value={"shared"}>Shared</MenuItem>
          <MenuItem value={"private"}>Private</MenuItem>
          <MenuItem value={"both"}>Both</MenuItem>
        </Select>
        <Label>Deadline For Application</Label>
        <DatePicker
          value={newInvitation?.draft?.deadlineOfApplications || null}
          maxDate={dayjs(newInvitation?.draft?.startDate).add(-1, "day")}
          minDate={dayjs().add(1, "day")}
          onChange={onDateChange}
        />
        <FormControlLabel
          sx={{my: 3}}
          control={
            <CustomSwitch
              name={'pictures'}
              onChange={handleSwitch}
              checked={newInvitation.draft.requiredFields.pictures}
            />
          }
          label={
            <Typography sx={{
              display: 'flex',
              color: '#373632',
              fontSize: 14,
              fontWeight: 400,
            }}>
              Guest must provide up to
              <Typography sx={{fontSize: 14, fontWeight: 700, ml: '3px'}} component={'span'}>
                5 Pictures of themselves
              </Typography>
            </Typography>
          }
        />
        <FormControlLabel
          sx={{mb: 3}}
          control={
            <CustomSwitch
              name={'video'}
              checked={newInvitation?.draft?.requiredFields?.video}
              onChange={handleSwitch}
            />
          }
          label={
            <Typography sx={{
              display: 'flex',
              color: '#373632',
              fontSize: 14,
              fontWeight: 400,
            }}>
              Guest must submit
              <Typography sx={{fontSize: 14, fontWeight: 700, ml: '3px'}} component={'span'}>
                a Short Video introducing themselves
              </Typography>
            </Typography>
          }
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          sx={{py: 1, mt: 3}}
          startIcon={
            <Icon sx={{width: 'auto', height: 'auto'}}>
              <img src={previewIconFile} alt={'preview'} />
            </Icon>
          }
        >
          PREVIEW INVITATION
        </Button>
      </FormWrapper>
      <PreviewInvitationModal
        open={open}
        onClose={() => setOpen(false)}
        invitation={{...newInvitation?.draft, travelPackage: newInvitation?.activeTravelPackage}}
      />
      <FormButtons showPrev showCreate isCreateDisabled={isDisabled} />
    </>
  )
}

export default GuestStepThree;
