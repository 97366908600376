import React from 'react';
import dayjs from 'dayjs';
import { Box, Grid, Icon, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HotelIcon from '@mui/icons-material/Hotel';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import genderIconFile from '../../../assets/gender.svg';
import accommodationIconFile from '../../../assets/accomodation.svg';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const InvitationInfo = ({invitation}) => {
  const {user} = useSelector(store => store.user);
  const location = useLocation();
  const isCreate = location.pathname.includes('/create-invitation');
  return (
    <Box sx={{
      backgroundColor: '#F9F5EF',
      padding: '25px',
      marginBottom: '30px',
      '@media (max-width:600px)': {
        marginBottom: '70px',
      },
      '@media (max-width:400px)': {
        marginBottom: '100px',
      },
    }}>
      <Typography sx={{color: '#373632', fontSize: 17, fontWeight: 600}}>
        Information
      </Typography>
      <Grid container sx={{mt: 3}} spacing={2}>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <LocationOnIcon sx={iconSx}/>
          <Typography sx={titleSx}>
            Start
          </Typography>
          <Typography sx={descriptionSx}>
            {invitation?.locationFrom?.city},{' '}{invitation?.locationFrom?.country}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <LocationOnIcon sx={iconSx}/>
          <Typography sx={titleSx}>
            End
          </Typography>
          <Typography sx={descriptionSx}>
            {invitation?.locationTo?.city},{' '}{invitation?.locationTo?.country}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <CalendarMonthIcon sx={iconSx}/>
          <Typography sx={titleSx}>
            From
          </Typography>
          <Typography sx={descriptionSx}>
            {dayjs(invitation?.startDate).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <CalendarMonthIcon sx={iconSx}/>
          <Typography sx={titleSx}>
            To
          </Typography>
          <Typography sx={descriptionSx}>
            {dayjs(invitation?.endDate).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <EventAvailableIcon sx={iconSx}/>
          <Typography sx={titleSx}>
            Deadline for Application
          </Typography>
          <Typography sx={descriptionSx}>
            {dayjs(invitation?.deadlineOfApplications).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Icon sx={{width: 20, '& > img': {width: '100%'}}}>
            <img src={genderIconFile} alt={'genderIcon'}/>
          </Icon>
          <Typography sx={titleSx}>
            Gender of Host
          </Typography>
          <Typography sx={descriptionSx}>
            {(user?.id === invitation?.host?.id || isCreate) ? user?.gender : invitation?.host?.gender }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <HomeIcon sx={iconSx} />
          <Typography sx={titleSx}>
            Accommodation
          </Typography>
          <Typography sx={descriptionSx}>
            {invitation?.typeOfAccommodation}
          </Typography>
        </Grid>
        {invitation?.type === 'coHost' && invitation?.linkOfAccommodation && (
          <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
            <InsertLinkIcon sx={iconSx} />
            <Typography sx={titleSx}>
              Link of Accommodation
            </Typography>
            <Typography sx={descriptionSx}>
              {invitation?.linkOfAccommodation}
            </Typography>
          </Grid>
        )}
        {invitation?.bedroomType && (
          <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
            <HotelIcon sx={iconSx}/>
            <Typography sx={titleSx}>
              Type of Bedroom
            </Typography>
            <Typography sx={descriptionSx}>
              {invitation?.bedroomType}
            </Typography>
          </Grid>
        )}
        {invitation?.numberOfGuest && <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <PeopleAltIcon sx={iconSx}/>
          <Typography sx={titleSx}>
            Number of Guests
          </Typography>
          <Typography sx={descriptionSx}>
            {invitation?.numberOfGuest}
          </Typography>
        </Grid>}
        {invitation?.genderOfGuests && <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
          <Icon sx={{width: 20, '& > img': {width: '100%'}}}>
            <img src={genderIconFile} alt={'genderIcon'}/>
          </Icon>
          <Typography sx={titleSx}>
            Gender of Guests
          </Typography>
          <Typography sx={descriptionSx}>
            {invitation?.genderOfGuests}
          </Typography>
        </Grid>}
        {invitation?.type === 'coHost' && (
          <>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <Icon sx={{width: 20, '& > img': {width: '100%'}}}>
                <img src={accommodationIconFile} alt={'genderIcon'}/>
              </Icon>
              <Typography sx={titleSx}>
                Accommodates up to:
              </Typography>
              <Typography sx={descriptionSx}>
                {invitation?.totalRooms}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <PersonIcon sx={iconSx} />
              <Typography sx={titleSx}>
                People available
              </Typography>
              <Typography sx={descriptionSx}>
                {invitation?.roomsTaken}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <BusinessCenterOutlinedIcon sx={iconSx} />
              <Typography sx={titleSx}>
                Type of Trip
              </Typography>
              <Typography sx={descriptionSx}>
                {invitation?.tripType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <PaidIcon sx={iconSx} />
              <Typography sx={titleSx}>
                Accommodation Cost
              </Typography>
              <Typography sx={descriptionSx}>
                ${invitation?.totalCost}
              </Typography>
            </Grid>
          </>
        )}
        {/*{invitation?.type === 'guest' && (
          <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
            <MailOutlineIcon sx={iconSx} />
            <Typography sx={titleSx}>
              Linked Applications
            </Typography>
            <Typography sx={descriptionSx}>
              {invitation?.linkedApplications ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        )}*/}
      </Grid>
    </Box>
  );
};

export default InvitationInfo

const iconSx = {
  color: '#c19a5b',
  fontSize: 25
};

const titleSx = {
  color: '#373632',
  fontSize: 14,
  fontWeight: 600,
  mt: 0.5
};

const descriptionSx = {
  textTransform: 'capitalize',
  color: '#373632',
  fontSize: 15,
  fontWeight: 400,
  mt: 0.5
};
