import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material/styles';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({theme}) => ({
  '&.notistack-MuiContent': {
    minWidth: 350,
    [theme.breakpoints.down('md')]: {
      minWidth: 300
    },
    '& #notistack-snackbar': {
      width: 'calc(100% - 60px)',
    },
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: '#373632',
    color: '#C19A5B',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#373632',
    color: '#C19A5B',
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#373632',
    color: '#C19A5B',
  },
}));

export default StyledMaterialDesignContent;
