import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  LOADING_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  DELETE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  SET_CONVERSATIONS,
  DELETE_CONVERSATION,
  LOADING_CONVERSATIONS,
  SET_FORGOT_PASSWORD,
  SET_REFERRALS,
  LOADING_REFERRALS,
  SET_VALIDATE_TOKEN,
  SET_UNVALIDATE_TOKEN,
  SET_RESET_PASSWORD,
} from "../types";

const initialState = {
  authenticated: false,
  loading: false,
  user: {},
  referrals: {},
  notifications: [],
  conversations: [],
  forgotPasswordEmailSent: false,
  tokenValidated: false,
  resetPassword: false,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        authenticated: true,
        loading: false,
        notifications: [],
        referrals: {},
        ...action.payload,
      };
    case SET_FORGOT_PASSWORD:
      return {
        loading: false,
        forgotPasswordEmailSent: true,
      };
    case SET_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        resetPassword: true,
      };
    case SET_VALIDATE_TOKEN:
      return {
        loading: false,
        tokenValidated: true,
      };
    case SET_UNVALIDATE_TOKEN:
      return {
        loading: false,
        tokenValidated: false,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      };
    case SET_REFERRALS:
      return {
        ...state,
        referrals: { ...state.referrals, ...action.payload },
        loading: false,
      };
    case SET_CONVERSATIONS:
      return {
        ...state,
        conversations: [...state.conversations, ...action.payload],
        loading: false,
      };

    case DELETE_CONVERSATION:
      let index = state.conversations.findIndex(
        (conversation) => conversation.id === action.payload
      );
      state.conversations.splice(index, 1);
      return {
        ...state,
      };
    case LOADING_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_REFERRALS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_CONVERSATIONS:
      return {
        ...state,
        loading: true,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };

    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state,
      };
    case DELETE_NOTIFICATION: {
      let index = state.notifications.findIndex(
        (notification) => notification._id === action.payload
      );
      state.notifications.splice(index, 1);
      return {
        ...state,
      };
    }
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
