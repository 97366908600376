import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { editUserDetails, closeAccount } from "../redux/actions/userActions.js";

import Footer from "../components/Footer";
//COMPONENTS
import Navbar from "../components/Navbar";
// MUI Stuff
import Grid from "@material-ui/core/Grid";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

//Redux Stuff
import { connect } from "react-redux";
import Input from '../components/UI/Input';
import { Button } from '../components/UI/Button';

const styles = (theme) => ({
  ...theme.spreadIt,

  settingsWrapper: {
    minHeight: "100vh",
  },

  settingsInner: {
    maxWidth: "600px",
    margin: "0px auto",
    width: "100%",
    "& h1": {
      fontWeight: "500 !important",
    },
    "@media(max-width:915px)": {
      marginTop: "50px",
    },
    "@media (max-width:650px)": {
      maxWidth: "400px",
    },
    "@media (max-width:440px)": {
      marginTop: "50px",
      maxWidth: "300px",
    },
  },
  settingsList: {
    marginTop: "20px",
    marginBottom: "50px",
  },
  settingsItem: {
    borderBottom: "1px solid #E1E1E1 ",
    padding: "12px 0px !important",
    marginBottom: "40px",
  },
  settingHeaderTitle: {
    fontSize: "15px",
    color: "#373632",
    fontWeight: "600",
  },
  settingHeaderButton: {
    fontSize: "14px",
    color: "#C19A5B",
    textDecoration: "underline",
    fontWeight: "600",
    cursor: "pointer",
  },
  settingValueWrapper: {
    marginTop: "10px",
  },
  settingValue: {
    fontSize: "14px",
    color: "#696969",
    fontWeight: "600",
    display: "block",
    lineHeight: "18px",
  },
  settingFormWrapper: {
    display: "none",
  },
  settingForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  textField: {
    maxWidth: "515px",
    marginBottom: "16px !important",
    "& .MuiInputBase-root": {
      borderRadius: "1px !important",
      border: "1px solid #C19A5B !important",
      "&.Mui-error": {
        border: "1px solid #F44336 !important",
      },
    },

    "& fieldset": {
      display: "none",
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Roboto !important",
      fontSize: "15px",
      color: "#F44336",
      margin: "0px !important;",
    },
    "& input": {
      outline: "unset !important",
      padding: "15px 20px !important",
      "&::placeholder": {
        fontFamily: "Roboto !important",
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
  },
  passwordError: {
    margin: "-20px 0px 15px 0px",
    fontFamily: "Roboto !important",
    fontSize: "15px",
    color: "#F44336",
  },
  passwordField: {
    maxWidth: "515px",
    marginBottom: "30px !important",
    '&:before': {
      border: 'none !important'
    },
    "& .MuiInputBase-root": {
      borderRadius: "1px !important",
      border: "1px solid #C19A5B !important",
      "&.Mui-error": {
        border: "1px solid #F44336 !important",
      },
    },

    backgroundColor: "white !important",
    borderRadius: "1px !important",
    border: "1px solid #C19A5B !important",
    "&.Mui-error": {
      border: "1px solid #F44336 !important",
    },

    "& fieldset": {
      display: "none",
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Roboto !important",
      fontSize: "15px",
      color: "#F44336",
      margin: "0px !important;",
    },
    "& input": {
      backgroundColor: "white",
      outline: "unset !important",
      padding: "15px 20px !important",
      "&::placeholder": {
        fontFamily: "Roboto !important",
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
  },
  button: {
    fontSize: "13px",
    padding: "8px 23px",
    lineHeight: "16px",
    boxShadow: "unset",
  },
  label: {
    fontSize: "14px !important",
    lineHeight: "23px !important",
    fontWeight: "500 !important",
    color: "#373632 !important",
    "& span": { color: "#F44336" },
  },
  closeAccountButton: {
    backgroundColor: "#F44336 !important",
  },
  updatePassDone: {
    color: "green !important",
    fontSize: "14px !important",
    fontWeight: "600",
  },
  updatePassFail: {
    color: "red !important",
    fontSize: "14px !important",
    fontWeight: "600",
  },
});

class settingsSecurityAndLogin extends Component {
  state = {
    username: this.props.user.user.username,
    email: this.props.user.user.email,
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    closeAccount: "",
    errors: {},
    updatePasswordDone: false,
    updatePasswordFail: false,
  };
  componentDidMount = () => {
    // axios
    //   .get("/auth/me")
    //   .then((res) => {})
    //   .catch((err) => {
    //     localStorage.removeItem("IdToken");
    //     delete axios.defaults.headers.common["Authorization"];
    //     window.location.href = "/";
    //   });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let userID = this.props.user.user.id;
    const userData = {
      username: this.state.username,
      email: this.state.email,
    };

    if (
      this.state.password !== this.state.confirmPassword &&
      this.state.password !== "" &&
      this.state.confirmPassword !== ""
    ) {
      this.setState({
        updatePasswordFail: true,
      });
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          password: "Passwords do not match.",
        },
      }));
    } else {
      if (this.state.password !== "" && this.state.confirmPassword !== "") {
        this.setState({ updatePasswordDone: true });
        this.setState({ updatePasswordFail: false });
        userData["password"] = this.state.password;
        userData["confirmPassword"] = this.state.confirmPassword;
      }
      if (this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ) { //eslint-disable-line
        this.props.editUserDetails(userData, userID);
        let setting = document.getElementById("settings-username-wrapper");
        let settingValue = document.getElementById("setting-value-username");
        setting.style.display = "none";
        settingValue.style.display = "block";
        document.getElementsByClassName("setting-username")[0].innerHTML =
          "Change";
        let settingEmail = document.getElementById("settings-email-wrapper");
        let settingValueEmail = document.getElementById("setting-value-email");
        settingEmail.style.display = "none";
        settingValueEmail.style.display = "block";
        document.getElementsByClassName("setting-email")[0].innerHTML =
          "Change";
        let settingPassword = document.getElementById(
          "settings-password-wrapper"
        );
        let settingValuePassword = document.getElementById(
          "setting-value-password"
        );
        settingPassword.style.display = "none";
        settingValuePassword.style.display = "block";
        document.getElementsByClassName("setting-password")[0].innerHTML =
          "Change";
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            email: "Email is not valid.",
          },
        }));
      }
    }
  };
  handleCloseAccount = (event) => {
    event.preventDefault();
    let userID = this.props.user.user.id;
    if (this.state.closeAccount !== "CLOSE") {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          closeAccount: "Please type CLOSE.",
        },
      }));
    } else {
      this.props.closeAccount(userID);
    }
  };
  handleChange = (event) => {
    if (event.target.name === "closeAccount") {
      const closeAccountValue = event.target.value.toUpperCase();
      this.setState({
        [event.target.name]: closeAccountValue,
      });
      if ("closeAccount" in this.state.errors) {
        delete this.state.errors["closeAccount"];
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
      if (
        (event.target.name === "password" ||
          event.target.name === "confirmPassword") &&
        "password" in this.state.errors
      ) {
        delete this.state.errors["password"];
      }
      if (event.target.name === "email" && "email" in this.state.errors) {
        delete this.state.errors["email"];
      }
    }
  };
  handleClickShowPassword = () => {
    this.setState({
      ...this.state.password,
      showPassword: !this.state.showPassword,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      ...this.state.confirmPassword,
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };

  handleClick = (event) => {
    let settingToChange = event.target.classList[0];

    if (settingToChange === "setting-username") {
      let setting = document.getElementById("settings-username-wrapper");
      let settingValue = document.getElementById("setting-value-username");
      if (window.getComputedStyle(setting).display === "none") {
        setting.style.display = "block";
        settingValue.style.display = "none";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Cancel";
      } else {
        setting.style.display = "none";
        settingValue.style.display = "block";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Change";
      }
    } else if (settingToChange === "setting-email") {
      let setting = document.getElementById("settings-email-wrapper");
      let settingValue = document.getElementById("setting-value-email");
      if (window.getComputedStyle(setting).display === "none") {
        setting.style.display = "block";
        settingValue.style.display = "none";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Cancel";
      } else {
        setting.style.display = "none";
        settingValue.style.display = "block";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Change";
      }
    } else if (settingToChange === "setting-password") {
      let setting = document.getElementById("settings-password-wrapper");
      let settingValue = document.getElementById("setting-value-password");
      if (window.getComputedStyle(setting).display === "none") {
        setting.style.display = "block";
        settingValue.style.display = "none";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Cancel";
      } else {
        setting.style.display = "none";
        settingValue.style.display = "block";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Change";
      }
    } else if (settingToChange === "setting-close-account") {
      let setting = document.getElementById("settings-close-account-wrapper");
      let settingValue = document.getElementById("setting-value-close-account");
      if (window.getComputedStyle(setting).display === "none") {
        setting.style.display = "block";
        settingValue.style.display = "none";
        document.getElementsByClassName(settingToChange)[0].innerHTML =
          "Cancel";
      } else {
        setting.style.display = "none";
        settingValue.style.display = "block";
        document.getElementsByClassName(settingToChange)[0].innerHTML = "Close";
      }
    }
  };
  render() {
    const { classes } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Navbar />
        <div className={"page-container " + classes.settingsWrapper}>
          <div className={classes.settingsInner}>
            <div className="page-header">
              <h1 style={{ display: "none" }}>Security and Login</h1>
            </div>
            <div className={"page-results " + classes.settingsList}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing={0}
              >
                <Grid item xs={12} sm={12} className={classes.settingsItem}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingHeader}
                  >
                    <span className={classes.settingHeaderTitle}>USERNAME</span>
                    <span
                      className={
                        "setting-username " + classes.settingHeaderButton
                      }
                      onClick={this.handleClick}
                    >
                      Change
                    </span>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingValueWrapper}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="setting-value-username"
                      className={classes.settingValue}
                    >
                      <span>{this.props.user.user.username}</span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="settings-username-wrapper"
                      className={classes.settingFormWrapper}
                    >
                      <form
                        className={classes.settingForm}
                        noValidate
                        onSubmit={this.handleSubmit}
                      >
                        <Input
                          id="username"
                          name="username"
                          type="text"
                          placeholder="Enter Username"
                          variant="outlined"
                          className={classes.textField}
                          value={this.state.username}
                          onChange={this.handleChange}
                          error={errors.error ? true : false}
                          fullWidth
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{maxWidth: 150, width: '100%'}}
                        >
                          SAVE
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.settingsItem}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingHeader}
                  >
                    <span className={classes.settingHeaderTitle}>EMAIL</span>
                    <span
                      className={"setting-email " + classes.settingHeaderButton}
                      onClick={this.handleClick}
                    >
                      Change
                    </span>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingValueWrapper}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="setting-value-email"
                      className={classes.settingValue}
                    >
                      <span>{this.props.user.user.email}</span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="settings-email-wrapper"
                      className={classes.settingFormWrapper}
                    >
                      <form
                        className={classes.settingForm}
                        noValidate
                        onSubmit={this.handleSubmit}
                      >
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          placeholder="Enter Email"
                          variant="outlined"
                          className={classes.textField}
                          value={this.state.email}
                          onChange={this.handleChange}
                          error={errors.email ? true : false}
                          fullWidth
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{maxWidth: 150, width: '100%'}}
                        >
                          SAVE
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.settingsItem}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingHeader}
                  >
                    <span className={classes.settingHeaderTitle}>PASSWORD</span>
                    <span
                      className={
                        "setting-password " + classes.settingHeaderButton
                      }
                      onClick={this.handleClick}
                    >
                      Change
                    </span>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingValueWrapper}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="setting-value-password"
                      className={classes.settingValue}
                    >
                      <span>••••••••••</span>
                    </Grid>
                    {this.state.updatePasswordDone ? (
                      <span className={classes.updatePassDone}>
                        Password updated
                      </span>
                    ) : null}
                    {this.state.updatePasswordFail ? (
                      <span className={classes.updatePassFail}>
                        Password update was non successful. Please, try again.
                      </span>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="settings-password-wrapper"
                      className={classes.settingFormWrapper}
                    >
                      <form
                        className={classes.settingForm}
                        noValidate
                        onSubmit={this.handleSubmit}
                      >
                        <InputLabel
                          className={classes.label}
                          htmlFor="password"
                        >
                          Enter new password
                        </InputLabel>
                        <FilledInput
                          id="password"
                          name="password"
                          type={this.state.showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          variant="outlined"
                          className={
                            classes.textField + " " + classes.passwordField
                          }
                          value={this.state.password}
                          onChange={this.handleChange}
                          error={errors.password ? true : false}
                          fullWidth
                          endAdornment={
                            <InputAdornment
                              style={{ backgroundColor: "transparent" }}
                              position="end"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOff style={{ color: "#6B6963" }} />
                                ) : (
                                  <Visibility style={{ color: "#6B6963" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <InputLabel
                          className={classes.label}
                          htmlFor="password"
                        >
                          Confirm new password
                        </InputLabel>
                        <FilledInput
                          id="confirmPassword"
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          placeholder="Re Enter Password"
                          variant="outlined"
                          className={
                            classes.textField + " " + classes.passwordField
                          }
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          error={errors.password ? true : false}
                          fullWidth
                          endAdornment={
                            <InputAdornment
                              style={{ backgroundColor: "transparent" }}
                              position="end"
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowConfirmPassword}
                                onMouseDown={
                                  this.handleMouseDownConfirmPassword
                                }
                                edge="end"
                              >
                                {this.state.showConfirmPassword ? (
                                  <VisibilityOff style={{ color: "#6B6963" }} />
                                ) : (
                                  <Visibility style={{ color: "#6B6963" }} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {this.state.errors.password && (
                          <span className={classes.passwordError}>
                            {" "}
                            {this.state.errors.password}
                          </span>
                        )}
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{maxWidth: 150, width: '100%'}}

                        >
                          SAVE
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.settingsItem}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingHeader}
                  >
                    <span className={classes.settingHeaderTitle}>
                      CLOSE YOUR ACCOUNT
                    </span>
                    <span
                      className={
                        "setting-close-account " + classes.settingHeaderButton
                      }
                      onClick={this.handleClick}
                    >
                      Close
                    </span>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    spacing={0}
                    className={classes.settingValueWrapper}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="setting-value-close-account"
                      className={classes.settingValue}
                    >
                      <span></span>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      id="settings-close-account-wrapper"
                      className={classes.settingFormWrapper}
                    >
                      <form
                        className={classes.settingForm}
                        noValidate
                        onSubmit={this.handleCloseAccount}
                      >
                        <Input
                          id="closeAccount"
                          name="closeAccount"
                          type="text"
                          placeholder=""
                          variant="outlined"
                          className={classes.textField}
                          value={this.state.closeAccount}
                          onChange={this.handleChange}
                          error={errors.closeAccount ? true : false}
                          fullWidth
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{maxWidth: 150, width: '100%'}}
                        >
                          CLOSE
                        </Button>
                      </form>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default userManagement;
settingsSecurityAndLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  editUserDetails: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
};

const mapActionsToProps = {
  editUserDetails,
  closeAccount,
};

const mapStateToProps = (state) => ({
  user: state.user,
  authenticated: state.user.authenticated,
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(settingsSecurityAndLogin));
