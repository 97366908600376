import React, { Component } from "react";
import AccordionComp from "../../components/StaticPages/AccordionCompTerms";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import StaticPageWrapper from '../../components/StaticPagesWrapper';

const styles = {
  toggle: {
    marginTop: "60px",
  },
  termsPrologue: {
    display: "block",
    color: "#373632",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "normal",
    textAlign: 'justify',
    padding: "8px 16px 50px 16px",
    "& a": {
      color: "#C19A5B !important",
      textDecoration: "none !important",
    },
  },
};
class TermsConditions extends Component {
  constructor() {
    super();
    this.state = {
      showButtons: true,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <StaticPageWrapper>
        <div className="static-wrapper">
          <span className="static-title">Terms & Conditions</span>

          <div className={classes.toggle}>
            <span className={classes.termsPrologue}>
              These Terms and Conditions govern your use of our Site located at
              https://www.invite.club/ (the “Site”) and the platform services
              provided by the company under the corporate name “InviteCo Ltd”
              (together “Invite.Club” or “we” or “us”). Please read these Terms
              collectively with the Privacy Policy and the Cookies Policy
              (together the “Terms”) fully and carefully before using the Site
              and the services, features, content or applications offered by it
              (the “Services”). These Terms set forth the legally binding terms
              and conditions for your use of the Site and the Services. You
              don't have to be a member to access and view the general
              information of the platform and other data and information made
              available to visitors of the Site (the “Visitors”). Members of
              Invite.Club are considered those who have completed the sign-up
              process and created an account. Members and Visitors are
              collectively referred to as “Users” or “you”.
            </span>
            <AccordionComp />
          </div>
        </div>
      </StaticPageWrapper>
    );
  }
}


TermsConditions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(withStyles(styles)(TermsConditions));
