import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import SaveDraftModal from '../features/invitations/create-invitation/SaveDraftModal';
import {
  useAddCoHostImagesMutation,
  useAddHostImagesMutation,
  useCreateDraftMutation, useUpdateDraftMutation
} from '../features/invitations/api';
import { useDispatch, useSelector } from 'react-redux';
import { dataURLtoBlob } from '../util/utils';
import { mainApi, TAGS } from '../services/mainApi';
import { setError } from '../features/invitations/slice';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';


const LeaveRouteGuard = ({when, shouldBlockNavigation}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {newInvitation, user} = useSelector(store => store);
  const [createDraft, {isSuccess, error}] = useCreateDraftMutation();
  const [updateDraft, {isSuccess: isDraftUpdateSuccess, error: updateInvitationError}] = useUpdateDraftMutation();
  const [addHostImages, {isSuccess: isHostImageSuccess ,error: hostImagesError}] = useAddHostImagesMutation();
  const [addCoHostImages, {isSuccess: isCoHostImageSuccess, error: coHostImagesError}] = useAddCoHostImagesMutation();

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    if ((isSuccess && !newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:')) &&
      !newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:')))||
      (isSuccess && isHostImageSuccess && !newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:'))) ||
      (isSuccess && isCoHostImageSuccess && !newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:'))) ||
      (isSuccess && isHostImageSuccess && isCoHostImageSuccess) || isDraftUpdateSuccess) {
      setConfirmedNavigation(true);
      setModalVisible(false);
      enqueueSnackbar(`${isDraftUpdateSuccess ? 'Draft was updated' : 'Draft was created'}`, {
        variant: 'info',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
        </IconButton>
      });
    }
  }, [isSuccess, isHostImageSuccess, isCoHostImageSuccess, newInvitation?.draft?.hostGallery, newInvitation?.draft?.coHostsGallery, isDraftUpdateSuccess]);

  useEffect(() => {
    if (error || hostImagesError || coHostImagesError || updateInvitationError) {
      setModalVisible(false);
      dispatch(setError({
        key: 'general',
        value: error?.data.error || hostImagesError?.data.error || coHostImagesError?.data.error || updateInvitationError?.data.error
      }))
    }
  }, [error, hostImagesError, coHostImagesError, updateInvitationError, dispatch]);
  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirm = () => {
    if (!newInvitation?.draft?.id) {
      const {hostGallery, coHostsGallery, ...draft} = newInvitation?.draft;
      createDraft({body: {...draft, host: user?.user?.id}}).then(res => {
        if (res.data) {
          if (newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:'))) {
            const hostImages = newInvitation?.draft.hostGallery.map(item => dataURLtoBlob(item));
            addHostImages({id: res.data.id, images: hostImages});
          }
          if (newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:'))) {
            const coHostImages = newInvitation?.draft.coHostsGallery.map(item => dataURLtoBlob(item));
            addCoHostImages({id: res.data.id, images: coHostImages});
          }
        }
      });
    } else {
      const {hostGallery, coHostsGallery, id, ...draft} = newInvitation?.draft;
      updateDraft({id, body: {...draft, host: draft?.host?.id}});
    }
  };

  const handleCancel = () => {
    setConfirmedNavigation(true);
    dispatch(mainApi.util.invalidateTags([TAGS.DRAFT]));
  }
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <SaveDraftModal open={modalVisible} onClose={closeModal} onConfirm={handleConfirm} onCancel={handleCancel} />
    </>
  );
};

export default LeaveRouteGuard;
