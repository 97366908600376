import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl, Icon
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import FormWrapper from '../../invitations/create-invitation/FormWrapper';
import FooterButtons from './FooterButtons';
import InfoIcon from '@mui/icons-material/Info';
import { Button } from '../../../components/UI/Button';
import Label from '../../../components/UI/Label';
import Input from '../../../components/UI/Input';
import ImageRow from '../../invitations/ImageRow';
import { useDispatch, useSelector } from 'react-redux';
import { setError, updateForm } from '../slice';
import { blobToDataURL, getVideoDuration } from '../../../util/utils';
import Video from '../../../components/Video';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import RadioButton from '../../../components/UI/RadioButton';
import previewIconFile from '../../../assets/create_inv/preview.svg';
import PreviewApplication from '../PreviewApplication';
import InfoModal from './InfoModal';
import dayjs from 'dayjs';
import CustomSwitch from '../../../components/UI/CustomSwitch';

const ApplyGuestInvitation = ({requiredFields: {pictures, video}, name, uid}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const {application: {applicationForm: form, errors}, user} = useSelector(store => store);
  const progressValue = step * 50;
  const [openPreview, setOpenPreview] = useState(false);
  const isNextDisabled = !form?.messageToHost || (pictures && !form?.gallery?.length) ||
    (video && !form?.video) || !form?.departurePoint || !form.hasOwnProperty('visitedBefore');
  const [showLinkApplication, setShowLinkApplication] = useState(false);

  const userInfo = {
    shortBio: user?.user?.shortBio || '',
    gender: user?.user?.gender || '',
    occupationSector: user?.user?.occupationSector || '',
    interests: user?.user?.interests || [],
    nationality: user?.user?.nationality || '',
    age: dayjs().diff(dayjs(user?.user?.birthDate), 'year') || 0,
    languages: user?.user?.languages || [],
  };

  const onChange = ({target: {value, name}}) => {
    if (name === 'messageToHost' && value.length > 700) {
      dispatch(updateForm({key: name, value: value.slice(0, 700)}));
      dispatch(setError({key: 'messageToHost', value: 'Message to the Host should be less than 700 characters'}));
      return;
    }
    dispatch(setError({key: 'messageToHost', value: null}));
    dispatch(updateForm({key: name, value: value}));
  }

  const onChangeImage = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    if (e?.target?.files[0].size > 4000000) {
      dispatch(setError({key: 'gallery', value: 'Image size should be less than 4mb.'}));
      return;
    }
    blobToDataURL(e?.target?.files[0], (dataUrl) => {
      dispatch(updateForm({
        key: 'gallery',
        value: !form?.gallery?.includes(dataUrl) ? [...form?.gallery || [], dataUrl] : form?.gallery || []
      }));
    });
    dispatch(setError({key: 'gallery', value: null}));
    e.target.value = '';
  };

  const onDeleteImage = (value) => {
    dispatch(updateForm({key: 'gallery', value: form?.gallery?.filter(item => item !== value)}))
  }

  const onChangeVideo = (e) => {
    getVideoDuration(e.target.files[0]).then((duration) => {
      if (duration > 30) {
        dispatch(setError({key: 'video', value: 'Video duration should be less than 30 seconds.'}));
        return;
      }
      if (!e.target.files[0]) {
        return;
      }
      if (e?.target?.files[0].size > 60000000) {
        dispatch(setError({key: 'video', value: 'Video size should be less than 60mb.'}));
        return;
      }
      blobToDataURL(e?.target?.files[0], (dataUrl) => {
        dispatch(updateForm({
          key: 'video',
          value: dataUrl
        }));
      });
      dispatch(setError({key: 'video', value: null}));
      e.target.value = '';
    });
  }

  const onDeleteVideo = () => {
    dispatch(updateForm({key: 'video', value: ''}));
  };

  const handleLocation = (key) => (value) => {
    dispatch(updateForm({key, value}));
    if (key === 'departurePoint' && !form?.returnPoint) {
      dispatch(updateForm({key: 'returnPoint', value}));
    }
  };

  const onChangeSwitch = ({target: {name, checked}}) => {
    setShowLinkApplication(checked);
    if (!checked) {
      dispatch(updateForm({key: 'linkedApplicationUser', value: ''}))
    }
  }

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          width: '100%',
        }}
      >
        <Stack direction={'row'} alignItems={'center'} sx={{position: 'relative'}}>
          <CircularProgress
            sx={{
              position: 'absolute',
              left: 0,
              '& .MuiCircularProgress-circle': {
                color: '#F9F5EF'
              }
            }}
            thickness={8}
            size={50}
            variant="determinate"
            value={100}
          />
          <CircularProgress
            sx={{
              '& .MuiCircularProgress-circle': {
                color: '#C19A5B',
              }
            }}
            thickness={8}
            size={50}
            variant="determinate"
            value={progressValue}
          />
          <Typography sx={{ml: 2, color: '#C19A5B', fontSize: 14, fontWeight: 700, textTransform: 'uppercase'}}>
            Step {step} of 2
          </Typography>
        </Stack>
        <IconButton onClick={() => history.goBack()} sx={{'& > svg': {color: '#ccc', fontSize: 32}}}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Apply To Invitation</Typography>
        <Typography sx={{fontSize: 14, fontWeight: 500, mb: 2, display: 'flex', textTransform: 'capitalize'}}>
          {name} {uid && `#${uid}`}
        </Typography>
        <Stack direction={'row'} spacing={1} onClick={() => setOpen(true)} sx={{cursor: 'pointer', mb: 2}}>
          <InfoIcon sx={{color: '#c19a5b'}} />
          <Typography sx={{fontSize: 14, lineHeight: '20px', letterSpacing: '0.21px'}}>
            Certain information is automatically retrieved from your public profile details. You can edit this by editing your profile.
          </Typography>
        </Stack>
        {step === 1 && (
          <>
            <Label>Personal Message to the Host</Label>
            <Input
              name={'messageToHost'}
              value={form?.messageToHost || ''}
              onChange={onChange}
              sx={{mb: errors?.messageToHost ? 1 : 3}}
              multiline
              maxRows={4}
              minRows={4}
              error={Boolean(errors?.messageToHost)}
            />
            {errors?.messageToHost && <Typography sx={{fontSize: 14, mb: 3}} color={'red'}>{errors?.messageToHost}</Typography>}
            {pictures && (
              <>
                <Label>Upload at least one picture of you</Label>
                <ImageRow
                  fiveImages
                  images={form?.gallery || []}
                  onChange={onChangeImage}
                  onDelete={onDeleteImage}
                  error={errors?.gallery}
                />
              </>
              )
            }
            {video && (
              <>
                <Label>Upload a Short Video (up to 30 sec.) of you. Supported format (mp4, mov)</Label>
                <Video video={form?.video || null} onChange={onChangeVideo} onDelete={onDeleteVideo} sx={{mb: errors?.video ? 1 : 4}} />
                {errors?.video && <Typography sx={{fontSize: 14, mb: 3, color: 'red'}}>{errors?.video}</Typography>}
              </>
            )}
            <Label>Departure point</Label>
            <GoogleAutocomplete value={form?.departurePoint || null} handleChange={handleLocation('departurePoint')} />
            <Label>Return Point (if Different)</Label>
            <GoogleAutocomplete value={form?.returnPoint || null} handleChange={handleLocation('returnPoint')} />
            <FormControl>
              <Typography
                sx={{
                  color: '#373632',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Have you been in that Location before?
              </Typography>
              <RadioGroup
                sx={{
                  mb: 2,
                  "& .MuiTypography-root": {
                    fontWeight: 400,
                  },
                }}
                row={true}
                name="visitedBefore"
                value={form?.visitedBefore || null}
                onChange={onChange}
              >
                <FormControlLabel
                  value={true}
                  control={<RadioButton />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<RadioButton />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </>
        )}
        {step === 2 && (
          <>
            <Label>Special Requirements, for example dietary, allergies etc. •<em>Optional</em></Label>
            <Input
              value={form?.specialRequirements || ''}
              name={'specialRequirements'}
              onChange={onChange}
              sx={{mb: 3}}
              multiline
              maxRows={4}
              minRows={4}
            />
            <FormControlLabel
              sx={{mb: 3}}
              control={
                <CustomSwitch
                  onChange={onChangeSwitch}
                  checked={showLinkApplication}
                />
              }
              label={
                <Typography sx={{
                  display: 'flex',
                  color: '#373632',
                  fontSize: 14,
                  fontWeight: 400,
                }}>
                  Link with another Application •<em>Optional</em>
                </Typography>
              }
            />
            {showLinkApplication && (
              <>
                <Label>
                  If you want to travel with another guest please specify the other guest's username.
                  The application will be sent to the Host only when both applications are submitted.
                </Label>
                <Input value={form?.linkedApplicationUser || ''} name={'linkedApplicationUser'} onChange={onChange} sx={{mb: 3}} />
              </>
            )}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenPreview(true)}
              sx={{py: 0.5}}
              startIcon={
                <Icon sx={{width: 'auto', height: 'auto'}}>
                  <img src={previewIconFile} alt={'preview'} />
                </Icon>
              }
            >
              PREVIEW APPLICATION
            </Button>
          </>
        )}
      </FormWrapper>
      <FooterButtons
        isNextDisabled={isNextDisabled}
        showNext={step === 1}
        showPrev={step === 2}
        showApply={step === 2}
        onNextStep={() => setStep(2)}
        onPrevStep={() => setStep(1)}
      />
      <InfoModal open={open} onClose={() => setOpen(false)} />
      <PreviewApplication open={openPreview} onClose={() => setOpenPreview(false)} application={{...form, ...userInfo}} />
    </>
  );
};

export default ApplyGuestInvitation;
