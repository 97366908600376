import { Box } from '@mui/material';

const ActivityWrapper = ({children, sx}) => (
  <Box sx={{
    display: 'flex',
    gap: 0.5,
    flexWrap: 'wrap',
    '& .MuiButtonBase-root': {
      borderRadius: '4px',
      padding: '4px 8px',
      background: 'transparent',
      border: '1px solid #C19A5B',
      boxShadow: 'inset 0px 0px 0px 1px rgba(193,154,91,1)',
      marginLeft: 0,
      cursor: 'auto',
      gap: '8px',
      '& + .MuiButtonBase-root': {
        borderLeft: '1px solid #C19A5B',
      }
    },
    '& img': {
      maxHeight: "20px",
      maxWidth: "22px",
      marginRight: "5px",
      pointerEvents: 'none',
    },
    '& .MuiTypography-root': {
      color: '#373632',
      fontSize: '14px',
      fontWeight: 400,
      pointerEvents: 'none',
      textTransform: 'capitalize'
    },
    ...sx
  }}>
    {children}
  </Box>
);

export default ActivityWrapper;
