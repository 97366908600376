import FormWrapper from './FormWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setError, updateDraft } from '../slice';
import dayjs from 'dayjs';
import { FormControlLabel, Icon, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import Label from '../../../components/UI/Label';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import DatePicker from '../../../components/UI/DatePicker';
import Input from '../../../components/UI/Input';
import Select from '../../../components/UI/Select';
import CustomSwitch from '../../../components/UI/CustomSwitch';
import FormButtons from './FormButtons';
import { Button } from '../../../components/UI/Button';
import previewIconFile from '../../../assets/create_inv/preview.svg';
import PreviewInvitationModal from '../PreviewInvitationModal';

const EventGuestInvitationForm = () => {
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const isDisabled = !newInvitation?.draft?.locationFrom || !newInvitation?.draft?.startDate ||
    !newInvitation?.draft?.additionalInfo || !newInvitation?.draft?.numberOfGuest || !newInvitation?.draft?.genderOfGuests ||
    !newInvitation?.draft?.deadlineOfApplications;
  const [open, setOpen] = useState(false);

  const handleDateChange = (e) => (key) => {
    dispatch(updateDraft({key, value: dayjs(e?.$d || null).format()}));
  };

  const handleChange = ({target: {name, value, type}}) => {
    if (type === 'number') {
      dispatch(updateDraft({key: name, value: value < 0 ? Math.abs(value) : value}));
      return;
    }
    dispatch(updateDraft({key: name, value}));
  };

  const handleLocation = (key) => (value) => {
    dispatch(setError({key, value: null}))
    dispatch(updateDraft({key, value}));
    if (!value?.city || !value?.country) {
      dispatch(setError({key, value: 'Please provide city and country'}))
    }
  };

  const handleSwitch = ({target: {name, checked}}) => {
    dispatch(updateDraft({key: 'requiredFields', value: {...newInvitation?.draft?.requiredFields, [name]: checked }}))
  };

  return (
    <>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation for Guests</Typography>
        <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>EVENT INFORMATION</Typography>
        <Label>Event name</Label>
        <Input
          name="name"
          placeholder=""
          multiline
          maxRows={5}
          value={newInvitation?.draft?.name}
          onChange={handleChange}
          sx={{mb: 3}}
        />
        <Label>Location</Label>
        <GoogleAutocomplete
          value={newInvitation?.draft?.locationFrom || null}
          handleChange={handleLocation('locationFrom')}
          error={newInvitation?.errors?.locationFrom}
        />
        <Label>Date</Label>
        <DatePicker
          name={'startDate'}
          value={newInvitation?.draft?.startDate || null}
          onChange={(e) => handleDateChange(e)('startDate')}
          minDate={dayjs().add(1, "day")}
          maxDate={
            newInvitation?.draft?.endDate
              ? dayjs(newInvitation?.draft?.endDate).add(-1, "day")
              : dayjs().add(1, "year")
          }
          sx={{mb: 4}}
        />
        <Label>Event information</Label>
        <Input
          name="additionalInfo"
          placeholder="Fill in"
          multiline
          maxRows={5}
          value={newInvitation?.draft?.additionalInfo}
          onChange={handleChange}
          sx={{mb: 4}}
        />
        <Label>Number Of Guests Invited</Label>
        <Input
          name="numberOfGuest"
          placeholder="Choose number of guests"
          type="number"
          value={newInvitation?.draft?.numberOfGuest || ''}
          onChange={handleChange}
          sx={{mb: 4}}
          inputProps={{inputMode: 'numeric', min: 1}}
          helperText={newInvitation?.errors?.numberOfGuest}
          error={!!newInvitation?.errors?.numberOfGuest}
          fullWidth
        />
        <Label>Gender Of Guests</Label>
        <Select
          name="genderOfGuests"
          value={newInvitation?.draft?.genderOfGuests || ''}
          onChange={handleChange}
          displayEmpty
          fullWidth
          sx={{mb: 4}}
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"both"}>Both</MenuItem>
        </Select>
        <Label>Deadline For Application</Label>
        <DatePicker
          value={newInvitation?.draft?.deadlineOfApplications || null}
          maxDate={dayjs(newInvitation?.draft?.startDate).add(-1, "day")}
          minDate={dayjs().add(1, "day")}
          onChange={(e) => handleDateChange(e)('deadlineOfApplications')}
          sx={{mb: 3}}
        />
        <FormControlLabel
          sx={{mb: 3}}
          control={
            <CustomSwitch
              name={'video'}
              checked={newInvitation?.draft?.requiredFields?.video}
              onChange={handleSwitch}
            />
          }
          label={
            <Typography sx={{
              display: 'flex',
              color: '#373632',
              fontSize: 14,
              fontWeight: 400,
            }}>
              Guest must submit
              <Typography sx={{fontSize: 14, fontWeight: 700, ml: '3px'}} component={'span'}>
                a Short Video introducing themselves
              </Typography>
            </Typography>
          }
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          sx={{py: 1}}
          startIcon={
            <Icon sx={{width: 'auto', height: 'auto'}}>
              <img src={previewIconFile} alt={'preview'} />
            </Icon>
          }
        >
          PREVIEW INVITATION
        </Button>
      </FormWrapper>
      <FormButtons showPrev showCreate isCreateDisabled={isDisabled} />
      <PreviewInvitationModal
        open={open}
        onClose={() => setOpen(false)}
        invitation={{...newInvitation?.draft}}
      />
    </>
  );
};

export default EventGuestInvitationForm;
