import ModalTitle from '../../components/UI/DialogTitle';
import { Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import InvitationTimeline from './invitation/InvitationTimeline';
import InvitationInfo from './invitation/InvitationInfo';
import EventInvitationInfo from './invitation/EventInvitationInfo';

const PreviewInvitationModal = ({showTitle = true, open, onClose, invitation}) => {
  console.log(1111, invitation);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 1300,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        {showTitle && <Typography sx={{fontSize: 15, fontWeight: 600}}>PREVIEW INVITATION</Typography>}
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          alignItems={{xs: 'flex-start', sm: 'center'}}
          justifyContent={'space-between'}
          spacing={{xs: 1, sm: 2}}
        >
          <Typography sx={{color: "#C19A5B", fontSize: 28}}>
            {invitation?.name && invitation?.experienceType === 'event' && invitation.name}
            {!invitation?.name && invitation?.experienceType === 'event' && 'Event name'}
            {!invitation?.name &&invitation?.experienceType === 'trip' && 'Trip name'} {invitation?.uid && `#${invitation?.uid}`}
          </Typography>
          <Typography sx={{color: "#373632", fontSize: 17}}>
            {dayjs(invitation?.startDate).format("DD MMM YYYY")} - {" "}{dayjs(invitation?.endDate).format("DD MMM YYYY")}
          </Typography>
        </Stack>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{mt: 1}}
        >
          <Grid item xs={12} sm={6}>
            <InvitationTimeline invitation={invitation} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {invitation?.experienceType === 'event' ? <EventInvitationInfo invitation={invitation} /> : <InvitationInfo invitation={invitation} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewInvitationModal;
