import { Box, Dialog, DialogContent, Grid, IconButton, InputAdornment, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalTitle from '../../components/UI/DialogTitle';
import { Button } from '../../components/UI/Button';
import CheckIcon from '@mui/icons-material/Check';
import { useChangeStatusMutation } from './api';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import Input from '../../components/UI/Input';
import DatePicker from '../../components/UI/DatePicker';
import Label from '../../components/UI/Label';
import InstagramIcon from '@mui/icons-material/Instagram';
import SectorsSelect from '../../components/SectorsSelect';
import Checkbox from '../../components/UI/Checkbox';

const ViewWaitlistApplication = ({open, onClose, userInfo}) => {
  const [changeStatus, {isLoading}] = useChangeStatusMutation();
  const [foundingMemberCheckbox, setFoundingMemberCheckbox] = useState(false);

  useEffect(() => {
    if (userInfo?.isFoundingMember) {
      console.log(123);
      setFoundingMemberCheckbox(true);
    }
    return () => (setFoundingMemberCheckbox(false));
  }, [userInfo])

  const handleFoundingMemberChange = (e, checked) => {
    setFoundingMemberCheckbox(checked);
  };

  const onApply = () => {
    const body = {
      id: userInfo._id,
      status: 'approved',
      isFoundingMember: foundingMemberCheckbox,
      isConfirmedFoundingMember: foundingMemberCheckbox,
    };
    changeStatus(body).then(res => {
      if (res.data) {
        enqueueSnackbar(`Application was approved`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        onClose();
      }
    });
  };

  const onDefer = () => {
    const body = {
      id: userInfo._id,
      status: 'deferred',
      isFoundingMember: foundingMemberCheckbox,
      isConfirmedFoundingMember: foundingMemberCheckbox,
    };
    changeStatus(body).then(res => {
      if (res.data) {
        enqueueSnackbar(`Application was defered`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        onClose();
      }
    });
  };

  const onPreApprove = () => {
    const body = {
      id: userInfo._id,
      status: 'preApproved',
      isFoundingMember: foundingMemberCheckbox,
      isConfirmedFoundingMember: foundingMemberCheckbox,
    };
    changeStatus(body).then(res => {
      if (res.data) {
        enqueueSnackbar(`Application was pre approved`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        onClose();
      }
    });
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          maxWidth: {xs: '100%', sm: 700},
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <ModalTitle onClose={onClose}>
        VIEW APPLICATION
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3, display: 'flex', flexDirection: 'column'}}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{flexWrap: 'wrap', '& > *': {width: {xs: '100%', sm: '45%'}}}}>
          <Box>
            <Label>
              First Name
            </Label>
            <Input
              value={userInfo.firstName}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              sx={{mb: 3}}
            />
          </Box>
          <Box>
            <Label htmlFor="surname">
              Last Name
            </Label>
            <Input
              value={userInfo.lastName}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              sx={{mb: 3}}
            />
          </Box>
          <Box>
            <Label>
              Email
            </Label>
            <Input
              value={userInfo.email}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              sx={{mb: 3}}
            />
          </Box>
          <Box>
            <Label>
              Date of Birth
            </Label>
            <DatePicker
              value={userInfo.birthDate}
              readOnly={true}
              onChange={() => {}}
            />
          </Box>
          <Box>
            <Label>Instagram</Label>
            <Input
              value={userInfo?.instagram || ''}
              fullWidth
              sx={{mb: 3}}
              InputProps={{
                startAdornment: <InputAdornment position="start"><InstagramIcon color={'primary'} /></InputAdornment>,
                readOnly: true,
              }}
            />
          </Box>
          <Box>
            <Label sx={{mb: 0.5}}>I live in</Label>
            <Input
              fullWidth
              value={userInfo?.liveIn?.description || ''}
              sx={{mb: 3}}
              inputProps={{ readOnly: true }}
            />
          </Box>
          <Box>
            <Label sx={{mb: 0.5}}>I am from</Label>
            <Input
              fullWidth
              value={userInfo?.from?.description || ''}
              sx={{mb: 3}}
              inputProps={{ readOnly: true }}
            />
          </Box>
          <Box>
            <Label>Industry</Label>
            <SectorsSelect
              value={userInfo?.occupationSector || ''}
              sx={{mb: 3}}
              inputProps={{ readOnly: true }}
            />
          </Box>
          {userInfo?.company && (
            <Box>
              <Label>Company</Label>
              <Input
                value={userInfo?.company || ''}
                fullWidth
                sx={{mb: 3}}
                inputProps={{ readOnly: true }}
              />
            </Box>
          )}
          {userInfo?.referralCode && (
            <Box>
              <Label>Referral name or code</Label>
              <Input
                value={userInfo?.referralCode || ''}
                fullWidth
                sx={{mb: 3}}
                inputProps={{ readOnly: true }}
              />
            </Box>
          )}
          <Stack direction={'column'} alignItems={'flex-start'}>
            <Label>Founding member</Label>
            <Checkbox
              sx={{mt: 1, ml: -1, '& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
              disableRipple
              color="default"
              disabled={userInfo?.status === 'approved'}
              checked={foundingMemberCheckbox}
              onChange={handleFoundingMemberChange}
            />
          </Stack>
        </Stack>
        <Grid container rowSpacing={2} columnSpacing={6} sx={{mt: 5}}>
          <Grid item xs={12} sm={6}>
            <Button
              variant={'contained'}
              color={'action'}
              disabled={userInfo.status === 'defer' || userInfo.status === 'active' || userInfo.status === 'approved' || isLoading}
              onClick={onDefer}
              fullWidth
              sx={{
                '&.MuiButton-containedAction': {
                  height: 48,
                  lineHeight: 1.25,
                  display: 'block',
                },
                '&.Mui-disabled': {
                  opacity: 0.7
                }
              }}
            >
              Defer
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant={'outlined'}
              color={'success'}
              fullWidth
              sx={{
                '&.MuiButton-outlinedSuccess': {
                  height: 48,
                  background:'#fff',
                  border: `2px solid #589C0C`,
                  color: '#589C0C !important',
                  boxShadow: 'none',
                },
                '&.Mui-disabled': {
                  opacity: 0.7,
                  border: `2px solid #589C0C`,

                }
              }}
              disabled={userInfo.status === 'preApproved' || userInfo.status === 'approved' || userInfo.status === 'active' || isLoading}
              onClick={onPreApprove}
            >
              Pre Approve
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant={'outlined'}
              color={'success'}
              fullWidth
              sx={{
                '&.MuiButton-outlinedSuccess': {
                  height: 48,
                  background:'#589C0C',
                  border: `2px solid #589C0C`,
                  color: '#fff',
                  boxShadow: 'none',
                },
                '&.Mui-disabled': {
                  opacity: 0.7
                }
              }}
              startIcon={<CheckIcon sx={{color: '#fff'}} />}
              disabled={userInfo.status === 'approved' || userInfo.status === 'active' ||  isLoading}
              onClick={onApply}
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewWaitlistApplication;
