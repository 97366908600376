import { Grid, Typography, Stack, Dialog, IconButton, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../components/UI/Label';
import React, { useState } from 'react';
import { getUserData } from '../../redux/actions/userActions';
import { useDeleteProfileImgMutation, useUpdateProfileImgMutation } from '../../services/mainApi';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../components/Loader';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const ProfilePhotoTiles = ({showActions = false, sx}) => {
  const {user} = useSelector(store => store.user);
  const [updateImage, {isLoading}] = useUpdateProfileImgMutation();
  const [deleteProfileImage] = useDeleteProfileImgMutation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const handleUploadImage = (e) => {
    if (!e?.target?.files[0]) {
      return;
    }
    if (e?.target?.files[0].size > 4000000) {
      setError('Image size should be less than 4mb.');
      return;
    }
    setError(null);
    updateImage({id: user?.id, image: e.target.files[0]}).then(() => dispatch(getUserData()));
    e.target.value = '';
  };

  const handleDelete = (e) => (imageLink) => {
    e.stopPropagation();
    deleteProfileImage({id: user.id, body: {imageLink}}).then(() => dispatch(getUserData()));
  };

  return (
    <>
      <Typography sx={{mb: 2, width: '100%'}} align={'left'}>My Photos</Typography>
      <Grid container spacing={1} sx={{mb: 3, transform: 'translate3d(0, 0, 0)', justifyContent: 'center', ...sx}}>
        {user?.imgUrl?.map((img, i) => (
          <Grid
            onClick={() => setOpen(i)}
            key={i}
            item
            xs={6}
            sm={4}
            sx={{
              aspectRatio: '1',
              position: 'relative',
              '& > img': {
                width: '100%',
                height: '100%',
                borderRadius: '20px',
                overflow: 'hidden',
                objectFit: 'cover',
                WebkitBackfaceVisibility: "hidden",
                msTransform: "translateZ(0)",
                WebkitTransform: "translateZ(0)",
                transform: "translateZ(0)",
                cursor: 'pointer',
                transition: 'all 300ms linear 200ms',
              },
            }}
          >
            <img src={img} alt={img} />
            {showActions && (
              <IconButton
                onClick={(e) => handleDelete(e)(img)}
                disabled={isLoading}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  borderRadius: '50%',
                  background: '#fff',
                  padding: 0.5,
                  boxShadow: '0 2px 4px rgba(50, 55, 64, 1)',
                  '&:hover': {
                    background: '#fff',
                  },
                  '& > svg': {
                    fill: '#000',
                    fontSize: 18,
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        ))}
        {user?.imgUrl?.length < 6 && showActions && (Array(6 - user?.imgUrl?.length).fill(null).map((_, i) => (
          <Grid item xs={6} sm={4} sx={{aspectRatio: '1'}} key={i}>
           <Label
             htmlFor={'profileImage'}
             sx={{
               '& > input': {display: 'none'},
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               width: '100%',
               height: '100%',
               cursor: 'pointer',
               background: '#e6e6e6',
               borderRadius: '20px',
               overflow: 'hidden',
               '&:hover': {
                 '.MuiTypography-root': {
                   transform: 'scale(1.5)'
                 }
               }
             }}
           >
             <input type="file" accept="image/*" id={'profileImage'} onChange={handleUploadImage}/>
             <Typography sx={{fontSize: 40, transition: 'all 300ms linear 200ms'}}>+</Typography>
           </Label>
          </Grid>
        )))}
        {user?.imgUrl?.length < 6 && !showActions && (Array(6 - user?.imgUrl?.length).fill(null).map((_, i) => (
          <Grid item xs={6} sm={4} sx={{aspectRatio: '1'}} key={i}>
            <Label
              htmlFor={'profileImage'}
              sx={{
                '& > input': {display: 'none'},
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                background: '#e6e6e6',
                borderRadius: '20px',
                overflow: 'hidden',
                '&:hover': {
                  '.MuiTypography-root': {
                    transform: 'scale(1.5)'
                  }
                }
              }}
            />
          </Grid>
        )))}
      </Grid>
      {Boolean(error) && <Typography align={'center'} sx={{fontSize: 14, mb: 4, color: 'red'}}>{error}</Typography>}
      <ImageModal open={open === +open} currentSlide={open} onClose={() => setOpen(false)} />
      {isLoading && <Loader />}
    </>
  );
};

export default ProfilePhotoTiles;

const ImageModal = ({open, onClose, currentSlide = 0}) => {
  const {user} = useSelector(store => store.user);

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          background: 'transparent',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(176, 176, 176, 0.6)",
        },
      }}
    >

      <Stack
        alignItems={'center'}
        justifyContent={'center'} sx={{width: '100%', height: '100%', padding: {xs: 2, sm: 5, md: 10}, position: 'relative'}}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            '& > svg': {
              fill: '#000',
              fontSize: 50,
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            '& img': {
              maxWidth: '100%',
              maxHeight: '100%',
            },
            '& .swiper': {
              height: '100%',
            },
            '& .swiper-slide':{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            },
            '& .swiper-button-next': {
              color: '#000',
            },
            '& .swiper-button-prev': {
              color: '#000',
            }
          }}
        >
          <Swiper
            spaceBetween={10}
            navigation={true}
            modules={[Navigation]}
            initialSlide={currentSlide || 0}
          >
            {user?.imgUrl?.map((image, i) => (
              <SwiperSlide key={`slide-${i}`}>
                <img src={image} alt={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Stack>
    </Dialog>
  );
};
