import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Box } from '@mui/material';

const StaticPageWrapper = ({children, sx, hideFooter = false}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box sx={{pt: {xs: 13, md: 0}, pb: {xs: 5, md: 10}, ...sx}}>
      <Header />
      {children}
      {!hideFooter && <Footer isStaticFooter/>}
    </Box>
  )
};

export default StaticPageWrapper;
