import { Portal, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationBlock, setStep } from '../slice';
import { Button } from '../../../components/UI/Button';
import {
  useAddCoHostImagesMutation,
  useAddHostImagesMutation,
  useCreateInvitationMutation,
  useUpdateInvitationMutation
} from '../api';
import LeaveRouteGuard from '../../../components/LeaveRouteGuard';
import { dataURLtoBlob } from '../../../util/utils';
import { useHistory } from 'react-router-dom';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@material-ui/core';
import Loader from '../../../components/Loader';

const FormButtons = ({showNext = false, showPrev = false, showCreate = false, isNextDisabled = true, isCreateDisabled = false}) => {
  const {newInvitation, user} = useSelector(store => store);
  const dispatch = useDispatch();
  const history = useHistory();
  const [create, {isSuccess: isCreateSuccess, error, isUninitialized, data: createdInvitation, isLoading: isCreateLoading}] = useCreateInvitationMutation();
  const [addHostImages, {isSuccess: isHostImageSuccess, error: hostImagesError, isLoading: isHostImagesLoading}] = useAddHostImagesMutation();
  const [addCoHostImages, {isSuccess: isCoHostImageSuccess, error: coHostImagesError, isLoading: isCoHostImagesLoading}] = useAddCoHostImagesMutation();
  const [updateInvitation, {isSuccess: isUpdateSuccess, error: updateInvitationError, isLoading: isUpdateLoading, data: updatedInvitation}] = useUpdateInvitationMutation();
  const isError = error || hostImagesError || coHostImagesError || updateInvitationError;

  useEffect(() => {
    if (isError) {
      dispatch(setNavigationBlock(true));
      enqueueSnackbar(error?.data.error || hostImagesError?.data.error || coHostImagesError?.data.error || updateInvitationError?.data.error, {
        variant: 'error',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
        </IconButton>
      });
    }
  }, [error, hostImagesError, coHostImagesError, updateInvitationError, dispatch, isError])


  useEffect(() => {
    if ((isCreateSuccess && !newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:')) &&
      !newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:')) && !isError) || (isCreateSuccess && isHostImageSuccess &&
      !newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:')) && !isError) || (isCreateSuccess && isCoHostImageSuccess &&
      !newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:'))) ||
      (isCreateSuccess && isHostImageSuccess && isCoHostImageSuccess) || isUpdateSuccess) {
      enqueueSnackbar(`${(isCreateSuccess && createdInvitation?.name) || (isUpdateSuccess && updatedInvitation?.name)} was created`, {
        variant: 'success',
        autoHideDuration: 10000,
        preventDuplicate: true,
        key: 'not_found',
        action: key => <IconButton
          onClick={() => closeSnackbar(key)}
          color={'primary'}
        >
          <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
        </IconButton>
      });
      history.push('/my-invitations');
    }
  }, [  //eslint-disable-line
    history,
    isCreateSuccess,
    isHostImageSuccess,
    isCoHostImageSuccess,
    isUpdateSuccess,
    newInvitation?.draft?.hostGallery,
    newInvitation?.draft?.coHostsGallery
  ]);

  const onPrevStep = () => {
    dispatch(setStep(newInvitation.step - 1))
  };

  const onNextStep = () => {
    dispatch(setStep(newInvitation.step + 1))
  };

  const onCreateInvitation = () => {
    const {hostGallery, coHostsGallery, ...draft} = newInvitation?.draft;
    dispatch(setNavigationBlock(false));
    if (!isUninitialized) {
      if (newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:'))) {
        const hostImages = newInvitation?.draft.hostGallery.map(item => dataURLtoBlob(item));
        addHostImages({id: createdInvitation?.id, images: hostImages});
      }
      if (newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:'))) {
        const coHostImages = newInvitation?.draft.coHostsGallery.map(item => dataURLtoBlob(item));
        addCoHostImages({id: createdInvitation?.id, images: coHostImages});
      }
      return;
    }
    if (!newInvitation?.draft?.id) {
      create({body: {...draft, status: 'active', host: user?.user?.id}}).then(res => {
        if (res.data) {
          if (newInvitation?.draft?.hostGallery?.some(item => item.startsWith('data:'))) {
            const hostImages = newInvitation?.draft.hostGallery.map(item => dataURLtoBlob(item));
            addHostImages({id: res.data.id, images: hostImages}).then(res => {
              if (newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:'))) {
                const coHostImages = newInvitation?.draft.coHostsGallery.map(item => dataURLtoBlob(item));
                addCoHostImages({id: res.data.id, images: coHostImages});
              }
            });
            return;
          }
          if (newInvitation?.draft?.coHostsGallery?.some(item => item.startsWith('data:'))) {
            const coHostImages = newInvitation?.draft.coHostsGallery.map(item => dataURLtoBlob(item));
            addCoHostImages({id: res.data.id, images: coHostImages});
          }
        }
      });
    } else {
      updateInvitation({
        id: newInvitation?.draft?.id,
        body: {...draft, status: 'active', host: draft?.host?.id}
      })
    }
  };

  return (
    <Stack
      direction={{xs: 'column', sm: 'row'}}
      justifyContent={'space-between'}
      alignItems={'center'}
      spacing={2}
      sx={{
        position: {xs: 'static', md: 'fixed'},
        p: 2,
        zIndex: 1000,
        right: 0,
        bottom: {xs: 0, md: 46},
        width: {xs: '100%', md: 'calc(100% - 350px)'},
        background: '#fff',
      }}
    >
      {showPrev && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onPrevStep}
          sx={{
            maxWidth: "330px",
            width: "100%",
            "& .MuiButton-label": {
              lineHeight: "18px",
              fontWeight: "500",
            },
          }}
        >
          PREVIOUS
        </Button>
      )}
      {showNext && (
        <Button
          variant="contained"
          color={'primary'}
          sx={{
            marginLeft: "auto",
            maxWidth: "330px",
            width: "100%",
            "& .MuiButton-label": {
              lineHeight: "18px",
              fontWeight: "500",
            },
          }}
          onClick={onNextStep}
          disabled={isNextDisabled}
        >
          NEXT
        </Button>
      )}
      {showCreate && (
        <Button
          disabled={isCreateDisabled}
          variant="contained"
          color="action"
          sx={{
            padding: "15px",
            maxWidth: "330px",
            width: "100%",
            backgroundColor: "#373632",
            "&.Mui-disabled": {
              opacity: 0.5,
            },
            "@media (max-width:700px)": {
              marginBottom: "20px",
            },
            "& .MuiButton-label": {
              lineHeight: "18px",
              fontWeight: "500",
            },
          }}
          onClick={onCreateInvitation}
        >
          CREATE INVITATION
        </Button>
      )}
      <Portal>
        <LeaveRouteGuard
          when={newInvitation.step > 0}
          shouldBlockNavigation={(location) => (
            !location.pathname.includes('/create-invitation') && newInvitation?.isNavigationBlocked
          )}
        />
      </Portal>
      {(isCreateLoading || isUpdateLoading || isHostImagesLoading || isCoHostImagesLoading) && <Loader />}
    </Stack>
  );
};

export default FormButtons;
