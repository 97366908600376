import {styled} from '@mui/material/styles';
import {Button as MUIButton} from "@mui/material";

export const Button = styled(MUIButton)(() => ({
  height: 55,
  padding: 16,
  verticalAlign: 'baseline',
  borderRadius: '30px',
'&.MuiButton-containedPrimary': {
    background: '#C19A5B',
    color: '#fff',
    '&:hover': {
      background: 'rgb(135, 107, 63)',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    '&.Mui-disabled': {
      opacity: .3,
      color: '#fff'
    }
  },
  '&.MuiButton-containedSecondary': {
    background: '#FFFFFF',
    color: '#C19A5B !important',
    boxShadow: 'none',
    '&:hover': {
      color: '#fff !important',
      background: '#C19A5B',
    },
  },
  '&.MuiButton-outlinedPrimary': {
    borderColor: '#c19a5b',
    color: '#c19a5b !important',
    '&:hover': {
      background: 'rgba(193, 154, 91, 0.04)'
    }
  },
  '&.MuiButton-outlinedSecondary': {
    background: 'transparent',
    color: '#fff !important',
    borderColor: '#fff',
    '&:hover': {
      color: '#fff !important',
      background: 'rgba(255, 255, 255, 0.04)'
    },
  },
  '&.MuiButton-outlinedError': {
    color: '#d32f2f !important'
  },
  // '&.MuiButton-sizeLarge': {
  //   fontSize: '0.875rem',
  //   lineHeight: '24px'
  // },
  // '&.MuiButton-sizeMedium': {
  //   fontWeight: 700,
  //   height: 40,
  // },
  // '&.MuiButton-sizeSmall': {
  //   fontSize: '0.75rem',
  //   lineHeight: '1rem',
  //   fontWeight: 700,
  //   padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  //   '&.MuiButton-outlinedSizeSmall': {
  //     padding: 7
  //   },
  // },
  '&.MuiButton-containedSuccess': {
    background: '#589C0C',
    color: '#FFF',
    textTransform: 'uppercase',
  },
  '&.MuiButton-containedAction': {
    background: '#373632',
    color: '#FFF',
    textTransform: 'uppercase',
    '&.Mui-disabled': {
      opacity: .3,
    }
  },
}));
