import FormInput from '../../components/UI/FormInput';
import Label from '../../components/UI/Label';
import Select from '../../components/UI/Select';
import { MenuItem, Stack } from '@mui/material';
import SectorsSelect from '../../components/SectorsSelect';
import NationalitiesSelect from '../../components/NationalitiesSelect';
import DatePicker from '../../components/UI/DatePicker';
import LanguagesSelect from '../../components/LanguagesSelect';
import ActivitiesGroup from '../../components/ActivitiesGroup';
import { Button } from '../../components/UI/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateProfileMutation } from '../../services/mainApi';
import dayjs from 'dayjs';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { SET_USER } from '../../redux/types';
import ProfilePhotoTiles from './ProfilePhotoTiles';

const ProfileForm = ({onSuccess}) => {
  const {user} = useSelector(store => store.user);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const isDisabled = form?.gender === "" || form?.occupationSector === "" || form?.shortBio === "" ||
    form?.nationality === "" || form?.birthDate === "" || !form?.languages?.length ||
    !form?.interests?.length;
  const isChanged = JSON.stringify(form) === JSON.stringify({
    shortBio: user?.shortBio || '',
    gender: user?.gender || '',
    nationality: user?.nationality || '',
    birthDate: user?.birthDate || null,
    languages: user?.languages || [],
    interests: user?.interests || [],
    occupationSector: user?.occupationSector || '',
  });
  const dispatch = useDispatch();
  const [update] = useUpdateProfileMutation();

  useEffect(() => {
    if (user?.role) {
      setForm({
        shortBio: user?.shortBio || '',
        gender: user?.gender || '',
        nationality: user?.nationality || '',
        birthDate: user?.birthDate || null,
        languages: user?.languages || [],
        interests: user?.interests || [],
        occupationSector: user?.occupationSector || '',
      })
    }
  }, [user])

  const onChangeInput = ({target: {name, value}}) => {
    setErrors(state => ({...state, [name]: false}))
    if (name === 'shortBio' && value.length > 300) {
      setErrors(state => ({...state, [name]: 'Field should contain less than 300 characters'}))
      setForm(state => ({...state, [name]: value.slice(0, 300)}));
      return;
    }
    setForm(state => ({...state, [name]: value}));
  };

  const handleDateChange = (e) => {
    setForm(state => ({...state, birthDate: dayjs(e?.$d || null).format()}))
  };

  const onChangeActivity = (name) => (value) => {
    setForm(state => ({
      ...state,
      [name]: state[name].includes(value) ? state[name].filter(item => item !== value) : [...state[name], value]
    }));
  };

  const onUpdate = () => {
    update({id: user?.id, body: form}).then(res => {
      if (res.data) {
        dispatch({
          type: SET_USER,
          payload: res.data,
        });
        enqueueSnackbar(`You have successfully updated your profile`, {
          variant: 'info',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        onSuccess();
      }
    });
  };

  return (
    <Stack direction={'column'}>
      <ProfilePhotoTiles showActions sx={{maxWidth: '100%'}} />
      <FormInput
        label={'Short bio'}
        required={user?.role === 'guest'}
        fullWidth
        multiline
        rows={4}
        name={'shortBio'}
        value={form?.shortBio || ''}
        error={errors?.shortBio}
        onChange={onChangeInput}
      />
      <Label sx={{mb: 1}}>Gender</Label>
      <Select
        name={'gender'}
        value={form?.gender || ''}
        onChange={onChangeInput}
        error={errors?.gender}
        sx={{mb: 2}}
      >
        <MenuItem value={"male"}>Male</MenuItem>
        <MenuItem value={"female"}>Female</MenuItem>
      </Select>
      <Label sx={{mb: 1}}>Industry</Label>
      <SectorsSelect
        name={'occupationSector'}
        value={form?.occupationSector || ''}
        onChange={onChangeInput}
        error={errors?.occupationSector}
        sx={{mb: 2}}
      />
      <Label sx={{mb: 1}}>Nationality</Label>
      <NationalitiesSelect
        name={'nationality'}
        value={form?.nationality || []}
        onChange={onChangeInput}
        errors={errors?.nationality}
        sx={{mb: 2}}
      />
      <Label sx={{mb: 1}}>Date of Birth</Label>
      <DatePicker
        name={'birthDate'}
        value={form?.birthDate}
        onChange={handleDateChange}
        sx={{mb: 2}}
      />
      <Label sx={{mb: 1}}>Languages</Label>
      <LanguagesSelect
        name={'languages'}
        value={form?.languages || []}
        onChange={onChangeInput}
        error={errors?.languages}
        sx={{mb: 2}}
      />
      <ActivitiesGroup
        selectedActivities={form?.interests}
        label={'Interests'}
        required={user?.role === "host"}
        tooltipText={'Things you enjoy'}
        error={errors?.interests}
        onChange={onChangeActivity('interests')}
      />
      <Button
        variant={'contained'}
        sx={{'&.MuiButton-contained':{maxWidth: 330, width: '100%', mx: 'auto'}}}
        onClick={onUpdate}
        disabled={isDisabled || isChanged}
      >
        Update
      </Button>
    </Stack>
  )
};

export default ProfileForm;
