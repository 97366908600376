import { mainApi } from '../../services/mainApi';

export const AuthApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    checkValue: build.mutation({
      query: ({key, body}) => {
        return {
          url: `/auth/check-value/${key}`,
          method: 'POST',
          body
        }
      },
    }),
    applyWaitingList: build.mutation({
      query: (body) => {
        return {
          url: `/waiting-list`,
          method: 'POST',
          body
        }
      },
    }),
    checkWaitingToken: build.mutation({
      query: (body) => ({
        url: '/waiting-list/check-waiting-token',
        method: 'POST',
        body
      })
    }),
    register: build.mutation({
      query: (body) => ({
        url: '/auth/register',
        method: 'POST',
        body
      })
    }),
    login: build.mutation({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body
      })
    })

    /*conversations: build.query({
      query: ({params}) => ({url: `conversations`, params}),
      providesTags: [TAGS.CONVERSATIONS]
    }),
    createConversation: build.mutation({
      query: (body) => {
        console.log(body);
        return {
          url: 'conversations',
          method: 'POST',
          body: body
        }
      },
      invalidatesTags: [TAGS.CONVERSATIONS],
    }),
    conversation: build.query({
      query: (id) => ({url: `conversations/${id}`}),
      providesTags: [TAGS.CONVERSATION]
    }),
    deleteConversation: build.mutation({
      query: (id) => ({
        url: `conversations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.CONVERSATIONS, TAGS.CONVERSATION]
    }),
    sendMessage: build.mutation({
      query: ({id, body}) => {
        return {
          url: `conversations/send-message/${id}`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.CONVERSATIONS],
    }),*/

  }),
  overrideExisting: false,
});

export const {
  useCheckValueMutation,
  useApplyWaitingListMutation,
  useCheckWaitingTokenMutation,
  useRegisterMutation,
  useLoginMutation
} = AuthApi;
