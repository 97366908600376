import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/actions/userActions';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import PageWrapper from '../../components/PageWrapper';
import ActivityItem from '../../components/ActivityItem';
import ActivityWrapper from '../../components/ActivityWrapper';
import ModalTitle from '../../components/UI/DialogTitle';
import { Stack, Typography, Grid, Tooltip, DialogContent, Dialog, Box } from '@mui/material';
import { Button } from '../../components/UI/Button';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import EditProfile from './EditProfile';
import ProfilePhotoTiles from './ProfilePhotoTiles';

const Profile = () => {
  const {user} = useSelector(store => store.user);
  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <PageWrapper>
      <Stack direction={'column'} justifyContent={'space-between'} sx={{flexGrow: 1}}>
        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} alignItems={{xs: 'flex-start', sm: 'center'}} justifyContent={'space-between'} sx={{mb: 3}}>
          <Typography sx={{fontWeight: 500}}>Profile</Typography>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{width: {xs: '100%', sm: 'auto'}}}>
            {user?.role !== 'admin' && (
              <Button
                sx={{height: 32, width: {xs: '100%', sm: 'auto'}}}
                component={Link}
                to={'/profile-referrals'}
                variant={'outlined'}
              >
                REFERRAL CODES
              </Button>
            )}
            <Button
              onClick={() => setOpenEditModal(true)}
              variant={'outlined'}
              sx={{height: 32}}
              startIcon={<EditIcon sx={{color: '#C19A5B'}} />}
            >
              EDIT PROFILE
            </Button>
          </Stack>
        </Stack>
        {user && (
          <Box sx={{maxWidth: 500, margin: '0 auto'}}>
            <Stack direction={'column'} alignItems={'center'} sx={{ mx: 'auto'}}>
              <Typography sx={{fontSize: "16px", color: "#373632", fontWeight: "600", mb: 1.2}}>{user?.username}</Typography>
              <Stack direction={'row'} alignItems={'center'} sx={{mb: 1.2}}>
                <Typography sx={{fontSize: "14px", color: "#696969"}}>{user?.email}</Typography>
              </Stack>
              <Typography
                sx={{
                  maxWidth: 250,
                  width: '100%',
                  fontWeight: 'normal',
                  fontSize: 16,
                  textAlign: "center",
                  lineHeight: "24px",
                  mb: 3,
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
              >
                {user?.shortBio}
              </Typography>
              {user?.role !== 'admin' && (
                <>
                  <Box sx={{
                    borderRadius: '16px',
                    background: '#F8F8F8',
                    backdropFilter: 'blur(2px)',
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <ProfilePhotoTiles />
                  </Box>
                  <Stack direction={'row'} alignItems={'center'} sx={{my: 1.5}}>
                    <PublicIcon sx={{color: "#696969", fontSize: 18, mr: 0.5}} />
                    <Typography sx={{fontSize: 14, color: "#696969"}}>
                      The information below will be included in applications.
                    </Typography>
                  </Stack>
                  <Grid container spacing={1}>
                    {Boolean(user?.gender) && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Gender</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.gender}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.nationality)&& (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Nationality</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.nationality}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.birthDate)&& (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Date of birth</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{fontWeight: 300}}>{dayjs(user?.birthDate).format("DD/MM/YYYY")}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.languages) && Boolean(user?.languages?.length) && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Languages</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Tooltip
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: '#373632',
                                  color: '#C19A5B',
                                  padding: 1,
                                  fontSize: 14,
                                  fontWeight: 300,
                                  '& .MuiTooltip-arrow': {
                                    color: '#373632',
                                  },
                                },
                              },
                            }}
                            title={user?.languages?.join(', ')} placement="top" arrow sx={{fontSize: 100}}>
                            <Typography
                              sx={{
                                cursor: 'pointer',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                fontWeight: 300
                              }}
                            >
                              {user?.languages?.join(', ')}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.occupationSector)&& (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Industry</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.occupationSector}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.interests) && Boolean(user?.interests?.length) && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Interests</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <ActivityWrapper sx={{justifyContent: 'flex-end'}}>
                            {user?.interests?.map(interest => <ActivityItem value={interest} key={interest} />)}
                          </ActivityWrapper>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Stack>
          </Box>
        )}
        <Stack direction={'row'} justifyContent={'flex-end'} sx={{pt: 2}}>
          <Button
            variant={'outlined'}
            sx={{height: 32, '&.MuiButton-outlined': {border: 'none', px: 0}}}
            startIcon={<LogoutIcon sx={{color: '#C19A5B'}} />}
            onClick={() => setOpenLogOutModal(true)}
          >
            log out
          </Button>
        </Stack>
      </Stack>
      <LogOutModal open={openLogOutModal} onClose={() => setOpenLogOutModal(false)} />
      <EditProfile open={openEditModal} onClose={() => setOpenEditModal(false)} />
    </PageWrapper>
  );
};

export default Profile;

export const LogOutModal = ({open, onClose}) => {
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 500,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>Do you want to log out?</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => dispatch(logoutUser())}
        >
          Yes
        </Button>
        <Button
          fullWidth
          sx={{mt: 2}}
          variant="outlined"
          color="error"
          onClick={onClose}
        >
          No
        </Button>
      </DialogContent>
    </Dialog>
  );
};
