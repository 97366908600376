import { useDispatch, useSelector } from 'react-redux';
import { Icon, MenuItem, Typography } from '@mui/material';
import Label from '../../../../components/UI/Label';
import React, { useState } from 'react';
import FormWrapper from '../FormWrapper';
import FormButtons from '../FormButtons';
import Input from '../../../../components/UI/Input';
import { updateDraft } from '../../slice';
import Select from '../../../../components/UI/Select';
import dayjs from 'dayjs';
import DatePicker from '../../../../components/UI/DatePicker';
import previewIconFile from '../../../../assets/create_inv/preview.svg';
import { Button } from '../../../../components/UI/Button';
import PreviewInvitationModal from '../../PreviewInvitationModal';

const CoHostStepTwo = () => {
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const isDisabled = !newInvitation?.draft?.numberOfCoHosts || !newInvitation?.draft?.genderOfCoHosts ||
    !newInvitation?.draft?.deadlineOfApplications;
  const [open, setOpen] = useState(false);

  const handleChange = ({target: {name, value, type}}) => {
    if (type === 'number') {
      dispatch(updateDraft({key: name, value: Math.abs(+value || 1)}));
      return;
    }
    dispatch(updateDraft({key: name, value}));
  };

  const onDateChange = (e) => {
    dispatch(updateDraft({key: 'deadlineOfApplications', value: dayjs(e?.$d || null).format()}))
  };

  const handlePreviewModal = () => {
    setOpen(true);
  };

  return (
    <>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation for Hosts</Typography>
        <Label>Number of CoHosts</Label>
        <Input
          name={'numberOfCoHosts'}
          inputProps={{inputMode: 'numeric', min: 1}}
          type="number"
          value={newInvitation?.draft?.numberOfCoHosts || ''}
          onChange={handleChange}
          sx={{mb: 4}}
        />
        <Label>Gender of CoHosts</Label>
        <Select
          name="genderOfCoHosts"
          value={newInvitation?.draft?.genderOfCoHosts || ''}
          onChange={handleChange}
          sx={{mb: 4}}
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"both"} disabled={newInvitation?.draft?.numberOfCoHosts === 1}>Both</MenuItem>
        </Select>
        <Label>Deadline For Application</Label>
        <DatePicker
          value={newInvitation?.draft?.deadlineOfApplications || null}
          maxDate={dayjs(newInvitation?.draft?.startDate).add(-1, "day")}
          minDate={dayjs().add(1, "day")}
          onChange={onDateChange}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePreviewModal}
          sx={{py: 1, mt: 3}}
          startIcon={
            <Icon sx={{width: 'auto', height: 'auto'}}>
              <img src={previewIconFile} alt={'preview'} />
            </Icon>
          }
        >
          PREVIEW INVITATION
        </Button>
      </FormWrapper>
      <PreviewInvitationModal
        open={open}
        onClose={() => setOpen(false)}
        invitation={{...newInvitation?.draft, travelPackage: newInvitation?.activeTravelPackage}}
      />
      <FormButtons showPrev showCreate isCreateDisabled={isDisabled} />
    </>
  )
};

export default CoHostStepTwo;
