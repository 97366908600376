import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

const CustomSwitch = styled(Switch)(() => ({
  padding: 7,
  width: 86,
  height: 48,
  '& .MuiSwitch-switchBase': {
    paddingTop: 11,
    '&.Mui-checked': {
      transform: 'translateX(100%)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff'
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#C19A5B',
        opacity: 1,
      }
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#ccc'
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 4,
    '&:before, &:after': {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 25,
      height: 26,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M7.771,13.873,4.493,10.6,3.4,11.688l4.371,4.371,9.366-9.366L16.044,5.6Z"/></svg>')`,
      left: 14,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M16.143,6.122,15.02,5,10.571,9.449,6.122,5,5,6.122l4.449,4.449L5,15.02l1.122,1.122,4.449-4.449,4.449,4.449,1.122-1.122-4.449-4.449Z" /></svg>')`,
      right: 9,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: "none",
    width: 25,
    height: 26,
    borderRadius: 4,
  },
}));

export default CustomSwitch;
