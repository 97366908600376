import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const VerticalButtonGroup = styled(ToggleButtonGroup)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
  '& .MuiToggleButtonGroup-grouped': {
    width: '100%',
    border: '1px solid #C19A5B',
    borderRadius: '4px',
    marginLeft: 0,
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    '&.Mui-selected': {
      backgroundColor: '#F9F5EF',
      border: '1px solid #C19A5B',
      boxShadow: 'inset 0px 0px 0px 1px rgba(193,154,91,1)',
      marginLeft: 0,
      '& + .MuiToggleButtonGroup-grouped.Mui-selected': {
        borderLeft: '1px solid #C19A5B',
      }
    },
    '&:not(:first-of-type)': {
      borderLeft: '1px solid #C19A5B',
      marginLeft: 0,
    },
    '&:first-of-type': {
      marginLeft: 0,
    },
  }
}));

export default VerticalButtonGroup;
