import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules//navigation/navigation.min.css";
import "swiper/modules//thumbs/thumbs.min.css";
import { Navigation, Thumbs } from "swiper";
import { Box } from '@mui/material';


const ThumbSlider = ({images}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <Box sx={{
      '& .swiper': {
        width: '100%',
        '& .swiper-button-prev, & .swiper-button-next': {
          color: '#fff',
          '&:after': {
            fontSize: 20
          }
        },
        '&:not(.swiper-thumbs)': {
          height: {xs: 200, sm: 345},
          '& .swiper-wrapper': {
            height: '100%',
            '& .swiper-slide': {
              height: '100%',
              '& > img': {
                objectFit: 'contain',
              }
            }
          }
        },
        '&.swiper-thumbs': {
          marginTop: '10px',
          '& .swiper-slide': {
            aspectRatio: '1',
            cursor: 'pointer',
            '& img': {
              filter: 'grayscale(80%)',
            },
            '&-thumb-active': {
              '& img': {
                filter: 'grayscale(0%)',
              },
            }
          }
        },
        '& .swiper-slide': {
          '& img': {
            width: '100%',
            height: '100%'
          }
        }
      }
    }}>
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Navigation, Thumbs]}
      >
        {images?.map((image, i) => (
          <SwiperSlide key={`slide-${i}`}>
            <img src={image} alt={`slide-${i}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={5}
        watchSlidesProgress={true}
        modules={[Navigation, Thumbs]}
      >
        {images?.map((image, i) => (
          <SwiperSlide key={`slide-thumb-${i}`}>
            <img src={image} alt={`slide-thumb-${i}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ThumbSlider;
