import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Stack, CircularProgress, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ProgressBar = () => {
  const {newInvitation} = useSelector(store => store);
  const history = useHistory();
  const lastStep = 2;
  const progressValue = (100 / lastStep) * newInvitation.step;

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          width: '100%',
        }}
      >
        <Stack direction={'row'} alignItems={'center'} sx={{position: 'relative'}}>
          <CircularProgress
            sx={{
              position: 'absolute',
              left: 0,
              '& .MuiCircularProgress-circle': {
                color: '#F9F5EF'
              }
            }}
            thickness={8}
            size={50}
            variant="determinate"
            value={100}
          />
          <CircularProgress
            sx={{
              '& .MuiCircularProgress-circle': {
                color: '#C19A5B',
              }
            }}
            thickness={8}
            size={50}
            variant="determinate"
            value={progressValue}
          />
          <Typography sx={{ml: 2, color: '#C19A5B', fontSize: 14, fontWeight: 700, textTransform: 'uppercase'}}>
            Step {newInvitation.step} of {lastStep}
          </Typography>
        </Stack>
        <IconButton onClick={() => history.goBack()} sx={{'& > svg': {color: '#ccc', fontSize: 32}}}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </>
  );
};

export default ProgressBar;
