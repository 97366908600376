import React, { Component } from "react";
import store from "./../redux/store";
import LoginModal from "../components/LoginModal";
import RegisterModal from "../components/RegisterModal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { validateToken } from "../redux/actions/userActions";
import ForgotPasswordModal from "../components/ForgotPasswordModal";
import ResetPasswordModal from "../components/ResetPasswordModal";
import { SET_UNVALIDATE_TOKEN } from '../redux/types';
import { withStyles } from "@material-ui/styles";
import { Container, Dialog, Stack, Typography } from '@mui/material';
import StaticPageWrapper from '../components/StaticPagesWrapper';
import { Button } from '../components/UI/Button';
import { withRouter } from 'react-router-dom';

const styles = {
  homeWrapper: {
    position: "relative",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "0px",
    outline: "unset !important",
    "& .MuiBackdrop-root": {
      backgroundColor: "#b0b0b04d",
    },
    "& div": {
      outline: "unset !important",
    },
  },

  registerPaper: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    outline: "unset !important",
    "& div": {
      outline: "unset !important",
    },
  },
  loginPaper: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    outline: "unset !important",
    maxWidth: "600px",
    width: "100%",
    '@media (max-width: 640px)': {
      minHeight: '100vh',
      maxWidth: '100%'
    }
  },
  forgotPasswordPaper: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    outline: "unset !important",
    maxWidth: "610px",
    width: "100%",
  },
  locationText: {
    color: "#C19A5B",
    textShadow: "-2px 2px 5px #0000008c",
    transition: "all 0.2s ease-in-out",
  },
  hidden: {
    opacity: 0,
  },
};
class Home extends Component {
  constructor() {
    super();
    this.state = {
      openRegisterModal: false,
      openLoginModal: false,
      openForgotPasswordModal: false,
      showButtons: false,
      slideText: "",
      changingSlide: false,
      openResetPasswordModal: false,
      token: "",
    };
  }
  componentDidMount() {
    if (this.props.match.path === "/login") {
      this.setState({ openLoginModal: true });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      this.setState({ token: token }, () => {
        this.props.validateToken({ token: token });
      });
    }
  }
  handleLoginOpen = () => {
    this.handleRegisterClose();
    this.handleResetPasswordClose();
    this.setState({ openLoginModal: true });
  };

  handleLoginClose = () => {
    this.setState({ openLoginModal: false });
  };
  handleRegisterOpen = () => {
    this.props.history.push('/join');
  };

  handleRegisterClose = () => {
    this.setState({ openRegisterModal: false });
  };
  handleForgotPasswordOpen = () => {
    this.handleLoginClose();
    this.setState({ openForgotPasswordModal: true });
  };

  handleForgotPasswordClose = () => {
    this.setState({ openForgotPasswordModal: false });
  };

  handleResetPasswordClose = () => {
    store.dispatch({ type: SET_UNVALIDATE_TOKEN });
  };

  render() {
    const {
      user: { authenticated },
    } = this.props;

    return (
      <StaticPageWrapper sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh', p: 0}}>
        <Container maxWidth={'xl'} sx={{maxWidth: 1320, position: 'relative', display: 'flex', flexGrow: 1}}>
          <div className="home-inner home">
            <Typography align={'center'} sx={{fontSize: {xs: 24, sm: 28}}}>Invite and be invited</Typography>
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              justifyContent={'center'}
              spacing={{xs: 2, sm: 6}}
              sx={{mt: 4}}
              style={!authenticated ? {} : { display: "none" }}
            >
              <Button
                variant="contained"
                color="action"
                sx={{height: 50, width: {xs: '100%', md: 300}}}
                onClick={this.handleRegisterOpen}
              >
                Apply for membership
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{height: 50, width: {xs: '100%', md: 300}}}
                onClick={this.handleLoginOpen}
              >
                LOGIN
              </Button>
            </Stack>
            <Dialog
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: {xs: '100%', sm: 600},
                  width: '100%',
                  height: {xs: '100vh', sm: 'auto'},
                  maxHeight: {xs: '100%', sm: 'auto'},
                  m: {xs: 0, sm: 1}
                },
                "& .MuiBackdrop-root": {
                  backgroundColor: "#b0b0b04d",
                },
              }}
              open={this.state.openLoginModal}
              onClose={this.handleLoginClose}
            >
              <LoginModal
                handleRegisterOpen={this.handleRegisterOpen}
                handleLoginClose={this.handleLoginClose}
                handleForgotPasswordOpen={this.handleForgotPasswordOpen}
              />
            </Dialog>
            <Dialog
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: 600,
                  width: '100%',
                },
                "& .MuiBackdrop-root": {
                  backgroundColor: "#b0b0b04d",
                },
              }}
              open={this.state.openRegisterModal}
              onClose={this.handleRegisterClose}
            >
              <RegisterModal
                handleLoginOpen={this.handleLoginOpen}
                handleRegisterClose={this.handleRegisterClose}
              />
            </Dialog>
            <Dialog
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: 600,
                  width: '100%',
                },
                "& .MuiBackdrop-root": {
                  backgroundColor: "#b0b0b04d",
                },
              }}
              open={this.state.openForgotPasswordModal}
              onClose={this.handleForgotPasswordClose}
            >
              <ForgotPasswordModal
                handleForgotPasswordClose={this.handleForgotPasswordClose}
              />
            </Dialog>
            <Dialog
              sx={{
                '& .MuiPaper-root': {
                  maxWidth: 600,
                  width: '100%',
                },
                "& .MuiBackdrop-root": {
                  backgroundColor: "#b0b0b04d",
                },
              }}
              open={this.props.user.tokenValidated}
              onClose={this.handleResetPasswordClose}
            >
              <ResetPasswordModal
                handleResetPasswordClose={this.handleResetPasswordClose}
                handleLoginOpen={this.handleLoginOpen}
                token={this.state.token}
              />
            </Dialog>
          </div>
        </Container>
      </StaticPageWrapper>
    );
  }
}

Home.propTypes = {
  validateToken: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapActionsToProps = { validateToken };
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withRouter(Home)));
