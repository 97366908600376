import React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { Typography } from '@mui/material';


const CustomTimelineItem = ({title, description, children}) => {
  return (
    <TimelineItem sx={{
      '& .MuiTimelineDot-root': {
        margin: 0,
        backgroundColor: '#F9F5EF',
        boxShadow: 'unset'
      }
    }}>
      <TimelineSeparator>
        <TimelineDot/>
        <TimelineConnector sx={{backgroundColor: '#F9F5EF', boxShadow: 'unset'}}/>
      </TimelineSeparator>
      <TimelineContent sx={{lineHeight: '1', padding: '0px 12px 50px 12px', width: '100%'}}>
        <Typography sx={{
          color: "#C19A5B",
          fontSize: 16,
          fontWeight: 500,
          mb: 1,
          lineHeight: '1',
          wordBreak: 'break-word',
        }}>
          {title}
        </Typography>
        {description && (
          <Typography sx={{
            color: "#373632",
            fontSize: 16,
            letterSpacing: "0.24px",
            fontWeight: 400,
            wordBreak: 'break-word',
          }}
          >
            {description}
          </Typography>
        )}
        {children}
      </TimelineContent>
    </TimelineItem>
  );
};

export default CustomTimelineItem;
