import { Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NotificationInner = ({title, description, invitationId}) => {
  switch(title) {
    case 'New Application':
      return (
        <Link
          underline={'none'}
          sx={{color: '#373632'}}
          component={RouterLink}
          to={`applications/${invitationId}`}
          >
          {description}
        </Link>
      )
    case 'New Linked Application':
      return (
        <Link
          underline={'none'}
          sx={{color: '#373632'}}
          component={RouterLink}
          to={`applications/${invitationId}`}
        >
          {description}
        </Link>
      )
    case 'Application status changed':
     return (
       <Link
         underline={'none'}
         sx={{color: '#373632'}}
         component={RouterLink}
         to={'my-applications'}
       >
         {description}
       </Link>
     )
    case 'New invite for linked application':
      return (
        <Link
          underline={'none'}
          sx={{color: '#373632'}}
          component={RouterLink}
          to={`/invitation/${invitationId}/apply`}
        >
          {description}
        </Link>
      )
    default:
      return <Typography>{description}</Typography>
  }
};

export default NotificationInner;
