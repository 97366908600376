import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHostInvitationsQuery } from './api';
import { Box, Grid, Typography } from '@mui/material';
import InvitationCard from '../../components/InvitationCard';
import PageWrapper from '../../components/PageWrapper';
import InvitationsFilters from './InvitationsFilters';

const Invitations = () => {
  const {user} = useSelector(store => store);
  const [params, setParams] = useState({page: 1, limit: 25});
  const {data = {}, isLoading} = useHostInvitationsQuery({id: user?.user?.id, params}, {skip: !user?.user?.id});

  const handleFiltersClick = (name, value) => {
    if (!value.length) {
      const {[name]: unUsed, ...newParams} = params;
      setParams(newParams);
    } else {
      setParams(params => ({...params, [name]: value}));
    }
  };

  const handleClearFilters = () => {
    setParams({page: 1, limit: 25});
  };

  return (
    <PageWrapper>
      <Box>
        <Typography sx={{fontWeight: 500, mb: 3}}>Invitations</Typography>
      </Box>
      <InvitationsFilters
        itemsCount={data?.data?.length || 0}
        handleClear={handleClearFilters}
        handleFilter={handleFiltersClick}
      />
      <Box
        sx={{
          width: '100%',
          mt: 3,
          mb: {xs: 15, sm: 12, md: 6},
          pl: {sm: 2.5, md: 0},
        }}
      >
        {data?.data?.length > 0 && !isLoading && (
          <Grid container spacing={3}>
            {data?.data?.map((invitation) => (
              <Grid item xs={12} sm={6} md={4} xl={3} key={invitation.id}>
                <InvitationCard invitation={invitation} showStatus showApplications />
              </Grid>
            ))}
          </Grid>
        )}
        {!data?.data?.length && !isLoading && (
          <Typography sx={{fontSize: 16}}>No Invitations Found</Typography>
        )}
        {isLoading && (<Typography sx={{fontSize: 16}}>...Loading</Typography>)}
      </Box>
    </PageWrapper>
  );
};

export default Invitations;
