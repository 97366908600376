import { Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import ModalTitle from '../../components/UI/DialogTitle';
import Application from './application';

const PreviewApplication = ({open, onClose, application}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 685,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>PREVIEW APPLICATION</Typography>
      </ModalTitle>
      <DialogContent sx={{p: 3, mt: 3}}>
        <Application application={application} />
      </DialogContent>
    </Dialog>
  );
};

export default PreviewApplication;
