import FormWrapper from './FormWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { setError, updateDraft } from '../slice';
import dayjs from 'dayjs';
import { Icon, InputAdornment, MenuItem, Typography } from '@mui/material';
import Label from '../../../components/UI/Label';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import DatePicker from '../../../components/UI/DatePicker';
import Input from '../../../components/UI/Input';
import React, { useState } from 'react';
import Select from '../../../components/UI/Select';
import FormButtons from './FormButtons';
import { Button } from '../../../components/UI/Button';
import previewIconFile from '../../../assets/create_inv/preview.svg';
import PreviewInvitationModal from '../PreviewInvitationModal';

const EventCoHostInvitationForm = () => {
  const {newInvitation} = useSelector(store => store);
  const dispatch = useDispatch();
  const isDisabled = !newInvitation?.draft?.locationFrom || !newInvitation?.draft?.startDate ||
    !newInvitation?.draft?.additionalInfo || !newInvitation?.draft?.totalRooms || !newInvitation?.draft?.roomsTaken ||
    !newInvitation?.draft?.totalCost || !newInvitation?.draft?.numberOfCoHosts || !newInvitation?.draft?.genderOfCoHosts ||
    !newInvitation?.draft?.deadlineOfApplications;
  const [open, setOpen] = useState(false);


  const handleDateChange = (e) => (key) => {
    dispatch(updateDraft({key, value: dayjs(e?.$d || null).format()}));
  };

  const handleChange = ({target: {name, value, type}}) => {
    if (type === 'number') {
      if (name === 'roomsTaken' && +newInvitation?.draft?.totalRooms < +value) {
        dispatch(updateDraft({key: name, value: value < 0 ? Math.abs(value) : newInvitation?.draft?.totalRooms}));
        return;
      }
      if (name === 'totalRooms' && +newInvitation?.draft?.roomsTaken > +value) {
        dispatch(updateDraft({key: 'roomsTaken', value: value < 0 ? 0 : value}));
      }
      dispatch(updateDraft({key: name, value: value < 0 ? Math.abs(value) : value}));
      return;
    }
    dispatch(updateDraft({key: name, value}));
  };

  const handleLocation = (key) => (value) => {
    dispatch(setError({key, value: null}))
    dispatch(updateDraft({key, value}));
    if (!value?.city || !value?.country) {
      dispatch(setError({key, value: 'Please provide city and country'}))
    }
  };

  return (
    <>
      <FormWrapper>
        <Typography sx={{fontSize: 20, fontWeight: 600, mb: 2}}>Create Invitation for Hosts</Typography>
        <Typography sx={{fontSize: 15, fontWeight: 600, mb: 2}}>EVENT INFORMATION</Typography>
        <Label>Event name</Label>
        <Input
          name="name"
          placeholder=""
          multiline
          maxRows={5}
          value={newInvitation?.draft?.name}
          onChange={handleChange}
          sx={{mb: 3}}
        />
        <Label>Location</Label>
        <GoogleAutocomplete
          value={newInvitation?.draft?.locationFrom || null}
          handleChange={handleLocation('locationFrom')}
          error={newInvitation?.errors?.locationFrom}
        />
        <Label>Date</Label>
        <DatePicker
          name={'startDate'}
          value={newInvitation?.draft?.startDate || null}
          onChange={(e) => handleDateChange(e)('startDate')}
          minDate={dayjs().add(1, "day")}
          maxDate={
            newInvitation?.draft?.endDate
              ? dayjs(newInvitation?.draft?.endDate).add(-1, "day")
              : dayjs().add(1, "year")
          }
          sx={{mb: 3}}
        />
        <Label>Event information</Label>
        <Input
          name="additionalInfo"
          placeholder="Fill in"
          multiline
          maxRows={5}
          value={newInvitation?.draft?.additionalInfo}
          onChange={handleChange}
          sx={{mb: 3}}
        />
        <Label>Max Capacity</Label>
        <Input
          name={'totalRooms'}
          type={'number'}
          value={newInvitation?.draft?.totalRooms || ''}
          inputProps={{inputMode: 'numeric', min: 1}}
          onChange={handleChange}
          sx={{mb: 3}}
        />
        <Label>Available Capacity</Label>
        <Input
          name={'roomsTaken'}
          type={'number'}
          inputProps={{inputMode: 'numeric', min: 0, max: newInvitation?.draft?.totalRooms}}
          value={newInvitation?.draft?.roomsTaken || ''}
          onChange={handleChange}
          disabled={!newInvitation?.draft?.totalRooms}
          sx={{mb: 3}}
        />
        <Label>Event Cost</Label>
        <Input
          name={'totalCost'}
          value={newInvitation?.draft?.totalCost || ''}
          type={'number'}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={handleChange}
          sx={{mb: 3}}
        />
        <Label>Number of CoHosts</Label>
        <Input
          name={'numberOfCoHosts'}
          inputProps={{inputMode: 'numeric', min: 1}}
          type="number"
          value={newInvitation?.draft?.numberOfCoHosts || ''}
          onChange={handleChange}
          sx={{mb: 4}}
        />
        <Label>Gender of CoHosts</Label>
        <Select
          name="genderOfCoHosts"
          value={newInvitation?.draft?.genderOfCoHosts || ''}
          onChange={handleChange}
          sx={{mb: 4}}
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"both"} disabled={newInvitation?.draft?.numberOfCoHosts === 1}>Both</MenuItem>
        </Select>
        <Label>Deadline For Application</Label>
        <DatePicker
          value={newInvitation?.draft?.deadlineOfApplications || null}
          maxDate={dayjs(newInvitation?.draft?.startDate).add(-1, "day")}
          minDate={dayjs().add(1, "day")}
          onChange={(e) => handleDateChange(e)('deadlineOfApplications')}
          sx={{mb: 3}}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          sx={{py: 1}}
          startIcon={
            <Icon sx={{width: 'auto', height: 'auto'}}>
              <img src={previewIconFile} alt={'preview'} />
            </Icon>
          }
        >
          PREVIEW INVITATION
        </Button>
      </FormWrapper>
      <FormButtons showPrev showCreate isCreateDisabled={isDisabled} />
      <PreviewInvitationModal
        open={open}
        onClose={() => setOpen(false)}
        invitation={{...newInvitation?.draft}}
      />
    </>
  );
};

export default EventCoHostInvitationForm;
