import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import { connect } from "react-redux";
import { forgotPassword } from "../redux/actions/userActions.js";
import { Link } from 'react-router-dom';
import Input from './UI/Input';
import { Button } from './UI/Button';

const styles = theme => ({
  header: {
    textAlign: "center",
    backgroundColor: "#F9F5EF",
    borderBottom: "1px solid #8F8F8F",
    "& span": {
      fontSize: "15px",
      fontWeight: 600,
    },
    "& .MuiCardHeader-action": {
      alignSelf: "center !important",
      marginTop: "0px !important",
      marginRight: "0px !important",
    },
    "& button": {
      padding: "0px",
      "& svg": {
        fill: "#8F8F8F",
      },
    },
  },
  card: {
    borderRadius: "10px !important",
    boxShadow: "unset !important",
  },
  label: {
    color: "#373632 !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  body: {
    padding: "25px 130px",
    margin: "auto",
    "@media(max-width:550px)": {
      padding: "25px 15px",
    },
  },
  successBody: {
    padding: "25px 80px",
    margin: "auto",
  },
  successMessage: {
    display: "block",
    textAlign: "center",
  },
  button: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "14px 0px",
    width: "100%",
    fontSize: "14px !important",
    lineHeight: "18px !important",
  },
  successButton: {
    display: "block",
    margin: "20px auto 0px auto !important",
    padding: "14px 0px",
    width: "50%",
    fontSize: "14px !important",
    lineHeight: "18px !important",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    marginBottom: "30px",
    "& .MuiInputBase-root": {
      border: "1px solid #C19A5B",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "15px",
      color: "#F44336",
    },
    "& input": {
      padding: "15px 20px",
      "&::placeholder": {
        color: "#9b9b9b",
        fontSize: "14px",
        opacity: 1,
      },
    },
  },

  loginModalLinkSpan: {
    fontSize: "16px",
  },
  loginModalLink: {
    fontSize: "16px",
    color: "#C19A5B",
    textDecoration: "underline",
    cursor: "pointer",
  },

  customError: {
    fontSize: "15px",
    color: "#F44336",
    marginBottom: "20px",
  },
});

class ForgotPasswordModal extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: { emailEr: "" },
      forgotPasswordEmailSent: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.email !== "" &&
      this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
    ) {
      const userData = {
        email: this.state.email,
      };
      this.props.forgotPassword(userData);
    } else {
      if (this.state.email === "") {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            emailEr: "Email cannot be empty",
          },
        }));
      } else if (
        !this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) //eslint-disable-line
      ) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            emailEr: "Enter a valid email address",
          },
        }));
      }
    }
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;
    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton
              aria-label="Close"
              onClick={this.props.handleForgotPasswordClose}
            >
              <CloseIcon />
            </IconButton>
          }
          title="RECOVER YOUR PASSWORD"
        />
        {!this.props.user.forgotPasswordEmailSent ? (
          <CardContent className={classes.body}>
            <form
              className={classes.loginForm}
              noValidate
              onSubmit={this.handleSubmit}
            >
              <InputLabel className={classes.label} htmlFor="email">
                Email
              </InputLabel>
              <Input
                id="email"
                name="email"
                type="text"
                placeholder="Enter Email"
                value={this.state.email}
                onChange={this.handleChange}
                helperText={errors.emailEr || errors.error}
                error={!!(errors.emailEr || errors.error)}
                fullWidth
                style={{marginBottom: '30px'}}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{mb: 3}}
                disabled={loading}
              >
                EMAIL ME A NEW PASSWORD
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>{" "}
            </form>
            <span className={classes.loginModalLinkSpan}>
              Problem with your account?{" "}
              <Link className={classes.loginModalLink} to="/contact">
                Contact us
              </Link>
            </span>
          </CardContent>
        ) : (
          <CardContent className={classes.successBody}>
            <span className={classes.successMessage}>
              We have received your request.
              <br /> Please, check your inbox to find your new password.
            </span>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.successButton}
              onClick={this.props.handleForgotPasswordClose}
            >
              OK
            </Button>
          </CardContent>
        )}
      </Card>
    );
  }
}

ForgotPasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionToProps = {
  forgotPassword,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles)(ForgotPasswordModal));
