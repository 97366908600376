import React from "react";
import { withStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from 'react-router-dom';

const styles = {
  accordion: {
    boxShadow: "unset !important",
    borderTop: "1px solid #C19A5B",
    borderRadius: "0px !important",
    padding: "0px 0px 0px 0px !important",
    "&.Mui-expanded": {
      margin: "0px 0px !important",
    },
    "&::before": {
      content: "none !important",
    },
    "&:last-child": {
      borderBottom: "1px solid #C19A5B",
    },
    "& .MuiAccordionSummary-expandIconWrapper svg": {
      fill: "#C19A5B",
    },
  },
  accordionHeader: {
    paddingLeft: "0px !important",
    "&:not(.Mui-expanded)": {
      margin: "25px 0px !important",
    },

    "& > .MuiAccordionSummary-content": {
      margin: "0px 0px !important",
    },
    "& p ": {
      color: "#C19A5B",
      fontSize: "17px",
      lineHeight: "24px",
      fontWeight: "600",
      textAlign: 'justify',
    },
  },
  accordionBody: {
    "& p, & .MuiBox-root ": {
      color: "#373632",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "normal",
      textAlign: 'justify',
      "& a": {
        color: "#C19A5B !important",
        textDecoration: "none !important",
      },
    },
  },
};
const AccordionCompPrivacy = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { classes } = props;

  return (
    <div>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel1" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            1. Introduction
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            The protection of personal data is important for us.
          </Typography>
          <br />
          <Typography>
            This website (https://www.invite.club/) is operated by the company
            under the corporate name “InviteCo Ltd” located at Nicosia, Cyprus
            (referred to as the “Company” or “us” or “we”) to offer to our
            website visitors (“you”, “your” or “users”). This Privacy Policy
            (the “Privacy Policy”) explains how the Company, collects, stores,
            uses, processes and in general handles personal data when you visit,
            use or get registered to its website or apps (hereinafter the “Site”
            or “Invite.Club” or “Apps”) and/or using the Site and the services,
            features, content or applications offered by it (the “Services”).
            This Policy is designed to assist you in making informed decisions
            when using our website or interacting with us.
          </Typography>
          <br />
          <Typography>
            Individuals are recommended to read carefully this Policy before
            disclosing any personal data and/or filling in any electronic form
            posted on this Site. By visiting our website or by providing us your
            personal data, you agree and consent to the collection, use and
            disclosure of your personal information as outlined in this Policy.
          </Typography>
          <br />
          <Typography>
            “Personal Data” means any information or set of information that
            relates to an individual such as name, address, email, telephone
            number etc. which can be either direct or indirect and can be
            collected by or on behalf of the Company.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel2" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            2. A few words about the Company and the Site
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            Invite.Club is designed to bring like-minded people together. This
            website offers its users the opportunity to organize and participate
            in trips in the world’s most trendy destinations.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel3" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            3. Personal Data We Collect
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            It goes without saying, we can’t help you develop meaningful
            connections without some information about you, such as basic
            profile details and basic info about yourself. We also collect
            information about your use of our services such as access logs, as
            well as information from third parties, like when you access our
            services through your social media account or when you upload
            information from your social media account to complete your profile.
            If you want additional info, we go into more detail below:
          </Typography>
          <br />
          <Typography>
            <strong>Information you give us</strong>
          </Typography>
          <br />
          <Typography>
            You choose to give us certain information when using our Services.
            This includes:
          </Typography>
          <br />
          <Typography>
            When you create an account, you provide us with your email address.
            If you are not referred by another existing member you provide us
            with some basic details to get to know more about you such as your
            name, surname, date of birth, gender, sector, short bio and at least
            one social media account of yours.
          </Typography>
          <br />
          <Typography>
            When you complete your profile, you can share with us additional
            information, such as details on your bio, interests, sector, gender
            and other details about you, as well as content such as photos and
            videos.
          </Typography>
          <br />
          <Typography>
            When you participate in surveys, focus groups or market studies, you
            give us your insights into our products and services, responses to
            our questions and testimonials.
          </Typography>
          <br />
          <Typography>
            If you contact our customer care team, we collect the information
            you give us during the interaction.
          </Typography>
          <br />
          <Typography>
            If you share with us information about other people (for example, if
            you use contact details of a friend for a given feature), we process
            this information on your behalf in order to complete your request.
            We may also request proven consent of the other person with regard
            to the processing of his/her personal data.
          </Typography>
          <br />
          <Typography>
            Of course, we also process your chats with other members as well as
            the content you publish, as necessary for the operation of the
            Services.
          </Typography>
          <br />
          <Typography>
            <strong>Information we receive from others</strong>
          </Typography>
          <br />
          <Typography>
            In addition to the information, you may provide us directly, we
            receive information about you from others, including:
          </Typography>
          <br />
          <Typography>
            - Members may provide information about you as they use our
            services, for instance as they interact with you or if they submit a
            report involving you. Any person sharing personal data of other
            people should have granted their consent proof of which may be
            requested at any time during the use of Services.
          </Typography>
          <Typography>
            - Affiliates of Invite.Club which are part of the Inverzio group.
            Inverzio considers the safety and security of members a top
            priority. If you were banned from another Inverzio service, your
            information can be shared with us to allow us to take necessary
            actions, including closing your account or preventing you from
            creating an account on our services.
          </Typography>
          <Typography>
            - Other Partners. We may receive information about you from our
            partners where Inverzio ads are published on a partner’s service (in
            which case they may pass along details on a campaign’s success).
            Where legally allowed, we can also receive information about
            suspected or convicted bad actors from third parties as part of our
            efforts to ensure our members’ safety and security.
          </Typography>
          <br />
          <Typography>
            <strong>Information collected when you use our Services</strong>
          </Typography>
          <br />
          <Typography>
            When you use our Services, this generates technical data about which
            features you’ve used, how you’ve used them and the devices you use
            to access our services. See below for more details:
          </Typography>
          <br />
          <Typography>
            Usage Information Using the services generates data about your
            activity on our services, for instance how you use them (e.g., when
            you logged in, features you’ve been using, actions taken,
            information shown to you, referring webpages address and ads that
            you interacted with) and your interactions with other members (e.g.,
            members you connect and interact with, when you exchanged with them,
            number of messages you send and receive).
          </Typography>
          <br />
          <Typography>
            Device information We collect information from and about the
            device(s) you use to access our services, including hardware and
            software information such as IP address, device ID and type,
            identifiers associated with cookies or other technologies that may
            uniquely identify a device or browser.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel4" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            4. Cookies and Other Similar Data Collection Technologies
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            We use and may allow others to use cookies and similar technologies
            (e.g., web beacons, pixels, SDKs) to recognize you and/or your
            device(s). You may read our Cookie Policy for more information on
            why we use them and how you can better control their use.
          </Typography>
          <br />
          <Typography>
            Some web browsers (including Safari, Internet Explorer, Firefox and
            Chrome) have a “Do Not Track” (“DNT”) feature that tells a website
            that a user does not want to have his or her online activity
            tracked. If a website that responds to a DNT signal receives a DNT
            signal, the browser can block that website from collecting certain
            information about the browser’s user. Not all browsers offer a DNT
            option and DNT signals are not yet uniform. For this reason, many
            businesses, including Invite.Club, do not currently respond to DNT
            signals.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel5" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            5. Purposes of processing personal data
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            The Company will process your personal data only for purposes
            permitted by applicable laws, which may vary depending on where you
            live, and where the Company operates, and on the terms set forth in
            this Policy.
          </Typography>
          <br />
          <Box>
            <ul>
              <li>
                Our primary purpose for collecting personal information from you
                is to provide and improve our Services, to administer your use
                of the Services and to enable you to enjoy and navigate our
                Services. Fulfill our contract with you and provide you with our
                Services, such as:
                <ul>
                  <li>Managing your information and accounts;</li>
                  <li>
                    Providing access to certain areas, functionalities, and
                    features of our Services;
                  </li>
                  <li>
                    Communicating with you about your account, activities on our
                    Services and policy changes;
                  </li>
                  <li>
                    Processing your financial information and other payment
                    methods for products or Services purchased;
                  </li>
                  <li>Processing applications and transactions.</li>
                </ul>
              </li>
              <li>
                Analyze and improve our Services pursuant to our legitimate
                interest, such as:
                <ul>
                  <li>
                    Detecting security incidents, protecting against malicious,
                    deceptive, fraudulent or illegal activity, and prosecuting
                    those responsible for that activity;
                  </li>
                  <li>
                    Measuring interest and engagement in our Services and
                    short-term, transient use, such as contextual customization
                    of ads;
                  </li>
                  <li>
                    Researching and developing products, services, marketing or
                    security procedures to improve their performance,
                    resilience, reliability or efficiency;
                  </li>
                  <li>Improving, upgrading or enhancing our Services</li>
                  <li>Developing new products and Services;</li>
                  <li>Ensuring internal quality control;</li>
                  <li>Verifying your identity and preventing fraud;</li>
                  <li>Enforcing our terms and policies; and</li>
                  <li>
                    Complying with our legal obligations, protecting your vital
                    interest, or as may be required for the public good.
                  </li>
                </ul>
              </li>
              <li>
                Managing our relationships/communications with individuals: for
                example, responding to questions and comments or inquiries,
                inviting individuals to the Company’s events, making proposals
                for future service needs.
              </li>
              <li>
                For collaboration and research purposes: for example, to enable
                Company to make more informed and objective decisions when
                identifying, engaging with individuals interested about our
                Services.
              </li>
              <li>
                Market research: processing data about individuals for lawful
                market research purposes. We collect data to help us improve our
                products and services.
              </li>
              <li>
                Direct marketing: to provide promotional material and engage in
                marketing and promotional activities with individuals in
                accordance with applicable laws.
              </li>
              <li>
                Website functions: to ensure that content from our website is
                presented in the most effective manner for you and for your
                device.
              </li>
              <li>
                Legal or regulatory obligations and the directions of law
                enforcement agencies and court orders: to comply with our legal
                or regulatory requirements (reporting for the safety of
                information and product quality complaints) or to fulfil
                transparency requirements.{" "}
              </li>
              <li>
                Sending you newsletters and other marketing material related to
                our project provided that you consent on these.{" "}
              </li>
              <li>
                Ensuring that the content of our Site is presented in the most
                effective way for you and your device.
              </li>
            </ul>
          </Box>
          <Typography>
            The Company will process personal data for further purposes, where
            lawful to do so or when legally obliged to do so.
          </Typography>
          <br />
          <Typography>
            For information on how we process personal information through
            profiling and automated decision-making, please see our{" "}
            <Link to="/faq">FAQ.</Link>
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel6" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            6. Legal basis of processing
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            To process your information as described in this Privacy Policy, we
            rely on the following legal bases:
          </Typography>
          <br />
          <Typography>
            <strong>Provide our Services to you:</strong> The reason we process
            your information is to perform the contract that you have with us.
            For instance, as you go about using our service to build meaningful
            connections, we use your information to maintain your account and
            your profile and to otherwise provide our free and paid features to
            you and other members.
          </Typography>
          <br />
          <Typography>
            <strong>Legitimate interests:</strong> We process your information
            based on our legitimate interest. For instance, we analyze users’
            behavior on our services to continuously improve our offerings, we
            suggest offers we think might interest you and promote our own
            services, we process information to help keep our members safe and
            we process data where necessary to enforce our rights, assist law
            enforcement and enable us to defend ourselves in the event of a
            legal action.
          </Typography>
          <br />
          <Typography>
            <strong>Comply with applicable laws and regulations:</strong> We
            process your information where it is necessary for us to comply with
            applicable laws and regulations and evidence our compliance with
            applicable laws and regulations. For example, we retain traffic data
            and data about transactions in line with our accounting, tax and
            other statutory data retention obligations and to be able to respond
            to valid access requests from law enforcement. We also keep data
            evidencing consents members give us and decisions they may have
            taken to opt-out of a given feature or processing.
          </Typography>
          <br />
          <Typography>
            <strong>Consent: </strong>If you choose to provide us with
            information that may be considered “special” or “sensitive” in
            certain jurisdictions you’re consenting to our processing of that
            information in accordance with this Privacy Policy. From time to
            time, we may ask for your consent to collect specific information or
            use your information for certain specific reasons. In some cases,
            you may withdraw your consent by deleting your content (for instance
            where you entered information in your profile that may be considered
            “special” or “sensitive”). In any case, you may withdraw your
            consent at any time by contacting us at the address provided at the
            end of this Privacy Policy.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel7" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            7. How We Share Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            Since our goal is to help you make meaningful connections, the main
            sharing of members’ information is, of course, with other members.
            We also share some members’ information with service providers and
            partners who assist us in operating the services, for specified
            reasons as laid out below and, in some cases, legal authorities.
            Read on for more details about how your information is shared with
            others below.
          </Typography>
          <br />
          <Typography>With employees of our Company</Typography>
          <br />
          <Typography>
            Access to your personal data is provided to the absolutely necessary
            staff of the Company, which has committed to maintain
            confidentiality and our partner companies or third party service
            providers, who process your personal data as persons who execute the
            process on our behalf and in accordance with our orders.{" "}
          </Typography>
          <br />
          <Box>
            <ul>
              <li>
                With other members
                <br />
                You share information with other members when you voluntarily
                disclose information on the Site (creating an invitation or when
                submitting an application, where you both fill information and
                your public profile information is used). Please be careful with
                your information and make sure that the content you share is
                stuff that you’re comfortable being visible.
                <br /> If someone submits a report involving you (such as a
                claim you violated our{" "}
                <Link to="/terms-and-conditions">Terms of Use</Link>, We may
                communicate to the reporter actions, if any, we took because of
                their report.
              </li>
              <li>
                With our service providers and partners
                <br />
                We use third parties to help us operate and improve our
                services. These third parties assist us with various tasks,
                including data hosting and maintenance, analytics, customer
                care, marketing, advertising, payment processing and security
                operations. We also share information with partners who
                distribute and assist us in advertising our services. For
                instance, we may share limited information on you in hashed,
                non-human readable form to advertising partners.
                <br />
                We follow a strict vetting process prior to engaging any service
                provider or working with any partner. Our service providers and
                partners must agree to strict confidentiality obligations.
              </li>
              <li>
                For corporate transactions
                <br />
                We may transfer your information if we are involved, whether in
                whole or in part, in a merger, sale, acquisition, divestiture,
                restructuring, reorganization, dissolution, bankruptcy or other
                change of ownership or control.
              </li>
              <li>
                With law enforcement / when required by law <br />
                We may disclose your information if reasonably necessary: (i) to
                comply with a legal process, such as a court order, subpoena or
                search warrant, government / law enforcement investigation or
                other legal requirements; (ii) to assist in the prevention or
                detection of crime (subject in each case to applicable law); or
                (iii) to protect the safety of any person.
              </li>
              <li>
                To enforce legal rights
                <br />
                We may also share information: (i) if disclosure would mitigate
                our liability in an actual or threatened lawsuit; (ii) as
                necessary to protect our legal rights and legal rights of our
                members, business partners or other interested parties; (iii) to
                enforce our agreements with you; and (iv) to investigate,
                prevent, or take other action regarding illegal activity,
                suspected fraud or other wrongdoing.
              </li>
              <li>
                With your consent or at your request
                <br />
                We may ask for your consent to share your information with third
                parties. In any such case, we will make it clear why we want to
                share the information.
                <br />
                We may use and share non-personal information (meaning
                information that, by itself, does not identify who you are such
                as device information, general demographics, general behavioral
                data, geolocation in de-identified form), as well as personal
                information in hashed, non-human readable form, under any of the
                above circumstances. We may combine this information with
                additional non-personal information or personal information in
                hashed, non-human readable form collected from other sources.
              </li>
            </ul>
          </Box>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel8" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            8. Cross-Border Data Transfers
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            The Personal Data we collect for the purposes provided herein are
            stored in the EU. However, your personal data may be accessed by
            staff or suppliers or service providers in, transferred to and
            stored at, a location outside the EU or the EEA. Where the Company
            processes personal information in countries that may not provide the
            same level of data protection as in the EU/EEA or in your own
            country, where you are resident, the Company will implement
            reasonable and appropriate legal and technical and organizational
            security measures with the aim to ensure the security of the
            processing and to protect your personal data from unauthorized
            access, use or disclosure.
          </Typography>
          <br />
          <Typography>
            In particular, for residents of EEA – whenever we transfer your
            information outside of the EEA, we will take all reasonable steps to
            ensure that adequate safeguards are put in place to protect your
            information (unless we are permitted under applicable data
            protection law to make such transfers without additional formalities
            e.g., where the recipient country is considered an adequate
            destination). Such safeguards include the use of European Commission
            approved standard contractual clauses as mentioned above. Where
            appropriate we may also ask for your explicit consent.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel9" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            9. Your Data Protection Rights
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            We want you to be in control of your information, so we want to
            remind you of the following options and tools available to you:
          </Typography>
          <br />
          <Typography>
            Access / Update tools in the service. Tools and account settings can
            help you access, rectify or remove information that you provided to
            us and that’s associated with your account directly within the
            service. If you have any questions on those tools and settings,
            please contact us for help.
          </Typography>
          <br />
          <Typography>
            Account closure. You can close your account by using the
            corresponding functionality directly on the service.
          </Typography>
          <br />
          <Typography>
            Under applicable laws and subject to any legal restrictions, you may
            have the right to request us to:
          </Typography>
          <Box>
            <ul>
              <li>
                Provide you with further details on the processing of your
                personal information;
              </li>
              <li>
                Provide you access to your personal data that we hold about you;
              </li>
              <li>
                Update any inaccuracies in the personal information we hold that
                is demonstrated to be inaccurate or incomplete;
              </li>
              <li>
                Delete any personal information that we no longer have a lawful
                basis to use;
              </li>
              <li>
                Provide you or a third party, with a copy of your data in a
                digital format (data portability);
              </li>
              <li>
                Stop a particular processing when you withdraw your consent;
              </li>
              <li>
                Object to any processing based on the legitimate interests or
                public interest to process information, unless our reasons for
                undertaking that processing outweigh any prejudice to your data
                protection rights; and
              </li>
              <li>
                Restrict certain aspects of the processing of your information.
              </li>
            </ul>
          </Box>
          <br />
          <Typography>
            All data protection related requests should be addressed to the
            Company at{" "}
            <a href="mailto:contact.support@invite.club" target="_blank" rel="noreferrer">
              contact.support@invite.club
            </a>
          </Typography>
          <br />
          <Typography>
            For your protection and the protection of all of our members, we may
            ask you to provide proof of identity before we can answer the above
            requests.
          </Typography>
          <br />
          <Typography>
            Keep in mind, we may reject requests, including if we are unable to
            authenticate you, if the request is unlawful or invalid, or if it
            may infringe on trade secrets or intellectual property or the
            privacy or other rights of someone else. If you wish to receive
            information relating to another member, such as a copy of any
            messages you received from them through our service, the other
            member will have to contact us to provide their written consent
            before the information is released. We may also ask them to provide
            proof of identity before we can answer the request.
          </Typography>
          <br />
          <Typography>
            Also, we may not be able to accommodate certain requests to object
            to or restrict the processing of personal information, notably where
            such requests would not allow us to provide our service to you
            anymore. For instance, we cannot provide our service if we do not
            have your date of birth and thus cannot ensure that you are 18 years
            of age or older.
          </Typography>
          <br />
          <Typography>
            If you receive an unwanted email from us, you can use the
            unsubscribe link found at the bottom of the email to opt out of
            receiving future emails. Note that you will continue to receive
            transaction-related emails regarding products or Services you have
            requested. We may also send you certain non-promotional
            communications regarding us and our Services, and you will not be
            able to opt out of those communications (e.g., communications
            regarding the Services or updates to our Terms or this Privacy
            Policy).
          </Typography>
          <br />
          <Typography>
            If we do not handle your request in a timely manner, or if you are
            not satisfied with our response to any exercise of these rights, you
            are entitled to lodge a complaint with the competent supervisory
            authority. Further information and contact details of the competent
            supervisory authorities can be found{" "}
            <a
              href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
              target="_blank" rel="noreferrer"
            >
              here.
            </a>
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel10" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            10. Security and data retention
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            We will take all steps reasonably necessary to ensure that your data
            is treated securely and in accordance with this Policy, applicable
            data protection laws and regulations as well as international
            security standards. All data you provide to us is stored on secure
            servers and accessed and used subject to our security policies and
            standards. The Company has implemented reasonable physical,
            technical and managerial controls and safeguards to keep your
            personal data protected from unauthorized access, disclosure,
            alteration, and destruction. Access to your personal data is limited
            to a restricted number of the Company’s employees whose duties
            reasonably require such information and third parties with whom the
            Company contracts to carry out business activities related to the
            provision of Services.
          </Typography>
          <br />
          <Typography>
            We keep your personal information only as long as we need it for
            legitimate business purposes and as permitted by applicable law.
            Data may be retained for a longer duration where applicable laws or
            regulations require or allow the Company to do so. When your data is
            no longer needed it will be either irreversibly anonymized (and the
            anonymized information may be retained) or securely destroyed. If
            you decide to stop using our Services, you can close your account.
            Note that we will close your account automatically if you are
            inactive for a period of two years. After your account is closed, we
            will delete your personal information, as laid out below:
          </Typography>
          <br />
          <Typography>
            To protect the safety and security of our members, we implement a
            safety retention window of three months following account closure,
            or one year following an account ban. During this period, we keep
            your information if it might be necessary to investigate unlawful or
            harmful conducts. The retention of information during this safety
            retention window is based on our legitimate interest as well as that
            of potential third-party victims.
          </Typography>
          <br />
          <Typography>
            Once the safety retention window elapses, we delete your data and
            only keep limited information for specified purposes, as permitted
            under applicable law.
          </Typography>
          <br />
          <Typography>
            Data may be retained for a longer duration where applicable laws or
            regulations require or allow the Company to do so. When your data is
            no longer needed it will be either irreversibly anonymized (and the
            anonymized information may be retained) or securely destroyed.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel11" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            11. Children's Privacy
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            Our services are restricted to individuals who are 18 years of age
            or older. We do not permit individuals under the age of 18 on our
            platform. If you suspect that a member is under the age of 18,
            please use the reporting mechanism available on the service.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel12" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            12. Job Candidates, Contractors and Vendor Representatives
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            We process the personal information of our job candidates,
            contractors and vendor representatives, as part of our recruitment
            and talent management operations and our management of the services
            that contractors and vendors provide to us. If you are a job
            candidate, contractor or vendor representative of Invite.Club,
            certain relevant terms of this Privacy Policy apply to our
            processing of your personal information, including the sections of
            this Privacy Policy that discuss the entity that is responsible for
            the processing of your personal information, transfers of personal
            information, rights you may have under applicable law and how to
            contact us.
          </Typography>
          <br />
          <Typography>
            If you are a job applicant, the personal information we process
            about you may vary depending on the job you seek but typically
            includes what you provide to us as part of your job application as
            well as professional qualifications, background and reference
            information that recruiters or other third parties share with us. We
            use this information to support the recruitment process, which may
            lead to an employment contract. For contractors and vendor
            representatives, we may process identification information and
            work-related information, as necessary to manage our relationship
            with you and your employer, which is necessary for the performance
            of the services agreement, and to establish, exercise or defend
            potential legal claims. We may share personal information with
            service providers that assist us with recruitment and technical data
            processing operations as well as with Inverzio companies (for
            instance if you have a business relationship with employees of an
            affiliate). We keep your personal information only as long as
            necessary for those purposes.
          </Typography>
          <br />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel13" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            13. Privacy Policy Changes
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            The Company reserves the right to amend this Policy from time to
            time to reflect technological advancements, legal and regulatory
            changes, and the Company’s business practices, subject to applicable
            laws. If the Company changes its privacy practices, an updated
            version of this Policy will reflect those changes by posting any
            revisions on with the respective update of the effective date listed
            on the bottom of this Policy. We therefore encourage you to
            periodically visit this page to stay informed of how we are using
            your personal data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel14"}
        onChange={handleChange("panel14")}
      >
        <AccordionSummary
          expandIcon={expanded === "panel14" ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1bh-content"
          className={classes.accordionHeader}
        >
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            14. How to Contact Us
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <Typography>
            If you have questions about this Privacy Policy, please send us your
            request through the <Link to="/contact">Contact Us</Link> section.
            <br />
            Last updated on 09/01/2022.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default withStyles(styles)(AccordionCompPrivacy);
