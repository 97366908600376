import { useApplicationQuery, useInvitationApplicationsQuery, useUpdateApplicationStatusMutation } from './api';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import ToggleFullscreenButton from '../../components/ToggleFullscreenButton';
import { Box, Dialog, DialogContent, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import ApplicationFilters from './ApplicationFilters';
import ScrollableSidebar from '../../components/ScrollableSidebar';
import SidebarItem from '../../components/SidebarItem';
import { theme } from '../../services/Theme';
import { transformDate } from '../../util/utils';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';
import Application from './application';
import { Button } from '../../components/UI/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ModalTitle from '../../components/UI/DialogTitle';
import Loader from '../../components/Loader';

const InvitationApplications = () => {
  const {id} = useParams();
  const [params, setParams] = useState({});
  const [selectedApp, setSelectedApp] = useState(null);
  const [openApplicationModal, setOpenApplicationModal] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const {data: applications, isLoading: isLoadingApplications} = useInvitationApplicationsQuery({id, params});
  const {data: application} = useApplicationQuery(selectedApp?.id, {skip: !applications || !selectedApp?.id});
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (applications && !selectedApp) {
      setSelectedApp(applications[0]);
    }
  }, [applications]);  //eslint-disable-line

  const onFilter = (name, value) => {
    setSelectedApp(null);
    if (!value.length) {
      const {[name]: filter, ...restFilters} = params;
      setParams(restFilters);
      return;
    }
    setParams(state => ({...state, [name]: value}));
  }

  const onApplicationClick = (application) => () => {
    if (isMobile) {
      setOpenApplicationModal(true);
    }
    setSelectedApp(application);
  }

 return (
   <PageWrapper sx={{ overflowY: 'hidden', height: 'calc(100vh - 45px)'}}>
     <ToggleFullscreenButton isFullScreen={fullScreen} onChange={() => setFullScreen(state => !state)} />
     <Stack direction={'row'} sx={{height: '100%'}}>
       <Box sx={{
         width: {xs: '100%', md: '40%'},
         height: '100%',
         display: fullScreen ? 'none' : 'flex', flexDirection: 'column',
         position: 'relative',
         '&:after': {
           content: '""',
           position: 'absolute',
           top: -30,
           right: 0,
           width: '1px',
           height: 'calc(100% + 60px)',
           backgroundColor: '#C19A5B',
           display: {xs: 'none', md: 'block'},
         }
       }}
       >
         <Typography sx={{mb: 3, fontWeight: 500}}>
           Applications
         </Typography>
         <ApplicationFilters
           sx={{pr: {xs: 0, sm: 3.5}, mb: 3}}
           hideCancelled
           itemsCount={applications?.length}
           handleClear={() => setParams({})}
           handleFilter={onFilter}
         />
         <ScrollableSidebar>
           {applications?.map((item) => (
             <SidebarItem
               key={item?.id}
               onClick={onApplicationClick(item)}
               selected={selectedApp?.id === item?.id}
               image={item?.user?.imgUrl[0]}
             >
               <Stack direction={'column'} spacing={0.5} sx={{overflow: 'hidden'}}>
                 <Typography sx={{fontSize: 15, fontWeight: 600}}>{item?.user?.username}</Typography>
                 <Typography
                   sx={{
                     fontSize: 14,
                     fontWeight: 500,
                     display: '-webkit-box',
                     WebkitLineClamp: '1',
                     WebkitBoxOrient: 'vertical',
                     overflow: 'hidden'
                   }}
                 >
                   {item?.messageToHost}
                 </Typography>
                 <Stack direction={'row'} spacing={2}>
                  <Typography sx={{fontSize: 11, fontWeight: 600, textTransform: 'uppercase'}}>{item?.status}</Typography>
                  <Typography sx={{fontSize: 11, fontWeight: 600, color: '#8F8F8F'}}>{transformDate(item?.updatedAt)}</Typography>
                 </Stack>
               </Stack>
             </SidebarItem>
           ))}
           {applications?.length === 0 && !isLoadingApplications && (
             <Typography>You have no applications</Typography>
           )}
         </ScrollableSidebar>
       </Box>
       <Box
         sx={{
           display: {xs: 'none', md: 'block'},
           width: fullScreen ? '100%' : '60%',
           position: 'relative',
           height: '100%',
           pl: fullScreen ? 0 : 3,
           overflowY:'auto'
         }}
       >
         <Details
           application={application}
           selectedApplication={selectedApp}
           twoColumns={fullScreen}
         />
       </Box>
     </Stack>
     <Dialog
       fullScreen
       open={openApplicationModal && isMobile}
       onClose={() => setOpenApplicationModal(false)}
       sx={{
         '& .MuiPaper-root': {
           maxWidth: 1300,
           width: '100%',
         },
         "& .MuiBackdrop-root": {
           backgroundColor: "#b0b0b04d",
         },
       }}
     >
       <ModalTitle onClose={() => setOpenApplicationModal(false)} />
       <DialogContent sx={{p: 3, mt: 3}}>
         <Details
           twoColumns={fullScreen}
           application={application}
           selectedApplication={selectedApp}
         />
       </DialogContent>
     </Dialog>
   </PageWrapper>
 );
};

export default InvitationApplications;

const Details = ({selectedApplication, application, twoColumns}) => {
  const isAccepted = application?.status === 'accepted';
  const isWaitlisted = application?.status === 'waitlisted';
  const isRejected = application?.status === 'rejected';
  const isCancelled = application?.status === 'cancelled';
  const [updateStatus, {isLoading}] = useUpdateApplicationStatusMutation();
  const [open, setOpen] = useState(false);
  const [isWaitlistDialog, setIsWaitlistDialog] = useState(false);

  const onChangeStatus = (value) => () => {
    if (value === application?.status) {
      return;
    }
    updateStatus({id: application?.id, body: {status: value}}).then(res => {
      if (res.data && open) {
        setOpen(false);
        if (isWaitlistDialog) {
          setIsWaitlistDialog(false);
        }
      }
    });
  };

  const onCloseDialog = () => {
    setOpen(false);
    if (isWaitlistDialog) {
      setIsWaitlistDialog(false);
    }
  };

  const onReject = () => {
    if (application.status === 'rejected') {
      return;
    }
    setOpen(true)
  };

  const onWaitlist = () => {
    if (isAccepted) {
      setOpen(true);
      setIsWaitlistDialog(true);
      return;
    }
    onChangeStatus('waitlisted')();
  };

  return (
    selectedApplication ? (
      <>
        <Grid container spacing={1} alignItems={'center'} sx={{mb: 6}}>
          <Grid item xs={12} sm={6}>
            <Typography sx={{fontSize: 17, color: '#C19A5B', fontWeight: 'bold', textAlign: 'left', textTransform: 'capitalize'}}>
              {application?.user?.username}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{fontSize: 17, color: '#373632', fontWeight: 'bold', textAlign: {xs: 'left', sm: 'right'}}}>
              {application?.invitation?.name} {application?.invitation?.uid && `#${application?.invitation?.uid}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{fontSize: 13, color: '#373632', textAlign: 'left', textTransform: 'uppercase', '& span': {fontWeight: 600}}}>
              application status: <span>{application?.status}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{fontSize: 16, color: '#373632', textAlign: {xs: 'left', sm: 'right'}}}>
              {dayjs(application?.invitation?.startDate).format("DD MMM YYYY")} - {dayjs(application?.invitation?.endDate).format("DD MMM YYYY")}
            </Typography>
          </Grid>
        </Grid>
        <Application application={{...application, ...application?.user}} isTwoColumnsLayout={twoColumns} />
        <Stack direction={'row'} alignItems={'center'}>
          <InfoIcon sx={{fontSize: 18, color: '#C19A5B', mr: 1}} />
          <Typography sx={{fontSize: 14, color: '#373632'}}>
            This user {`${application?.visitedBefore ? "has" : "hasn't"}`} been to this location before
          </Typography>
        </Stack>
        {application?.linkedApplication && application?.linkedApplicationUser && (
          <Box sx={{mt: 6}}>
            <Divider component="div" sx={{mb: 6, borderColor: '#C19A5B'}}/>
            <Typography sx={{fontSize: 17, color: '#C19A5B', fontWeight: 'bold', textAlign: 'left', textTransform: 'capitalize', mb: 3}}>
              {application?.linkedApplicationUser?.username}
            </Typography>
            <Application application={{...application?.linkedApplication, ...application?.linkedApplicationUser}} isTwoColumnsLayout={twoColumns} />
            <Stack direction={'row'} alignItems={'center'}>
              <InfoIcon sx={{fontSize: 18, color: '#C19A5B', mr: 1}} />
              <Typography sx={{fontSize: 14, color: '#373632'}}>
                This user {`${application?.linkedApplication?.visitedBefore ? "has" : "hasn't"}`} been to this location before
              </Typography>
            </Stack>
          </Box>
        )}
        {!isCancelled && (
          <Stack direction={{xs: 'column', sm: 'row'}} alignItems={'center'} justifyContent={'center'} spacing={2} sx={{mt: 3}}>
            <Button
              variant={'outlined'}
              color={'success'}
              fullWidth
              sx={{
                '&.MuiButton-outlinedSuccess': {
                  height: 48,
                  background: isAccepted ? '#589C0C' : 'transparent',
                  border: `2px solid #589C0C`,
                  color: `${isAccepted ? '#fff' : '#589C0C'} !important`,
                  boxShadow: 'none'
                }
              }}
              startIcon={<CheckIcon sx={{color: isAccepted ? '#fff' : '#589C0C'}} />}
              onClick={onChangeStatus('accepted')}
              disabled={isLoading}
            >
              {isAccepted ? 'ACCEPTED' : 'ACCEPT'}
            </Button>
            <Button
              variant={'outlined'}
              fullWidth
              sx={{
                '&.MuiButton-outlined': {
                  height: 48,
                  background: isWaitlisted ? '#8F8F8F' : 'transparent',
                  border: `2px solid ${isWaitlisted ? '#8F8F8F' : '#B8B7B7'}`,
                  color: `${isWaitlisted ? '#fff' : '#8F8F8F'} !important`,
                  boxShadow: 'none',
                  '&:hover': {
                    background: isWaitlisted ? '#8F8F8F' : 'transparent'
                  }
                }
              }}
              startIcon={<HourglassEmptyIcon sx={{color: isWaitlisted ? '#fff' : '#8F8F8F', fontSize: 17}} />}
              onClick={onWaitlist}
              disabled={isLoading}
            >
              {isWaitlisted ? 'WAITLISTED' : 'WAITLIST'}
            </Button>
            <Button
              variant={'outlined'}
              fullWidth
              sx={{
                '&.MuiButton-outlined': {
                  height: 48,
                  background: isRejected ? '#C3352B' : 'transparent',
                  border: `2px solid ${isRejected ? '#C3352B' : '#F44336'}`,
                  color: `${isRejected ? '#fff' : '#F44336'} !important`,
                  boxShadow: 'none',
                  '&:hover': {
                    background: isRejected ? '#C3352B' : 'transparent',
                  }
                }
              }}
              startIcon={<CloseIcon sx={{color: isRejected ? '#fff' : '#F44336', fontSize: 17}} />}
              onClick={onReject}
              disabled={isLoading}
            >
              {isRejected ? 'Rejected' : 'Reject'}
            </Button>
          </Stack>
        )}
        <Dialog
          open={open}
          onClose={onCloseDialog}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 600,
              width: '100%',
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "#b0b0b04d",
            },
          }}
        >
          <ModalTitle onClose={onCloseDialog} >
            <Typography sx={{fontSize: 15, fontWeight: 600}}>
              DO YOU WANT TO {isWaitlistDialog ? 'WAITLIST' : 'REJECT'}?
            </Typography>
          </ModalTitle>
          <DialogContent sx={{p: 3, mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography>
              If you {isWaitlistDialog ? 'waitlist' : 'reject'} an already accepted application you are going to lose any application fee paid. Do you want to proceed?
            </Typography>
            <Button
              sx={{mt: 2, maxWidth: 300, width: '100%'}}
              variant="outlined"
              color={isWaitlistDialog ? 'primary' : "error"}
              onClick={onChangeStatus(isWaitlistDialog ? 'waitlisted' : 'rejected')}
            >
              Yes
            </Button>
            <Button
              sx={{mt: 2, maxWidth: 300, width: '100%'}}
              variant="contained"
              color="primary"
              onClick={onCloseDialog}
            >
              No
            </Button>
          </DialogContent>
        </Dialog>
        {isLoading && <Loader />}
      </>
    ) : <Typography>Please select application</Typography>
  )
};
