import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...props }) => {
  const token = localStorage.getItem("IdToken");
  return (
    <Route
      {...props}
      render={(props) =>
        !token ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  )
};

export default PrivateRoute;
