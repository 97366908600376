import { createSlice } from '@reduxjs/toolkit';
import { invitationApi } from './api';

const draft = {
  id: null,
  experienceType: '',
  type: '',
  status: 'draft',
  host: null,
  requiredFields: {
    pictures: false,
    video: false,
  },
  hostGallery: [],
  coHostsGallery: []
}

const initialState = {
  isNavigationBlocked: true,
  step: 0,
  activeTravelPackage: null,
  draft,
  errors: null,
};

const newInvitation = createSlice({
  name: 'newInvitation',
  initialState,
  reducers: {
    setStep: (state, {payload}) => {
      state.step = payload;
    },
    setDraft: (state, {payload}) => {
      state.draft = {...payload, travelPackage: payload?.travelPackage?.id};
    },
    clearSlice: () => {
      return initialState;
    },
    updateDraft: (state, {payload: {key, value}}) => {
      state.draft = {...state.draft, [key]: value}
    },
    setError: (state, {payload: {key, value}}) => {
      state.errors = {...state.errors, [key]: value}
    },
    setNavigationBlock: (state, {payload}) => {
      state.isNavigationBlocked = payload;
    },
    setActiveTravelPackage: (state, {payload}) => {
      state.activeTravelPackage = payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      invitationApi.endpoints.addHostImages.matchFulfilled,
      (state, {payload}) => {
        state.draft.hostGallery = payload?.hostGallery;
      }
    ).addMatcher(
      invitationApi.endpoints.addCoHostImages.matchFulfilled,
      (state, {payload}) => {
        state.draft.coHostsGallery = payload?.coHostsGallery;
      }
    )
  }
});

export const {
  setStep,
  setDraft,
  setError,
  clearSlice,
  updateDraft,
  setNavigationBlock,
  setActiveTravelPackage,
} = newInvitation.actions;
export default newInvitation.reducer;
