import React, { useState } from 'react';
import { Stack } from '@mui/material';
import FiltersInfo from '../invitations/FiltersInfo';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const STATUSES = [
  {name: 'active', value: 'active'},
  {name: 'accepted', value: 'accepted'},
  {name: 'waitlisted', value: 'waitlisted'},
  {name: 'rejected', value: 'rejected'},
  {name: 'cancelled', value: 'cancelled'},
];

const ApplicationFilters = ({handleClear, handleFilter, itemsCount, hideCancelled = false, sx}) => {
  const [statusValue, setStatusValue] = useState([]);
  const filtersCount = statusValue?.length || 0;
  const statuses = !hideCancelled ? STATUSES : STATUSES.filter(item => item.name !== 'cancelled');

  const handleFiltersChange = ({target: {name, value}}) => {
    setStatusValue(value);
    handleFilter(name, value);
  };

  const onClearFilters = () => {
    setStatusValue([]);
    handleClear();
  }

  return (
    <Stack
      direction={'column'}
      justifyContent={'space-between'}
      spacing={1}
      sx={{mt: {xs: 3, sm: 0}, width: '100%', ...sx}}>
      <Stack direction={'row'} flexWrap={'wrap'} gap={2} sx={{width: {xs: '100%', sm: 'auto'}}}>
        <FormControl>
          <Select
            name={'status'}
            sx={getStyles(statusValue.length > 0)}
            multiple
            autoWidth
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            value={statusValue}
            onChange={handleFiltersChange}
            MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
            input={<OutlinedInput />}
            renderValue={() => {
              return <span>STATUS</span>;
            }}
          >
            {statuses.map((status) => (
              <MenuItem
                sx={{
                  minWidth: "150px",
                  padding: "5px 10px !important",
                  "& span": {
                    fontSize: "12px",
                    fontWeight: 600,
                  },
                  "& .MuiCheckbox-root": {
                    padding: "0px !important",
                    marginRight: "8px",
                  },
                }}
                value={status.value}
                key={status.value} >
                <Checkbox
                  sx={{'& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
                  disableRipple
                  color="default"
                  checked={statusValue.indexOf(status.value) > -1}
                />
                <ListItemText
                  sx={{textTransform: "uppercase"}}
                  primary={status.name}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {statusValue.length > 0 && (
        <FiltersInfo filtersCount={filtersCount} onClear={onClearFilters} />
      )}
    </Stack>
  );
};


const getStyles = (active) => ({
  "& fieldset": {
    borderColor: "#fff0",
  },
  "& .MuiSelect-select": {
    minWidth: "150px",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "15px",
    border: "1px solid #C19A5B !important",
    boxShadow: "unset !important",
    boxSizing: "border-box",
    background: active ? '#C19A5B' : '#fff',
  },
  "& svg": {
    color: active ? '#fff' : "#373632",
    right: "5px !important",
  },
  "& .MuiSelect-select[aria-expanded='false']": {
    color: active ? '#fff' : "#373632",
    width: "auto",
    padding: "9px 29px 8px 10px !important",
  },
  "& .MuiSelect-select[aria-expanded='true']": {
    color: active ? '#fff' : "#373632",
    border: "none",
    width: "auto",
    padding: "9px 29px 8px 10px !important",
  },
  "& .MuiSelect-select[aria-expanded='false']:hover ~ fieldset": {
    borderColor: "#C19A5B",
  },
  "& .MuiSelect-select[aria-expanded='true'] ~ fieldset": {
    borderColor: "#C19A5B",
  },

  "& .MuiSelect-select:focus ~ fieldset": {
    borderColor: "#F9F5EF",
    zIndex: "-1",
  },
});

export default ApplicationFilters;
