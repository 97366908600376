import { Box, FormControl, FormControlLabel, Icon, IconButton, RadioGroup, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs';
import { Button } from '../../../components/UI/Button';
import previewIconFile from '../../../assets/create_inv/preview.svg';
import PageWrapper from '../../../components/PageWrapper';
import React, { useEffect, useState } from 'react';
import {
  useApplicationQuery,
  useDeleteApplicationMediaMutation,
  useUpdateApplicationMutation,
  useUploadApplicationImageMutation,
  useUploadApplicationVideoMutation,
} from '../api';
import { useHistory, useParams } from 'react-router-dom';
import CancelApplicationModal from './CancelApplicationModal';
import PreviewApplication from '../PreviewApplication';
import Label from '../../../components/UI/Label';
import Input from '../../../components/UI/Input';
import ImageRow from '../../invitations/ImageRow';
import Video from '../../../components/Video';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import RadioButton from '../../../components/UI/RadioButton';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { getVideoDuration } from '../../../util/utils';
import { useSelector } from 'react-redux';

const EditApplication = () => {
  const {user} = useSelector(store => store);
  const history = useHistory();
  const {id} = useParams();
  const {data: application, isLoading, error} = useApplicationQuery(id, {skip: !id});
  const [uploadImage, {error: isUploadImagesError}] = useUploadApplicationImageMutation();
  const [uploadVideo, {error: isUploadVideoError, isLoading: isVideoLoading}] = useUploadApplicationVideoMutation();
  const [updateApplication, {error: isUpdateError}] = useUpdateApplicationMutation();
  const [deleteMedia] = useDeleteApplicationMediaMutation();
  const [form, setForm] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (application && !form) {
      setForm(application);
    } else if (application && form) {
      setForm(state => ({...state, gallery: application?.gallery, video: application?.video}))
    }
  }, [application]);//eslint-disable-line

  const userInfo = {
    shortBio: user?.user?.shortBio || '',
    gender: user?.user?.gender || '',
    occupationSector: user?.user?.occupationSector || '',
    interests: user?.user?.interests || [],
    nationality: user?.user?.nationality || '',
    age: dayjs().diff(dayjs(user?.user?.birthDate), 'year') || 0,
    languages: user?.user?.languages || [],
  };

  const onChange = ({target: {value, name}}) => {
    setForm(state => ({...state, [name]: value}));
  }

  const onChangeImage = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    uploadImage({id, images: [e.target.files[0]]});
    e.target.value = '';
  };

  const onDeleteImage = (value) => {
    deleteMedia({id, body: {filename: value}});
  }

  const onChangeVideo = (e) => {
    getVideoDuration(e.target.files[0]).then((duration) => {
      if (duration > 30) {
        setErrors(state => ({...state, video: 'Video duration should be less than 30 seconds.'}));
        return;
      }
      if (!e.target.files[0]) {
        return;
      }
      if (e?.target?.files[0].size > 60000000) {
        setErrors(state => ({...state, video: 'Video size should be less than 60mb.'}));
        return;
      }
      setErrors(state => ({...state, video: null}));
      uploadVideo({id, video: e.target.files[0]})
      e.target.value = '';
    })
  }

  const onDeleteVideo = (value) => {
    deleteMedia({id, body: {filename: value}});
  };

  const handleLocation = (key) => (value) => {
    setForm(state => ({...state, [key]: value}))
  };

  // const onChangeSwitch = ({target: {name, checked}}) => {
  //   if (!checked) {
  //     setForm(state => ({...state, [name]: checked, linkApplicationUsername: '' }));
  //     return;
  //   }
  //   setForm(state => ({...state, [name]: checked}))
  // }

  const handleUpdate = () => {
    const {gallery, video, invitation, user, updatedAt, ...body} = form;
    updateApplication({id, body}).then(res => {
      if (res?.data) {
        enqueueSnackbar(`Application was updated`, {
          variant: 'info',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        history.push('/my-applications');
      }
    });
  };

  const handleCancel = () => {
    updateApplication({id, body: {status: 'cancelled'}}).then(res => {
      if (res?.data) {
        enqueueSnackbar(`Application was cancelled`, {
          variant: 'error',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
        history.push('/my-applications');
      }
    });
  };

  return (
    <PageWrapper>
      {error && !isLoading && <Typography>No Applications found</Typography>}
      {!error && !isLoading && (
        <>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{flexGrow: 1}}>
            <Box sx={{width: {xs: '100%', sm: '50%'}, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <Box>
                <Typography sx={{fontWeight: 600, fontSize: 20, mb: 2}}>Edit Application</Typography>
                <Stack direction={'row'} sx={{mb: 3}}>
                  <InfoIcon sx={{mr: 1, color: '#c19a5b'}} />
                  <Typography sx={{fontSize: 16, letterSpacing: '0.21px', lineHeight: '20px'}}>
                    Certain information is automatically retrieved from your profile details. You can edit this by editing your profile.
                  </Typography>
                </Stack>

                {application?.type === 'guest' && application?.experienceType === 'trip' ? (
                  <>
                    <Label>Personal Message to the Host</Label>
                    <Input
                      fullWidth
                      name={'messageToHost'}
                      value={form?.messageToHost || ''}
                      onChange={onChange}
                      sx={{mb: 3}}
                      multiline
                      maxRows={4}
                      minRows={4}
                    />
                    {application?.invitation?.requiredFields?.pictures && (
                      <>
                        <Label>Upload at least one picture of you</Label>
                        <ImageRow
                          fiveImages
                          images={form?.gallery || []}
                          onChange={onChangeImage}
                          onDelete={onDeleteImage}
                        />
                        {isUploadImagesError && (
                          <Typography sx={{color: 'red', fontSize: 16, mb: 2}}>
                            {isUploadImagesError?.data?.error}
                          </Typography>
                        )}
                      </>
                    )}
                    {application?.invitation?.requiredFields?.video && (
                      <>
                        <Label>Upload a Short Video (up to 30 sec.) of you</Label>
                        <Video video={form?.video || null} onChange={onChangeVideo} onDelete={onDeleteVideo} isLoading={isVideoLoading} />
                        {isUploadVideoError && (
                          <Typography sx={{color: 'red', fontSize: 16, mb: 2}} align={'left'}>
                            {isUploadVideoError?.data?.error}
                          </Typography>
                        )}
                        {errors?.video && (
                          <Typography sx={{color: 'red', fontSize: 16, mb: 2}} align={'left'}>
                            {errors?.video}
                          </Typography>
                        )}
                      </>
                    )}
                    <Label>Departure point</Label>
                    <GoogleAutocomplete value={form?.departurePoint || null} handleChange={handleLocation('departurePoint')} />
                    <Label>Return Point (if Different)</Label>
                    <GoogleAutocomplete value={form?.returnPoint || null} handleChange={handleLocation('returnPoint')} />
                    <FormControl>
                      <Typography
                        sx={{
                          color: '#373632',
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        Have you been in that Location before?
                      </Typography>
                      <RadioGroup
                        sx={{
                          mb: 2,
                          "& .MuiTypography-root": {
                            fontWeight: 400,
                          },
                        }}
                        row={true}
                        name="visitedBefore"
                        value={form?.visitedBefore || false}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<RadioButton />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<RadioButton />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Label>Special Requirements, for example dietary, allergies etc. •<em>Optional</em></Label>
                    <Input
                      fullWidth
                      value={form?.specialRequirements || ''}
                      name={'specialRequirements'}
                      onChange={onChange}
                      sx={{mb: 3}}
                      multiline
                      maxRows={4}
                      minRows={4}
                    />
                    {/*<FormControlLabel
                      sx={{mb: 3}}
                      control={
                        <CustomSwitch
                          name={'linkApplication'}
                          onChange={onChangeSwitch}
                          checked={form?.linkApplication || false}
                        />
                      }
                      label={
                        <Typography sx={{
                          display: 'flex',
                          color: '#373632',
                          fontSize: 14,
                          fontWeight: 400,
                        }}>
                          Link with another Application •<em>Optional</em>
                        </Typography>
                      }
                    />
                    {form?.linkApplication && (
                      <>
                        <Label>
                          If you want to travel with another guest please specify the other guest's username.
                          The application will be sent to the Host only when both applications are submitted.
                        </Label>
                        <Input
                          fullWidth
                          value={form?.linkApplicationUsername || ''}
                          name={'linkApplicationUsername'}
                          onChange={onChange}
                          sx={{mb: 3}}
                        />
                      </>
                    )}*/}
                  </>
                ) : (
                  <>
                    <Label>Personal Message to the Host</Label>
                    <Input
                      fullWidth
                      name={'messageToHost'}
                      value={form?.messageToHost || ''}
                      onChange={onChange}
                      sx={{mb: 3}}
                      multiline
                      maxRows={4}
                      minRows={4}
                    />
                    {application?.invitation?.requiredFields?.pictures && (
                      <>
                        <Label>Upload at least one picture of you</Label>
                        <ImageRow
                          fiveImages
                          images={form?.gallery || []}
                          onChange={onChangeImage}
                          onDelete={onDeleteImage}
                        />
                        {isUploadImagesError && (
                          <Typography sx={{color: 'red', fontSize: 16, mb: 2}}>
                            {isUploadImagesError?.data?.error}
                          </Typography>
                        )}
                      </>
                    )}
                    {application?.invitation?.requiredFields?.video && (
                      <>
                        <Label>Upload a Short Video (up to 30 sec.) of you</Label>
                        <Video video={form?.video || null} onChange={onChangeVideo} onDelete={onDeleteVideo} isLoading={isVideoLoading} />
                        {isUploadVideoError && (
                          <Typography sx={{color: 'red', fontSize: 16, mb: 2}} align={'left'}>
                            {isUploadVideoError?.data?.error}
                          </Typography>
                        )}
                        {errors?.video && (
                          <Typography sx={{color: 'red', fontSize: 16, mb: 2}} align={'left'}>
                            {errors?.video}
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
              <Button
                sx={{maxWidth: {xs: '100%', md: 400}}}
                variant={'outlined'}
                color={'error'}
                fullWidth
                onClick={() => setOpenDeleteModal(true)}
              >
                Cancel Application
              </Button>
            </Box>
            <Box sx={{
              position: {xs: 'static', sm: 'fixed'},
              pb: {xs: 0, sm: 2},
              pr: {xs: 0, sm: 2.5},
              top: {xs: 50, sm: 95, md: 30},
              right: 0,
              width: {xs: '100%', sm: '45%', md: '25%'},
              height: {xs: 'auto', sm: 'calc(100vh - 232px)', md: 'calc(100vh - 78px)'},
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {xs: 'center', sm: 'space-between'}
            }}>
              <Box>
                <Typography sx={{
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#373632",
                  fontWeight: 700,
                  mb: 1,
                }}>
                  {application?.invitation?.name} {application?.invitation?.uid && `#${application?.invitation?.uid}`}
                </Typography>
                <Typography sx={{
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "#373632",
                  fontWeight: 600,
                  mb: 1.2,
                }}>
                  {dayjs(form?.startDate).format("DD MMM YYYY")}{" "}
                  -{" "}
                  {dayjs(form?.endDate).format("DD MMM YYYY")}
                </Typography>
                <Typography sx={{fontSize: 14, textTransform: 'uppercase', fontWeight: 500, mb: 3, display: 'flex', alignItems: 'center'}}>
                  STATUS: <Typography component='span' sx={{fontWeight: 600, ml: 0.25}}> {application?.status}</Typography>
                </Typography>
                {application?.invitation?.imgUrl && (
                  <Box sx={{width: '100%', '& > img': {width: '100%', height: 'auto'}}}>
                    <img src={application?.invitation?.imgUrl} alt={'invitation-wallpaper'} />
                  </Box>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenPreview(true)}
                  sx={{py: 0.5, width: {xs: '100%', md: 'auto'}, mx: {xs: 'auto', md: 0}}}
                  startIcon={
                    <Icon sx={{width: 'auto', height: 'auto'}}>
                      <img src={previewIconFile} alt={'preview'} />
                    </Icon>
                  }
                >
                  PREVIEW APPLICATION
                </Button>
              </Box>
              <Box>
                {isUpdateError && (
                  <Typography color={'error'} sx={{mt: 2}}>{isUpdateError?.data?.error}</Typography>
                )}
                <Button
                  variant={'contained'}
                  color={'primary'}
                  sx={{maxWidth: {xs: '100%', md: 400}, mt: 2}}
                  fullWidth
                  disabled={JSON.stringify(application) === JSON.stringify(form) && !form?.video && !form?.gallery.length}
                  onClick={handleUpdate}
                >
                  Update application
                </Button>
              </Box>
            </Box>
          </Stack>
          <CancelApplicationModal
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onConfirm={handleCancel}
          />
          <PreviewApplication
            application={{...application, ...userInfo}}
            open={openPreview}
            onClose={() => setOpenPreview(false)}
          />
        </>
      )}
    </PageWrapper>
  );
};

export default EditApplication;
