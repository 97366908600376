import { useSelector } from 'react-redux';
import CoHostStepOne from './CoHostStepOne';
import CoHostStepTwo from './CoHostStepTwo';
import ProgressBar from '../ProgressBar';

const CoHostInvitationForm = () => {
  const {newInvitation: {step}} = useSelector(store => store);

  return (
    <>
      <ProgressBar />
      {step === 1 && <CoHostStepOne />}
      {step === 2 && <CoHostStepTwo />}
    </>
  );
}

export default CoHostInvitationForm;
