import { Box, IconButton, Stack, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageUploader from '../../components/ImageUploader';
import React from 'react';

const ImageRow = ({images = [], error = false, onChange, onDelete, fiveImages = false}) => {
  return (
    <>
      <Stack direction={'row'} spacing={1} sx={{mb: error ? 1 : 4}}>
        {images.map((item, i) => (
          <Box
            key={`hostImage-${i}`}
            sx={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              aspectRatio: '1',
              justifyContent: 'center',
              backgroundColor: '#AFAFAF',
              width: fiveImages ? '20%' : '25%',
              borderRadius: '50%',
              '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%',
                overflow: 'hidden',
              }
            }}
          >
            <IconButton
              onClick={() => onDelete(item)}
              sx={{
                background: '#000',
                position: 'absolute',
                right: 0,
                top: 0,
                m: 0,
                p: 0,
                '&:hover': {
                  backgroundColor: 'inherit',
                }
              }}
            >
              <CancelIcon />
            </IconButton>
            <img src={item} alt={`uploaded-${i}`} />
          </Box>
        ))}
        {images?.length < (fiveImages ? 5 : 4) && (
          <ImageUploader
            sx={{width: fiveImages ? '20%' : '25%'}}
            onChange={(e) => onChange(e)}
          />
        )}
      </Stack>
      {error && <Typography sx={{fontSize: 14, mb: 4, color: 'red'}}>{error}</Typography>}
    </>
  )
};

export default ImageRow;
