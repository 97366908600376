import {
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Select from '../../components/UI/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OutlinedInput from '@mui/material/OutlinedInput';
import dayjs from 'dayjs';
import Dropdown from '../../components/UI/Dropdown';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ConfirmationDialog from './ConfirmationDialog';
import { useDeleteUserMutation, useReinstateUserMutation, useSuspendUserMutation } from './api';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const VerifiedUsersTable = ({users, isLoading, onFilter}) => {
  const [userType, setUserType] = useState([]);
  const [modal, setModal] = useState({
    openDelete: false,
    openSuspend: false,
    openReinstate: false,
  });
  const [deleteUser] = useDeleteUserMutation();
  const [suspendUser] = useSuspendUserMutation();
  const [reinstateUser] = useReinstateUserMutation();

  const onChangeFilter = ({target: {value}}) => {
    setUserType(value);
    onFilter(value);
  };

  const onDelete = () => {
    deleteUser({id: modal.openDelete}).then(res => {
      if (res?.data) {
        setModal(state => ({...state, openDelete: false}));
        enqueueSnackbar(`User with username ${res?.data?.username} was deleted`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
      }
    })
  };

  const onSuspend = () => {
    suspendUser(modal.openSuspend).then(res => {
      if (res?.data) {
        setModal(state => ({...state, openSuspend: false}));
        enqueueSnackbar(`User with username ${res?.data?.user?.username} was suspended`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  const onReinstate = () => {
    reinstateUser(modal.openReinstate).then(res => {
      if (res?.data) {
        setModal(state => ({...state, openReinstate: false}));
        enqueueSnackbar(`User with username ${res?.data?.user?.username} was reinstated`, {
          variant: 'success',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
            color={'primary'}
          >
            <CloseIcon sx={{color: '#C19A5B', fontSize: '20px'}}/>
          </IconButton>
        });
      }
    });
  };

  const onOpenConfirmModal = (name, value) => () => {
    setModal(state => ({...state, [name]: value}));
  };

  return (
    <>
      {isLoading && <Typography>Loading...</Typography>}
      {users?.length > 0 && !isLoading && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{'& > .MuiTableCell-root': { background: '#F9F5EF'}}}>
                <TableCell style={{ width: 126, minWidth: 126 }} sx={{p: 0}}>
                  <Select
                    name={'status'}
                    sx={{
                      background: 'transparent',
                      '& .MuiSelect-select': {
                        fontSize: 14,
                        fontWeight: 600,
                        '&[aria-expanded="true"]': {
                          background: '#C19A5B',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      }
                    }}
                    multiple
                    autoWidth
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    value={userType}
                    onChange={onChangeFilter}
                    MenuProps={{ classes: { paper: "0px 3px 6px #00000029 !important" } }}
                    input={<OutlinedInput />}
                    renderValue={() => {
                      return <span>USER TYPE</span>;
                    }}
                  >
                    <SelectItem value={'host'} name={'host'} checked={userType.indexOf('host') > -1} />
                    <SelectItem value={'guest'} name={'guest'} checked={userType.indexOf('guest') > -1} />
                  </Select>
                </TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  EMAIL
                </TableCell>
                <TableCell style={{ minWidth: 150 }}>
                  USERNAME
                </TableCell>
                <TableCell style={{ minWidth: 150 }}>
                  REFERAL CODE
                </TableCell>
                <TableCell style={{ width: 126, minWidth: 126 }}>
                  JOINED
                </TableCell>
                <TableCell style={{ width: 100, minWidth: 100 }}>
                  STATUS
                </TableCell>
                <TableCell align={'center'} style={{ width: 50, minWidth: 50 }}>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map(item => (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.id} sx={{'& > .MuiTableCell-root': { fontSize: 15, fontWeight: 400, color: '#373632', p: 2, borderBottomColor: '#E1E1E1'}}}>
                  <TableCell>
                    {item.role}
                  </TableCell>
                  <TableCell>
                    {item.email}
                  </TableCell>
                  <TableCell>
                    {item.username}
                  </TableCell>
                  <TableCell>
                    {item.signUpReferralCode}
                  </TableCell>
                  <TableCell>
                    {dayjs(item.createdAt).format("DD MMM YYYY")}
                  </TableCell>
                  <TableCell>
                    {item?.active && !item?.deleted && 'ACTIVE'}
                    {!item?.active && !item?.deleted && 'SUSPENDED'}
                  </TableCell>
                  <TableCell align={'center'}>
                    <Dropdown
                      dropdownItems={
                        <Box>
                          <MenuItem
                            sx={{textTransform: 'uppercase', fontSize: 15 }}
                            component={Link}
                            to={`/user/${item?.id}`}
                          >
                            {item?.username}
                          </MenuItem>
                          {item?.active && !item?.deleted && (
                            <MenuItem
                              onClick={onOpenConfirmModal('openSuspend', item?.id)}
                              sx={{
                                textTransform: 'uppercase',
                                fontSize: 15,
                              }}>
                              Suspend User
                            </MenuItem>
                          )}
                          {!item?.active && !item?.deleted && (
                            <MenuItem
                              onClick={onOpenConfirmModal('openReinstate', item?.id)}
                              sx={{
                                textTransform: 'uppercase',
                                fontSize: 15,
                              }}>
                              Reinstate User
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={onOpenConfirmModal('openDelete', item?.id)}
                            sx={{
                              textTransform: 'uppercase',
                              fontSize: 15,
                            }}>
                            Delete User
                          </MenuItem>
                        </Box>
                      }
                    >
                      <MoreVertIcon/>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {users?.length === 0 && !isLoading && <Typography align={'center'}>No users found</Typography>}
      <ConfirmationDialog
        onConfirm={onDelete}
        open={Boolean(modal.openDelete)}
        onClose={() => setModal(state => ({...state, openDelete: false}))}
        title={'Do you want to delete this user?'}
      />
      <ConfirmationDialog
        onConfirm={onSuspend}
        open={Boolean(modal.openSuspend)}
        onClose={() => setModal(state => ({...state, openSuspend: false}))}
        title={'Do you want to suspend this user?'}
      />
      <ConfirmationDialog
        onConfirm={onReinstate}
        open={Boolean(modal.openReinstate)}
        onClose={() => setModal(state => ({...state, openReinstate: false}))}
        title={'Do you want to reinstate this user?'}
      />
    </>
  );
};

export default VerifiedUsersTable;

const SelectItem = ({value, name, checked, ...props}) => {
  return (
    <MenuItem
      sx={{
        minWidth: "126px",
        padding: "5px 10px !important",
        "& span": {
          fontSize: "12px",
          fontWeight: 600,
        },
        "& .MuiCheckbox-root": {
          padding: "0px !important",
          marginRight: "8px",
        },
      }}
      value={value}
      {...props}
    >
      <Checkbox
        sx={{'& .MuiSvgIcon-root': {fill: '#C19A5B'}}}
        disableRipple
        color="default"
        checked={checked}
      />
      <ListItemText
        sx={{textTransform: "uppercase", color: '#000'}}
        primary={name}
      />
    </MenuItem>
  )
};
