import PageWrapper from '../../../components/PageWrapper';
import { Stack, Typography } from '@mui/material';
import React, {useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useInvitationQuery } from '../../invitations/api';
import ApplyGuestInvitation from './ApplyGuestInvitation';
import { useDispatch, useSelector } from 'react-redux';
import { clearSlice, setForm } from '../slice';
import ApplyCoHostInvitation from './ApplyCoHostInvitation';

const ApplyInvitation = () => {
  const {id} = useParams();
  const {data: invitation, isLoading} = useInvitationQuery(id);
  const {user} = useSelector(store => store.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const notFilledProfileInfo = (user.gender === "" || user.occupationSector === "" || user.shortBio === "" ||
    user.nationality === "" || user.birthDate === "" || user.languages?.length === 0 ||
    user?.interests?.length === 0);

  useEffect(() => {
    if (notFilledProfileInfo) {
      history.push({pathname: '/complete-your-profile', state: {path: history.location.pathname}});
    }
  }, [notFilledProfileInfo]);//eslint-disable-line

  useEffect(() => {
    if (invitation) {
      dispatch(setForm({type: invitation?.type, invitation: invitation?.id || null}));
    } else {
      dispatch(clearSlice());
    }
    return () => dispatch(clearSlice());
  }, [invitation, dispatch]);

  return (
    <PageWrapper>
      <Stack direction={'column'} sx={{flexGrow: 1}}>
        {invitation && !isLoading && (
          invitation?.type === 'guest' && invitation.experienceType === 'trip' ?
            <ApplyGuestInvitation requiredFields={invitation?.requiredFields} name={invitation?.name} uid={invitation?.uid} /> :
            <ApplyCoHostInvitation requiredFields={invitation?.requiredFields} name={invitation?.name} uid={invitation?.uid} />
        )}
        {!invitation && !isLoading && <Typography sx={{fontSize: 16}}>Invitation was not found</Typography>}
        {isLoading && <Typography sx={{fontSize: 16}}>...Loading</Typography>}
      </Stack>
    </PageWrapper>
  );
};

export default ApplyInvitation;
