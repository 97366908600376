import {Select as MuiSelect} from '@mui/material';
import { styled } from '@mui/material/styles';

const Select = styled(({children, ...props}) => (
  <MuiSelect
    MenuProps={
      {
        PaperProps: {
          elevation: 1,
          style: {
            maxHeight: 200,
            width: 250,
          },
        },
      }
    }
    {...props}
  >
    {children}
  </MuiSelect>
))(({theme}) => ({
  background: theme.palette.background.default,
  boxSizing: 'border-box',
  width: '100%',
  minHeight: '55px !important',
  height: '55px !important',
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c19a5b',
    },
  },
  '&::placeholder': {
    color: '#373632',
  },
  '&:focus, &.Mui-focused': {
    outline: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c19a5b',
      borderWidth: 1,
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'red',
      },
    },
  },
  '& .MuiSelect-select': {
    flex: 1,
    minWidth: 0,
    padding: '1rem',
    boxSizing: 'border-box',
    borderWidth: 2,
    lineHeight: '2rem',
    color: '#373632',
    fontSize: '1rem',
    background: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#c19a5b',
    borderWidth: 1,
    borderRadius: '1px',
  },
  '& .MuiSvgIcon-root': {
    color: '#373632',
    fontSize: 24,
  }
}));

export default Select;
