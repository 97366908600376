//User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const SET_REFERRALS = "SET_REFERRALS";
export const LOADING_REFERRALS = "LOADING_REFERRALS";
export const LOADING_USER = "LOADING_USER";
export const LOADING_USERS = "LOADING_USERS";
export const LOADING_NOTIFICATIONS = "LOADING_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const DELETE_CONVERSATION = "DELETE_CONVERSATION";
export const SET_FORGOT_PASSWORD = "SET_FORGOT_PASSWORD";
export const LOADING_CONVERSATIONS = "LOADING_CONVERSATIONS";
export const SET_VALIDATE_TOKEN = "SET_VALIDATE_TOKEN";
export const SET_UNVALIDATE_TOKEN = "SET_UNVALIDATE_TOKEN";
export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD";

//UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

//Data reducer types
export const SET_INVITATIONS = "SET_INVITATIONS";
export const SET_MY_INVITATIONS = "SET_MY_INVITATIONS";
export const SET_INVITATIONS_FILTERS = "SET_INVITATIONS_FILTERS";
export const CLEAR_INVITATIONS = "CLEAR_INVITATIONS";
export const CLEAR_MY_INVITATIONS = "CLEAR_MY_INVITATIONS";
export const SET_COHOST_INVITATIONS = "SET_COHOST_INVITATIONS";
export const SET_MY_COHOST_INVITATIONS = "SET_MY_COHOST_INVITATIONS";
export const CLEAR_MY_COHOST_INVITATIONS = "CLEAR_MY_COHOST_INVITATIONS";
export const CLEAR_COHOST_INVITATIONS = "CLEAR_COHOST_INVITATIONS";
export const SET_COHOST_INVITATIONS_FILTERS = "SET_COHOST_INVITATIONS_FILTERS";
export const SET_INVITATION = "SET_INVITATION";
export const SET_COHOST_INVITATION = "SET_COHOST_INVITATION";
export const SET_TRAVELPACKAGES = "SET_TRAVELPACKAGES";
export const SET_TRAVELPACKAGE = "SET_TRAVELPACKAGE";
export const DELETE_POST = "DELETE_POST";
export const DELETE_USER = "DELETE_USER";
export const ADD_COHOST_INVITATION = "ADD_COHOST_INVITATION";
export const ADD_INVITATION = "ADD_INVITATION";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const ADD_COHOST_APPLICATION = "ADD_COHOST_APPLICATION";
export const SET_MY_APPLICATIONS = "SET_MY_APPLICATIONS";
export const SET_COHOST_APPLICATIONS = "SET_COHOST_APPLICATIONS";
