import { Container, Typography } from '@mui/material';
import { Button, Box } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import StaticPageWrapper from '../StaticPagesWrapper';

const PageNotFound = () => {
  const history = useHistory();
  return (
    <StaticPageWrapper>
      <Container sx={{maxWidth: 1320}} maxWidth={'xl'}>
        <Box className={'home-inner'}>
          <Typography variant={'h5'} sx={{mb: 3}}>Sorry, page not found, please return to home page.</Typography>
          <Button variant={'contained'} color={'primary'} onClick={() => history.push('/invitations-gallery')}>Return to home page</Button>
        </Box>
      </Container>
    </StaticPageWrapper>
  )
};

export default PageNotFound;
